import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/ui/Button/Button';
import { localizePrice } from 'shared/providers/utils';
import ConfiguratorStore from 'stores/Configurator.store';

import { Input } from '../Input/Input';

import styles from './DiscountForm.module.scss';

interface DiscountFormType {
    percentage: number;
    chf: number;
    discount: number;
}

export const DiscountForm: React.FC<{
    defaultValues: {
        percentage: number;
        chf: number;
        discount?: number;
    };
    onSuccess?: (val: DiscountFormType) => void;
}> = observer(({ onSuccess, defaultValues: { percentage, chf, discount } }) => {
    const { t } = useTranslation();
    const store = useContext(ConfiguratorStore);

    const totalPrice = store.totalPrice;

    const {
        handleSubmit,
        register,
        formState: { isValid },
        watch,
        setValue,
    } = useForm<DiscountFormType>({
        mode: 'onChange',
        defaultValues: {
            percentage: percentage ?? 0,
            chf: chf ?? totalPrice,
            discount: discount ?? 0,
        },
    });

    const percentageValue = watch('percentage');
    const discountedPriceChfValue = watch('chf');
    const discountValue = watch('discount');

    const [isLoading, setIsLoading] = useState(false);
    const [lastEdited, setLastEdited] = useState<'percentage' | 'chf' | 'discount' | null>(null);

    useEffect(() => {
        if (lastEdited === 'chf' && totalPrice > 0) {
            const newDiscount = totalPrice - discountedPriceChfValue;
            setValue('discount', parseFloat(newDiscount.toFixed(2)), { shouldValidate: true });

            const calculatedPercentage = (newDiscount / totalPrice) * 100;
            setValue('percentage', parseFloat(calculatedPercentage.toFixed(2)), { shouldValidate: true });
        }
    }, [discountedPriceChfValue, totalPrice, setValue, lastEdited]);

    useEffect(() => {
        if (lastEdited === 'percentage' && totalPrice > 0) {
            const discountFactor = 1 - percentageValue / 100;
            const newChf = totalPrice * discountFactor;
            setValue('chf', parseFloat(newChf.toFixed(2)), { shouldValidate: true });

            const newDiscount = totalPrice - newChf;
            setValue('discount', parseFloat(newDiscount.toFixed(2)), { shouldValidate: true });
        }
    }, [percentageValue, totalPrice, setValue, lastEdited]);

    useEffect(() => {
        if (lastEdited === 'discount' && totalPrice > 0) {
            const newChf = totalPrice - discountValue;
            setValue('chf', parseFloat(newChf.toFixed(2)), { shouldValidate: true });

            const newPercentage = (discountValue / totalPrice) * 100;
            setValue('percentage', parseFloat(newPercentage.toFixed(2)), { shouldValidate: true });
        }
    }, [discountValue, totalPrice, setValue, lastEdited]);

    const onSubmit = async (data: DiscountFormType) => {
        setIsLoading(true);
        try {
            onSuccess?.(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            onSuccess?.(data);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
            <div className={styles.body}>
                <h4>{t('discountForm.title')}</h4>

                <div className={styles.formLine}>
                    <div>{t('discountForm.bruttoPrice')}</div>
                    <div>
                        {/* <div className={styles.fakeInput}>CHF {localizePrice(totalPrice)}</div> */}
                        <Input className={styles.input} value={`CHF ${localizePrice(totalPrice)}`} disabled />
                    </div>
                </div>

                {/* Discount Percentage Field */}
                <div className={styles.formLine}>
                    <div>{t('discountForm.discountPercentage')}</div>
                    <div>
                        <Input
                            className={styles.input}
                            labelWrapperClassName={styles.labelWrapper}
                            type="number"
                            step="0.01"
                            suffix="%"
                            {...register('percentage', {
                                onChange: () => setLastEdited('percentage'),
                                valueAsNumber: true,
                                min: 0,
                                max: 100,
                            })}
                        />
                    </div>
                </div>

                {/* Discount Field (Absolute) */}
                <div className={styles.formLine}>
                    <div>{t('discountForm.discount')}</div>
                    <div>
                        <Input
                            className={styles.input}
                            labelWrapperClassName={styles.labelWrapper}
                            type="number"
                            step="0.01"
                            suffix="CHF"
                            {...register('discount', {
                                onChange: () => setLastEdited('discount'),
                                valueAsNumber: true,
                                min: 0,
                            })}
                        />
                    </div>
                </div>

                {/* Discounted Final Price Field (CHF) */}
                <div className={styles.formLine}>
                    <div>{t('discountForm.priceWithDiscount')}</div>
                    <div>
                        <Input
                            className={styles.input}
                            labelWrapperClassName={styles.labelWrapper}
                            type="number"
                            step="0.01"
                            suffix="CHF"
                            {...register('chf', {
                                onChange: () => setLastEdited('chf'),
                                valueAsNumber: true,
                                min: 0,
                            })}
                        />
                    </div>
                </div>

                <Button htmlType="submit" className={styles.button} isDisabled={!isValid} isLoading={isLoading}>
                    {t('salesPersonPopup.button')}
                </Button>
            </div>
        </form>
    );
});
