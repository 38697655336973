import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button, Loader } from 'shared/components/ui';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import CheckoutStore from 'stores/Checkout.store';
import ConfiguratorStore from 'stores/Configurator.store';

import fallbackBikeImg from '../../assets/images/mock-bike.jpg';

import styles from './ThankYou.module.scss';

export const ThankYou = observer(() => {
    const checkoutStore = useContext(CheckoutStore);
    const configuratorStore = useContext(ConfiguratorStore);
    const [image, setImage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();
    // const [checkoutSyncAppRoomMutation] = useMutation(CHECKOUT_SYNC_APPROOM);
    const [syncState, setSyncState] = useState<'loading' | 'success' | 'error'>('loading');
    const isSyncSuccess = syncState === 'success';
    const isSyncLoading = syncState === 'loading';
    const sid = checkoutStore.data?.metadata.find((d: any) => d.key === 'sid')?.value;

    useEffect(() => {
        async function getCheckout() {
            const checkout = await checkoutStore.getCheckout(id);
            if (!checkout) return navigate(AppRoutes.notFound);
            const image = checkoutStore.data?.metadata.find(d => d.key === 'configurationImage')?.value;
            setImage(image ? image : fallbackBikeImg);
            const res = await checkoutStore.checkoutComplete(id, true);
            if (res?.errors.lenght > 0) {
                setSyncState('error');
            } else {
                setSyncState('success');

                checkoutStore.resetCheckoutStore();
                configuratorStore.resetStore();
            }

            if (localStorage.getItem(LocalStorageKey.CHECKOUT_ID)) {
                localStorage.removeItem(LocalStorageKey.CHECKOUT_ID);
                localStorage.removeItem(LocalStorageKey.DEAL_ID);
                localStorage.removeItem(LocalStorageKey.DEAL_SID);
            }
        }
        if (id) getCheckout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, checkoutStore, navigate]);

    return (
        <div className={styles.root}>
            {image && (
                <img
                    className={styles.bikeImage}
                    src={`${checkoutStore?.data?.configurationImage || image}?h=300&trim=color&trim-tol=50`}
                    alt="Screenshot of the bike"
                />
            )}
            <h1>{t('posOrderTitle')}</h1>
            {!isSyncLoading && (
                <>
                    <div className={clsx(styles.text, { [styles.error]: syncState === 'error' })}>
                        {isSyncSuccess ? t('posOrderDescription') : t('somethingWentWrong')}
                    </div>
                    <div className={styles.text}>
                        <b>{sid}</b>
                    </div>
                </>
            )}
            <Button
                type="primary"
                isDisabled={isSyncLoading}
                onClick={() => (isSyncSuccess ? navigate(AppRoutes.home) : navigate(-1))}
                className={styles.button}
            >
                {isSyncLoading ? (
                    <Loader className={styles.loader} />
                ) : (
                    <> {isSyncSuccess ? t('posOrderButton') : t('posOrderRetry')}</>
                )}
            </Button>
        </div>
    );
});
