export enum hubspotEvents {
    BIKE_AR_VIEW = 'bike_ar_view_clicked',
    BIKE_CATEGORY_SELECTED = 'bike_category_selected',
    BIKE_COLOR_SELECTED = 'bike_color_selected',
    BIKE_COLOR_TYPE_SELECTED = 'bike_color_type_selected',
    BIKE_CUSTOM_COLOR_SELECTED = 'bike_custom_color_selected',
    BIKE_EMAIL_MODAL = 'bike_email_modal',
    BIKE_EMAIL_SHARE = 'bike_email_share',
    BIKE_MODEL_SELECTED = 'bike_model_selected',
    BIKE_PART_CHANGE = 'bike_part_changed',
    BIKE_PRECONFIGURATION_CREATE = 'bike_preconfiguration_created',
    BIKE_SHARABLE_ID_COPY = 'bike_sharable_id_copy',
    BIKE_SHARABLE_ID_MODAL = 'bike_sharable_id_modal',
    BIKE_SITTING_POSITION_SELECTED = 'bike_sitting_position_selected',
    BIKE_SIZE_MODAL_TRIGGERED = 'bike_size_modal_triggered',
    BIKE_SIZE_SELECTED = 'bike_size_selected',
    CONFIGURATION_SHARED = 'configuration_shared',
    CONTACT_SUPPORT = 'contact_support',
    EXIT_INTENT_POPUP = 'exit_intent_popup',
    EXIT_INTENT_SAVE_CONFIGURATION = 'exit_intent_save_configuration',
    EXIT_INTENT_SEND_EMAIL = 'exit_intent_send_mail',
}
