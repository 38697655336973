import { useFrame, useThree } from '@react-three/fiber';
import { EffectComposer, Outline, Select, Selection } from '@react-three/postprocessing';
import { TweenLite } from 'gsap';
import {
    forwardRef,
    SetStateAction,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import ConfiguratorStore from 'stores/Configurator.store';
import {
    ACESFilmicToneMapping,
    BackSide,
    Box3,
    BufferGeometry,
    Color,
    ConeGeometry,
    DoubleSide,
    EquirectangularReflectionMapping,
    Float32BufferAttribute,
    FrontSide,
    Group,
    Line,
    LineDashedMaterial,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    PlaneGeometry,
    PointLight,
    RepeatWrapping,
    ShadowMaterial,
    SphereGeometry,
    SpotLight,
    sRGBEncoding,
    TextureLoader,
    Vector3,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { LoadingManager } from 'three/src/loaders/LoadingManager';
import tinycolor from 'tinycolor2';

import { IDs, LIGHT_COLOR, LIGHTS, MATERIAL_METALNESS, MATERIAL_ROUGHNESS, THREEx } from './config';
import { getAllMeshes, getMatrix4, newGetMatrixWorldById } from './helpers';
import { ModelType } from './types';

const frameMaterialName = 'base color';
const frameSecondaryMaterial = 'stripes';
const frameSecondaryStripesMaterial = 'secdetail';
const frameDecalsMaterial = 'color stickers';
const frameSecondaryDecalsMaterial = 'secstickers';
const frameThirdDecalsMaterial = 'thirdstickers';
const shadowCatcherMaterial = 'shadow catcher';
const textureMaterialName = 'primtexture';
const secondaryTextureMaterialName = 'sectexture';

const decalsLightColor = '#FFF';
const decalsDarkColor = '#2D2926';

const rotateAnimationGroups = [
    'Bremsscheibe vorne',
    'Bremsscheibe hinten',
    'Pneu',
    'Laufräder',
    'Reifen nicht Tubeless',
    'Reifen Tubeless',
    'Reifen',
];
const zoomCamera = 'zoom-camera';
const partNameOfLight = 'Beleuchtung';
const showLogs = localStorage.getItem('hLogs')?.toLowerCase() === 'true' ? true : false;

export type Ref = any;

const Model: any = forwardRef<Ref, ModelType>(
    (
        {
            // Increased paddings to 20
            padding = {
                paddingTop: 20,
                paddingLeft: 0.2,
                paddingBottom: 20,
                paddingRight: 0.2,
            },
            modelData = [],
            colorFrame,
            allVariants,
            myColor,
            cameraControlsRef,
            onCloseLoading = function () {},
            selectedProductTypeId = null,
            isTurnOnLight = true,
            selectedGroupType = '',
            configuratorParts = [],
            loadingModelIds = [],
            loadingProgress,
            takingScreenshot,
        },
        ref,
    ) => {
        useImperativeHandle(ref, () => ({
            front: () => {
                if (cameraControlsRef.current) {
                    resetCamera();
                    cameraControlsRef.current.rotate(Math.PI / 2, 0, true);
                }
            },
            back: () => {
                if (cameraControlsRef.current) {
                    resetCamera();
                    cameraControlsRef.current.rotate(-Math.PI / 2, 0, true);
                }
            },
            left: () => {
                if (cameraControlsRef.current) {
                    resetCamera();
                    cameraControlsRef.current.zoomTo(minZoom / 1.25, true);
                }
            },
            right: () => {
                if (cameraControlsRef.current) {
                    resetCamera();
                    cameraControlsRef.current.rotate(Math.PI, 0, true);
                    cameraControlsRef.current.zoomTo(minZoom / 1.25, true);
                }
            },
            default: () => {
                if (cameraControlsRef.current) {
                    resetCamera();
                    cameraControlsRef.current.enabled = true;
                    cameraControlsRef.current.rotate(Math.PI / 4, -Math.PI / 10, true);
                }
            },
            twoDimension: () => {
                if (cameraControlsRef.current) {
                    resetCamera();
                    cameraControlsRef.current.enabled = false;
                }
            },
            onAnimationBegin: () => {
                if (!isFirstZoom) return;

                setTimeout(() => {
                    setIsRotateWheels(true);
                    let step = 0;

                    const increaseItv = setInterval(() => {
                        step += Math.PI / 1400;
                        if (step >= Math.PI / 15) {
                            rotationStep.current = Math.PI / 10;
                            clearInterval(increaseItv);

                            setTimeout(() => {
                                rotationStep.current = Math.PI / 10;
                                step = Math.PI / 10;
                                const decreaseItv = setInterval(() => {
                                    step -= Math.PI / 1100;
                                    if (step <= 0) {
                                        rotationStep.current = 0;
                                        clearInterval(decreaseItv);
                                        setIsRotateWheels(false);
                                    } else {
                                        rotationStep.current = step;
                                    }
                                }, 15);
                            }, 500);
                        } else {
                            rotationStep.current = step;
                        }
                    }, 10);
                }, 10);

                const group = new Group();
                group.add(...part3dsFirstZoom.map(item => item.clone()));
                fitCameraToObject(cameraControlsRef.current, group);
            },
        }));

        const { scene, size: canvasSize } = useThree();
        const [materialTexture, setMaterialTexture] = useState<any>(null);
        const [materialSecondaryTexture, setMaterialSecondaryTexture] = useState<any>(null);
        const [frameMaterials, setFrameMaterials] = useState<any>([]);
        const [secondaryFrameMaterials, setSecondaryFrameMaterials] = useState<any>([]);
        const [decalsFrameMaterials, setDecalsFrameMaterials] = useState<any>([]);
        const [secondaryDecalsFrameMaterials, setSecondaryDecalsFrameMaterials] = useState<any>([]);
        const [thirdDecalsFrameMaterials, setThirdDecalsFrameMaterials] = useState<any>([]);
        const [secondaryStripesFrameMaterials, setSecondaryStripesFrameMaterials] = useState<any>([]);
        const [part3ds, setPart3ds] = useState<any[]>([]);
        const [part3dsFirstZoom, setPart3dsFirstZoom] = useState<any[]>([]);
        const [isFirstZoom, setIsFirstZoom] = useState<boolean>(true);
        const [minZoom, setMinZoom] = useState<any>(2.7);
        const maxZoom = 30;
        const [defaultFitZoom, setDefaultFitZoom] = useState<any>(2.7);
        const [isRotateWheels, setIsRotateWheels] = useState<boolean>(false);
        const [defaultPos, setDefaultPos] = useState<Vector3>(new Vector3());
        const [defaultTarget, setDefaultTarget] = useState<Vector3>(new Vector3());
        const rotationStep = useRef<number>(0);
        const [preCanvasSize, setPreCanvasSize] = useState<number>(canvasSize.height);
        const [loadedModels, setLoadedModels] = useState<any[]>([]);
        const [defaultZoomModels, setDefaultZoomModels] = useState<any[]>([]);
        const [boxTransform, setBoxTransform] = useState<Vector3>(new Vector3());
        const [showOutline, setShowOutline] = useState(false);
        const renderer = useThree(state => state.gl);
        const manager = useMemo(() => new LoadingManager(), []);
        const textureLoader = useMemo(() => new TextureLoader(), []);
        const store = useContext(ConfiguratorStore);
        store.threeScene = scene;

        function resetCamera() {
            cameraControlsRef.current.setLookAt(
                defaultPos.x,
                defaultPos.y,
                defaultPos.z,
                defaultTarget.x,
                defaultTarget.y,
                defaultTarget.z,
                true,
            );
            const isPortrait = canvasSize.width < 500;
            cameraControlsRef.current.minZoom = isPortrait ? minZoom - 0.5 : minZoom - 2;
            cameraControlsRef.current.maxZoom = maxZoom;
            cameraControlsRef.current.zoomTo(defaultFitZoom, true);
        }

        function loadModel(
            url: string,
            id: string,
            partId: string,
            group: string,
            productTypeName: string,
        ): Promise<{
            object: Object3D;
            baseMaterials: any[];
            secondaryMaterials: any[];
            decalsMaterials: any[];
            secondaryDecalsMaterials: any[];
            secondaryStripesMaterials: any[];
            thirdDecalsMaterials: any[];
        } | null> {
            return new Promise(resolve => {
                const loader = new GLTFLoader(manager);
                if (!url) resolve(null);
                loader.load(
                    url,
                    function (data) {
                        const baseMaterials: any[] = [];
                        const secondaryMaterials: any[] = [];
                        const decalsMaterials: any[] = [];
                        const secondaryDecalsMaterials: any[] = [];
                        const thirdDecalsMaterials: any[] = [];
                        const secondaryStripesMaterials: any[] = [];

                        if (data.scene) {
                            data.scene.traverse((obj: any) => {
                                const name = obj.material?.name.toLowerCase();
                                if (obj.material) {
                                    if (name.includes(frameMaterialName)) {
                                        if (colorFrame && colorFrame.color) {
                                            obj.material.color = new Color(colorFrame.color);
                                            obj.material.map = null;
                                            obj.material.needsUpdate = true;
                                            obj.material.metalnessMap = null;
                                        }
                                        baseMaterials.push(obj.material);
                                    }
                                    if (name.includes(frameSecondaryMaterial)) {
                                        obj.material.depthWrite = true;
                                        secondaryMaterials.push(obj.material);
                                    }
                                    if (name.includes(frameSecondaryStripesMaterial)) {
                                        obj.material.depthWrite = true;
                                        secondaryStripesMaterials.push(obj.material);
                                    }
                                    if (name.includes(frameDecalsMaterial)) {
                                        decalsMaterials.push(obj.material);
                                    }
                                    if (name.includes(frameSecondaryDecalsMaterial)) {
                                        secondaryDecalsMaterials.push(obj.material);
                                    }
                                    if (name.includes(frameThirdDecalsMaterial)) {
                                        thirdDecalsMaterials.push(obj.material);
                                    }
                                }

                                if (obj.isMesh) {
                                    obj.castShadow = true;
                                    obj.material.shadowSide = FrontSide;
                                    obj.material.side = DoubleSide;
                                    if (name.includes(shadowCatcherMaterial)) {
                                        obj.material.side = FrontSide;
                                    }
                                }
                            });
                        }

                        data.scene.rotateY(Math.PI / 2);
                        data.scene.userData = { id: id, partId: partId, group, productTypeName, url };

                        resolve({
                            object: data.scene,
                            baseMaterials: baseMaterials,
                            secondaryMaterials,
                            decalsMaterials,
                            secondaryDecalsMaterials,
                            secondaryStripesMaterials,
                            thirdDecalsMaterials,
                        });
                    },
                    () => {},
                    () => {
                        resolve(null);
                    },
                );
            });
        }

        function moveOriginToCenter(part3ds: any[]) {
            const group = new Group();
            group.add(...part3ds.map(item => item.clone()));

            group.updateMatrixWorld();
            const box = new Box3();

            let objects = getAllMeshes(group, false);
            if (objects.length === 0) {
                return;
            }

            for (const object of objects) {
                box.expandByObject(object);
            }

            const centerPoint = box.getCenter(new Vector3());
            for (const item of part3ds) {
                (item as Object3D).translateX(-centerPoint.x);
                (item as Object3D).translateZ(-centerPoint.z);
                (item as Object3D).translateY(-centerPoint.y);
            }
            setBoxTransform(centerPoint);
        }

        async function fitCameraToObject(controls: any, sceneMeshes: Object3D, fitToVisibleOnly: boolean = false) {
            sceneMeshes.updateMatrixWorld();
            const box = new Box3();

            let objects = getAllMeshes(sceneMeshes, fitToVisibleOnly);
            if (objects.length === 0) {
                return;
            }

            for (const object of objects) {
                box.expandByObject(object);
            }

            if (isFirstZoom) {
                setIsFirstZoom(false);
                setTimeout(() => {
                    controls.rotateAzimuthTo(controls.azimuthAngle - 1.75 * Math.PI, true); // Decrease multiplier from 2 to 1.75
                    controls.rotatePolarTo(controls.polarAngle - 0.25, true); // Rotation of the camera angle
                }, 100);

                controls.polarRotateSpeed = 0.15;
                controls.azimuthRotateSpeed = 0.15;

                setDefaultPos(controls.getPosition(defaultPos));
                setDefaultTarget(controls.getFocalOffset(defaultTarget));

                await controls.fitToBox(box, true, padding);

                const isPortrait = canvasSize.width < 500;
                const portraitZoomLevel = canvasSize.width / 170;
                const newZoomLevel = canvasSize.height / 150;

                setMinZoom(isPortrait ? portraitZoomLevel : newZoomLevel);
                setDefaultFitZoom(isPortrait ? portraitZoomLevel : newZoomLevel);
                cameraControlsRef.current.minZoom = isPortrait ? portraitZoomLevel - 0.5 : newZoomLevel - 2;
                cameraControlsRef.current.maxZoom = maxZoom;
                cameraControlsRef.current.zoomTo(isPortrait ? portraitZoomLevel : newZoomLevel, true);
            }
        }

        function fitCameraToObjectComponent(controls: any, sceneMeshes: Object3D, fitToVisibleOnly: boolean = false) {
            sceneMeshes.updateMatrixWorld();
            const box = new Box3();

            let objects = getAllMeshes(sceneMeshes, fitToVisibleOnly);
            if (objects.length === 0) {
                return;
            }

            for (const object of objects) {
                box.expandByObject(object);
            }
            const centerBox = box.getCenter(new Vector3());

            const part = modelData.find((i: any) => i.productType?.id === selectedGroupType);

            if (
                part &&
                part.productType?.metadata &&
                part.productType?.metadata.find(item => item.key === zoomCamera)
            ) {
                const matrix = part.productType?.metadata.find(item => item.key === zoomCamera)?.value;
                if (matrix) {
                    const matrixCamera = getMatrix4(matrix);
                    const gr = new Group();
                    gr.applyMatrix4(matrixCamera);
                    gr.updateMatrixWorld();
                    setTimeout(() => {
                        controls.setLookAt(
                            gr.position.x,
                            gr.position.y,
                            gr.position.z,
                            centerBox.x,
                            centerBox.y,
                            centerBox.z,
                            true,
                        );

                        const x = box.max.x - box.min.x;
                        const y = box.max.y - box.min.y;
                        const z = box.max.z - box.min.z;

                        let radius = x;
                        if (radius < y) {
                            radius = y;
                            if (radius < z) {
                                radius = z;
                            }
                        } else if (radius < z) {
                            radius = z;
                        }

                        const geometry = new SphereGeometry(radius / 2, 30, 30);
                        const material = new MeshBasicMaterial();
                        const sphere = new Mesh(geometry, material);
                        sphere.position.set(centerBox.x, centerBox.y, centerBox.z);

                        controls.minZoom = minZoom;
                        controls.maxZoom = maxZoom;
                        controls.fitToSphere(sphere, true);
                    }, 500);
                }
            } else {
                setTimeout(() => {
                    resetCamera();

                    controls.fitToBox(box, true, padding);
                    const positionControl = controls.getPosition(new Vector3());
                    controls.moveTo(centerBox.x, positionControl.y, positionControl.z, true);
                }, 150);
            }
        }

        useEffect(() => {
            manager.onProgress = function (url, itemsLoaded, itemsTotal) {
                const progress = (itemsLoaded / itemsTotal) * 100;
                loadingProgress(progress);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (colorFrame) {
                const variant = allVariants?.find((v: any) => v.id === colorFrame.id);
                const stripes = variant?.attributes.find((a: any) => a.attribute?.slug === 'secondary-color');
                const stickers = variant?.attributes.find((a: any) => a.attribute?.slug === 'stickers-color');
                const secondaryStrickers = variant?.attributes.find(
                    (a: any) => a.attribute?.slug === 'secondary-stickers-color',
                );
                const thirdStickers = variant?.attributes.find(
                    (a: any) => a.attribute?.slug === 'third-stickers-color',
                );
                const secondaryStripes = variant?.attributes.find(
                    (a: any) => a.attribute?.slug === 'secondary-stripes-color',
                );
                const metalness = variant?.attributes.find((a: any) => a.attribute?.slug === 'material-metalness');
                const roughness = variant?.attributes.find((a: any) => a.attribute?.slug === 'material-roughness');
                const stickerMetalness = variant?.attributes.find(
                    (a: any) => a.attribute?.slug === 'sticker-metalness',
                );
                const stickerRoughness = variant?.attributes.find(
                    (a: any) => a.attribute?.slug === 'sticker-roughness',
                );
                const stickerOpacity = variant?.attributes.find((a: any) => a.attribute?.slug === 'sticker-opacity');
                const secondStickerOpacity = variant?.attributes.find(
                    (a: any) => a.attribute?.slug === 'sticker-opacity-2',
                );
                const thirdStickerOpacity = variant?.attributes.find(
                    (a: any) => a.attribute?.slug === 'sticker-opacity-3',
                );
                const bikeTexture = variant?.attributes.find((a: any) => a.attribute?.slug === 'primtexture')
                    ?.values[0];
                const bikeSecondaryTexture = variant?.attributes.find((a: any) => a.attribute?.slug === 'sectexture')
                    ?.values[0];

                if (stripes) {
                    setModelColors(stripes.values[0]?.name, secondaryFrameMaterials, true);
                }
                if (secondaryStrickers) {
                    setModelColors(secondaryStrickers.values[0]?.name, secondaryDecalsFrameMaterials, true);
                }
                if (thirdStickers) {
                    setModelColors(thirdStickers.values[0]?.name, thirdDecalsFrameMaterials, true);
                }
                if (secondaryStripes) {
                    setModelColors(secondaryStripes.values[0]?.name, secondaryStripesFrameMaterials, true);
                }
                if (stickers) {
                    setModelColors(stickers.values[0]?.name, decalsFrameMaterials, true);
                } else {
                    // colorDecals(colorFrame.color);
                }

                if (metalness || roughness) {
                    for (const material of frameMaterials) {
                        material.metalness = parseInt(metalness.values[0]?.name) || MATERIAL_METALNESS;
                        material.roughness = parseInt(roughness.values[0]?.name) || MATERIAL_ROUGHNESS;
                    }
                }
                if (stickerRoughness || stickerMetalness) {
                    for (const material of decalsFrameMaterials) {
                        material.roughness = parseInt(stickerRoughness.values[0]?.name || MATERIAL_ROUGHNESS);
                        material.metalness = parseInt(stickerMetalness?.values[0]?.name || MATERIAL_METALNESS);
                    }
                    for (const material of secondaryDecalsFrameMaterials) {
                        material.roughness = parseInt(stickerRoughness.values[0]?.name || MATERIAL_ROUGHNESS);
                        material.metalness = parseInt(stickerMetalness?.values[0]?.name || MATERIAL_METALNESS);
                    }
                }
                if (stickerOpacity) {
                    for (const material of decalsFrameMaterials) {
                        material.opacity = stickerOpacity.values[0] ? Number(stickerOpacity.values[0]?.name) : 1;
                    }
                }
                if (secondStickerOpacity) {
                    for (const material of secondaryDecalsFrameMaterials) {
                        material.opacity = secondStickerOpacity.values[0]
                            ? Number(secondStickerOpacity.values[0]?.name)
                            : 1;
                    }
                }
                if (thirdStickerOpacity) {
                    for (const material of thirdDecalsFrameMaterials) {
                        material.opacity = thirdStickerOpacity.values[0]
                            ? Number(thirdStickerOpacity.values[0]?.name)
                            : 1;
                    }
                }

                // Load and set textures to state in order to use where needed
                const text = loadTexture(bikeTexture?.file?.url);
                setMaterialTexture(text ? text : null);
                const textSec = loadTexture(bikeSecondaryTexture?.file?.url);
                setMaterialSecondaryTexture(textSec ? textSec : null);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            colorFrame,
            allVariants,
            secondaryFrameMaterials,
            decalsFrameMaterials,
            secondaryDecalsFrameMaterials,
            thirdDecalsFrameMaterials,
            secondaryStripesFrameMaterials,
        ]);

        // Load textures and flip them accordingly so it can be used in the scene
        const loadTexture = (textureUrl: string) => {
            if (!textureUrl) return null;
            const texture = textureLoader.load(textureUrl);
            texture.encoding = sRGBEncoding;
            texture.wrapS = RepeatWrapping; // Allow horizontal wrapping
            texture.wrapT = RepeatWrapping; // Allow vertical wrapping
            texture.repeat.x = 1; // Flip horizontally
            texture.repeat.y = -1; // No vertical flip
            texture.offset.x = 1; // Adjust offset to align the flipped texture

            return texture;
        };

        useEffect(() => {
            cameraControlsRef.current.rotateTo(Math.PI / 2, Math.PI / 2, false);

            // LOAD HDR MAP
            renderer.outputEncoding = sRGBEncoding;
            renderer.toneMapping = ACESFilmicToneMapping;
            renderer.toneMappingExposure = 1.8;
            const loader = new RGBELoader();
            loader.load('/hdri2.hdr', function (texture) {
                texture.mapping = EquirectangularReflectionMapping;
                scene.environment = texture;
            });

            // ADD POINT LIGHTS TO THE SCENE
            const light2 = new PointLight(LIGHTS.color, LIGHTS.intensity, LIGHTS.distance, LIGHTS.decay);
            const light4 = new PointLight(LIGHTS.color, LIGHTS.intensity, LIGHTS.distance, LIGHTS.decay);

            light2.position.set(500, 100, 0);
            scene.add(light2);
            light4.position.set(-500, 300, 0);
            scene.add(light4);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (isFirstZoom || preCanvasSize === canvasSize.height) return;

            const isPortrait = canvasSize.width < 500;
            const newZoomLevel = canvasSize.height / 150;
            const portraitZoomLevel = canvasSize.width / 170;
            cameraControlsRef.current.mouseButtons.right = 2;

            setMinZoom(isPortrait ? portraitZoomLevel : newZoomLevel);
            cameraControlsRef.current.minZoom = isPortrait ? portraitZoomLevel - 0.5 : newZoomLevel - 2;
            setDefaultFitZoom(isPortrait ? portraitZoomLevel : newZoomLevel);
            setTimeout(() => {
                cameraControlsRef.current.zoomTo(isPortrait ? portraitZoomLevel : newZoomLevel, true);
            }, 10);

            setPreCanvasSize(canvasSize.height);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [canvasSize]);

        useEffect(() => {
            if (isFirstZoom || !part3dsFirstZoom.length) return;

            const group = new Group();
            group.add(...part3dsFirstZoom);
            drawCircleGround(group);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isTurnOnLight]);

        useEffect(() => {
            if (!selectedGroupType || !defaultZoomModels.length) return;
            const objs = defaultZoomModels.find(dm => dm.id === selectedGroupType);

            if (objs && objs.objects) {
                const group = new Group();
                // eslint-disable-next-line array-callback-return
                group.add(
                    ...objs.objects.map((obj: any) => {
                        const item: any = obj.clone();
                        item.translateX(-boxTransform.x);
                        item.translateZ(-boxTransform.z);
                        item.translateY(-boxTransform.y);
                        return item;
                    }),
                );
                fitCameraToObjectComponent(cameraControlsRef.current, group, false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedGroupType]);

        useEffect(() => {
            if (!part3dsFirstZoom.length) return;

            const group = new Group();

            group.add(...part3dsFirstZoom);
            drawCircleGround(group);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [part3dsFirstZoom]);

        useEffect(() => {
            if (myColor && myColor.length > 0) {
                // temporary solution for "c.frame.toLowerCase() === 'frame color 1'" for preconfigs that already exists
                const base = myColor.find(c => c.key === 'frame-color-1' || c.frame.toLowerCase() === 'frame color 1');
                const secondary = myColor.find(
                    c => c.key === 'frame-color-2' || c.frame.toLowerCase() === 'frame color 2',
                );
                if (base) {
                    setModelColors(base.hex, frameMaterials);
                    colorDecals(base.hex);
                }
                if (secondary) {
                    setModelColors(secondary.hex, thirdDecalsFrameMaterials);
                    setModelColors(secondary.hex, decalsFrameMaterials);
                }
            } else {
                if (!colorFrame || !colorFrame.color || !frameMaterials) return;
                setModelColors(colorFrame.color, frameMaterials);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [colorFrame, frameMaterials, myColor]);

        useEffect(() => {
            if (takingScreenshot) {
                setShowOutline(false);
            }
        }, [takingScreenshot]);

        const colorDecals = (color: string) => {
            const isLight = tinycolor(color).isLight();
            if (isLight) {
                setModelColors(decalsDarkColor, decalsFrameMaterials, true);
            } else {
                setModelColors(decalsLightColor, decalsFrameMaterials, true);
            }
        };

        useFrame(() => {
            if (isRotateWheels && part3ds) {
                for (const item of part3ds) {
                    if (rotateAnimationGroups.includes(item.userData?.productTypeName)) {
                        if (item.children.length > 0) {
                            item.children[0].rotation.x -= rotationStep.current;
                        }
                    }
                }
            }
        });

        // CHANGE FRAME COLOR
        function setModelColors(color: string, materials: any, useMap = false) {
            if (color && materials) {
                const value = new Color(color);
                for (const frameMaterial of materials) {
                    // If texture is present, use that one instead of color
                    if (frameMaterial.name.toLowerCase().includes(textureMaterialName) && materialTexture) {
                        frameMaterial.color = new Color('0xffffff');
                        frameMaterial.normalMap = null;
                        frameMaterial.map = materialTexture;
                        frameMaterial.needsUpdate = true;

                        continue;
                    }
                    // If secondary texture is present, apply it to according model
                    if (
                        frameMaterial.name.toLowerCase().includes(secondaryTextureMaterialName) &&
                        materialSecondaryTexture
                    ) {
                        frameMaterial.color = new Color('0xffffff');
                        frameMaterial.normalMap = null;
                        frameMaterial.map = materialSecondaryTexture;
                        frameMaterial.needsUpdate = true;

                        continue;
                    }

                    var initial = new Color(frameMaterial.color.getHex());
                    TweenLite.to(initial, 0.5, {
                        r: value.r,
                        g: value.g,
                        b: value.b,

                        // eslint-disable-next-line no-loop-func
                        onUpdate: function () {
                            frameMaterial.color = initial;
                            if (!useMap) frameMaterial.map = null;
                            frameMaterial.needsUpdate = true;
                        },
                    });
                    if (!useMap) frameMaterial.map = null;
                    frameMaterial.needsUpdate = true;
                }
            }
        }

        useEffect(() => {
            if (!modelData || !modelData.length) return;

            const productPartModels: any[] = [...modelData];

            const data3ds: any[] = [];

            const modelDataLoading = [];

            for (const item of productPartModels) {
                if (item.visible) {
                    const model = loadedModels.find(i => i.object?.userData?.url === item.productModel);
                    if (model) {
                        data3ds.push({ ...model, object: model.object.clone() });
                    } else {
                        modelDataLoading.push(
                            loadModel(
                                item.productModel,
                                item.productType?.id,
                                item.id,
                                item.group,
                                item.productType?.name,
                            ),
                        );
                    }
                }
            }

            getItemsTree(productPartModels);

            if (!modelDataLoading.length) {
                addModels(data3ds, productPartModels);
            } else {
                Promise.all(modelDataLoading).then((data: Array<{ object: Object3D; baseMaterials: any[] } | null>) => {
                    data3ds.push(...data.filter(i => i != null));
                    addModels(data3ds, productPartModels);
                    setLoadedModels([
                        ...loadedModels,
                        ...data.filter(i => i != null).map(i => ({ ...i, object: i?.object?.clone() })),
                    ]);
                    if (isFirstZoom) {
                        onCloseLoading();
                    }
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [modelData]);

        useEffect(() => {
            loadDefaultZoomModels();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [modelData, configuratorParts, loadedModels]);

        useEffect(() => {
            for (const item of loadingModelIds) {
                if (!item.isLoaded && !item.isLoading) {
                    const allModel = modelData.filter((i: any) => i.id === item.id);
                    item.isLoading = true;
                    for (const m of allModel) {
                        const part: any = { ...m };
                        loadModel(
                            part.productModel,
                            part.productType?.id,
                            part.id,
                            part.group,
                            part.productType?.name,
                        ).then((data: { object: Object3D; baseMaterials: any[] } | null) => {
                            if (data) {
                                loadedModels.push({ ...data, object: data.object.clone() });
                                item.isLoading = false;
                                item.isLoaded = true;
                            }
                        });
                    }
                }
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [loadingModelIds.length, modelData]);

        function loadDefaultZoomModels() {
            if (!configuratorParts || !modelData.length || !loadedModels.length) return;

            const loadedDefaultModels = [...defaultZoomModels];

            // If the file is already loaded, use that one for
            // camera zoom instead of loading a new random one
            for (const p of loadedModels) {
                const defaultModel = loadedDefaultModels.find(dm => dm.type === p.object?.userData?.productTypeName);

                if (!defaultModel) {
                    loadedDefaultModels.push({
                        id: p.object.userData.id,
                        type: p.object.userData.productTypeName,
                        objects: [],
                    });
                }
            }

            const loadModels = [];

            for (const c of configuratorParts) {
                const defaultModel = loadedDefaultModels.find(dm => dm.type === c.type);

                if (!defaultModel) {
                    const filterLoadingModelId: any = modelData.find((i: any) => i.productType?.name === c.type);
                    if (filterLoadingModelId && filterLoadingModelId.id) {
                        const filterLoadingModel = modelData.filter((i: any) => i.id === filterLoadingModelId.id);

                        if (filterLoadingModel.length) {
                            loadModels.push(
                                ...filterLoadingModel.map((m: any) =>
                                    loadModel(m.productModel, m.productType?.id, m.id, m.group, m.productType?.name),
                                ),
                            );
                        }
                    }

                    loadedDefaultModels.push({
                        type: c.type,
                        objects: [],
                    });
                }
            }

            if (!loadModels) return;

            const productPartModels: any[] = modelData.map(item => JSON.parse(JSON.stringify(item)));

            getItemsTree(productPartModels);

            Promise.all(loadModels).then(rs => {
                if (rs) {
                    // merge the newly loaded models and already loaded ones into a list
                    const list = [...rs, ...loadedModels];
                    for (const obj of list) {
                        if (!obj) continue;

                        const item: any = obj.object;

                        // eslint-disable-next-line no-loop-func
                        item.traverse((i: any) => {
                            i.position.set(0, 0, 0);
                            i.rotation.set(0, 0, 0);

                            i.userData = { ...i.userData, id: item.userData.id };
                        });
                        item.position.set(0, 0, 0);
                        item.rotation.set(0, 0, 0);
                        item.updateMatrixWorld();

                        const matrixWorld = newGetMatrixWorldById(item.userData.partId, productPartModels);

                        item.applyMatrix4(matrixWorld);
                        item.updateMatrixWorld();

                        const defaultData = loadedDefaultModels.find(i => i.type === item.userData.productTypeName);

                        defaultData.objects = [...defaultData.objects, item];
                    }

                    setDefaultZoomModels(loadedDefaultModels);
                }
                for (const item of productPartModels) {
                    delete item.isGetMatrix;

                    if (item.slots) {
                        for (const slot of item.slots) {
                            delete slot.isUsed;
                        }
                    }
                }
            });
        }

        // ADD ALL MODELS
        function addModels(data: any[], productPartModels: any[]) {
            const newParts: SetStateAction<any[]> = [];
            const baseMaterials = [];
            const secondaryMaterials = [];
            const decalsMaterials = [];
            const secondaryStripesMaterials = [];
            const secondaryDecalsMaterials = [];
            const thirdDecalsMaterials = [];

            for (const mat of frameMaterials) {
                mat.roughness = 2;
            }

            // For all the products check what type the parentId is, find by that type the visible
            // product in the scene and set it as an actual parentId
            for (const model of productPartModels) {
                const parent = productPartModels.find(p => p.id === model.parentId);
                if (!parent) continue;
                const activeProduct = productPartModels.find(
                    d => d.productType?.id === parent.productType?.id && d.visible,
                );
                if (activeProduct) {
                    model.parentId = activeProduct.id;
                } else {
                    // If there is no active product for the given model try and find
                    // if there is another parent to it that is a different product type
                    // Search is done by checking if another model has this one in the reference models
                    for (const p of productPartModels) {
                        if (!p.slots) continue;
                        for (const slot of p.slots) {
                            if (!slot.modelReferences) continue;
                            const exists = slot.modelReferences.find((s: any) => s.id === model.modelId && p.visible);
                            if (exists) model.parentId = p.id;
                        }
                    }
                }
            }

            for (const rs of data) {
                baseMaterials.push(...rs.baseMaterials);
                secondaryMaterials.push(...rs.secondaryMaterials);
                decalsMaterials.push(...rs.decalsMaterials);
                secondaryDecalsMaterials.push(...rs.secondaryDecalsMaterials);
                secondaryStripesMaterials.push(...rs.secondaryStripesMaterials);
                thirdDecalsMaterials.push(...rs.thirdDecalsMaterials);

                const item = rs.object;

                const part = productPartModels.find(
                    i => i.productType?.id === item.userData.id && i.id === item.userData.partId,
                );

                item.visible = part.visible;
                if (part.productType?.isBikeType) item.visible = true;

                // eslint-disable-next-line no-loop-func
                item.traverse((i: any) => {
                    i.position.set(0, 0, 0);
                    i.rotation.set(0, 0, 0);

                    i.userData = { ...i.userData, id: item.userData.id };
                });
                item.position.set(0, 0, 0);
                item.rotation.set(0, 0, 0);

                item.updateMatrixWorld();

                const matrixWorld = newGetMatrixWorldById(part.id, productPartModels, part.modelId);

                if (item.children.length > 0) {
                    for (const child of item.children) {
                        child.applyMatrix4(matrixWorld);
                    }
                } else {
                    item.applyMatrix4(matrixWorld);
                }

                item.updateMatrixWorld();
                setShowOutline(true);

                newParts.push(item);
                setTimeout(() => {
                    setShowOutline(false);
                }, 7000);
            }

            // setDefaultZoomModels(dfZoom)
            setFrameMaterials(baseMaterials);
            setSecondaryFrameMaterials(secondaryMaterials);
            setDecalsFrameMaterials(decalsMaterials);
            setSecondaryDecalsFrameMaterials(secondaryDecalsMaterials);
            setThirdDecalsFrameMaterials(thirdDecalsMaterials);
            setSecondaryStripesFrameMaterials(secondaryStripesMaterials);

            for (const item of productPartModels) {
                delete item.isGetMatrix;

                if (item.slots) {
                    for (const slot of item.slots) {
                        delete slot.isUsed;
                    }
                }
            }
            // Find if item needs to morph any other item
            for (const item of newParts) {
                item.traverse((i: any) => {
                    if (i.name.includes('morph')) {
                        if (showLogs) {
                            console.log(`${i.name} >>> ${item.userData.productTypeName}`);
                        }
                        const arr = i.name.split('-');
                        const key = arr[0];
                        const value = parseInt(arr[1]);
                        for (const obj of newParts) {
                            obj.traverse((o: any) => {
                                if (o.morphTargetDictionary) {
                                    const index = o.morphTargetDictionary[key];
                                    o.morphTargetInfluences[index] = value > 1 ? parseFloat(`0.${value}`) : value;
                                }
                            });
                        }
                    }
                });
            }
            moveOriginToCenter(newParts);
            setPart3ds(newParts);
            setPart3dsFirstZoom(newParts.map(item => item.clone()));
        }

        function drawCircleGround(mesh: Object3D) {
            if (!mesh) return;

            for (const name of Object.keys(IDs)) {
                const item = scene.getObjectByName(name);
                if (item) {
                    if ((item as Mesh).geometry) {
                        (item as Mesh).geometry.dispose();
                    }
                    scene.remove(item);
                }
            }

            mesh.updateMatrixWorld();
            const box = new Box3();
            let objects = getAllMeshes(mesh, true);

            if (objects.length === 0) {
                return;
            }

            for (const object of objects) {
                box.expandByObject(object);
            }

            const boxCenter = box.getCenter(new Vector3());
            const planePos = new Vector3(boxCenter.x, box.min.y - 1, boxCenter.z);

            const vertices = [];
            const divisions = 300;
            for (let i = 0; i <= divisions; i++) {
                const v = (i / divisions) * (Math.PI * 2);
                const x = Math.sin(v) * 120;
                const z = Math.cos(v) * 120;
                vertices.push(x, 0, z);
            }
            const geometry = new BufferGeometry();
            geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));

            const material = new LineDashedMaterial({
                color: '#828282',
                linewidth: 1,
                dashSize: 10,
                gapSize: 10,
            });
            const line = new Line(geometry, material);
            line.position.set(planePos.x, planePos.y + 1, planePos.z);
            line.name = IDs.lineId;
            // scene.add(line);

            var geometryPlane = new PlaneGeometry(1000000, 1000000);
            const planeMaterial = new ShadowMaterial({ side: BackSide });
            const plane = new Mesh(geometryPlane, planeMaterial);

            plane.rotateX(Math.PI / 2);
            plane.position.set(planePos.x, planePos.y + 1, planePos.z);
            plane.material.opacity = 0.15;
            plane.receiveShadow = true;
            plane.name = IDs.planeId;
            // scene.add(plane);

            const boxLine = new Box3();
            boxLine.expandByObject(line);

            const mainLight = isTurnOnLight ? 0xffffff : 0x404040;
            const dirLight = new SpotLight(mainLight, 2, 700, undefined, undefined, 2);
            dirLight.position.set(boxCenter.x, box.max.y + 240, boxCenter.z);
            dirLight.castShadow = true;
            dirLight.shadow.mapSize.width =
                (box.max.z - box.min.z > box.max.x - box.min.x ? box.max.z - box.min.z : box.max.x - box.min.x) - 20;
            dirLight.shadow.mapSize.height = box.max.y - box.min.y;
            dirLight.shadow.camera.far = 2 * (box.max.y - box.min.y) + 300;
            dirLight.shadow.camera.near = 1;
            dirLight.name = IDs.dirLight1Id;

            dirLight.angle = Math.PI / 7;
            // scene.add(dirLight);

            setTimeout(() => {
                addBikeLight(LIGHT_COLOR, IDs.bikeLight);
            }, 100);
        }

        function getItemsTree(data: any[]) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].slots) {
                    for (let j = 0; j < data[i].slots.length; j++) {
                        const itemsInSlot = data.filter(
                            item =>
                                item !== data[i] &&
                                item.productType?.id === data[i].slots[j].productType?.id &&
                                !item.parentId,
                        );

                        if (!itemsInSlot.length) continue;

                        for (const item of itemsInSlot) {
                            const isMatch = data[i].slots[j].modelReferences.find(
                                (mR: any) => item.modelId && mR.id === item.modelId,
                            );
                            if (isMatch) {
                                item.parentId = data[i].id;
                                if (!data[i].childrens) {
                                    data[i].childrens = [];
                                }
                                data[i].childrens.push(item);
                            }
                        }
                    }
                }
            }

            for (let i = 0; i < data.length; i++) {
                if (data[i].slots) {
                    for (let j = 0; j < data[i].slots.length; j++) {
                        const itemsInSlot = data.filter(
                            item =>
                                item !== data[i] &&
                                item.productType?.id === data[i].slots[j].productType?.id &&
                                !item.parentId,
                        );

                        if (!itemsInSlot.length) continue;

                        for (const item of itemsInSlot) {
                            item.parentId = data[i].id;
                            if (!data[i].childrens) {
                                data[i].childrens = [];
                            }
                            data[i].childrens.push(item);
                        }
                    }
                }
            }
        }

        function addBikeLight(lightColor: any, bikeLightName: string) {
            if (isTurnOnLight) return;

            const bikeLight = part3ds.find(
                item =>
                    item.visible &&
                    item.userData.productTypeName &&
                    item.userData.productTypeName.includes(partNameOfLight),
            );

            if (!bikeLight) return;

            const lightBox = new Box3();
            let objects = getAllMeshes(bikeLight, true);

            if (objects.length === 0) {
                return;
            }

            for (const object of objects) {
                lightBox.expandByObject(object);
            }

            const centerBox = lightBox.getCenter(new Vector3());

            var geometry = new ConeGeometry(120, 350, 32 * 4);
            geometry.translate(0, -175, 0);

            var material = THREEx.VolumetricSpotLightMaterial();
            const mesh = new Mesh(geometry, material);
            mesh.position.set(centerBox.x, lightBox.min.y + 2.5, lightBox.min.z + 2);
            material.uniforms.lightColor.value.set(lightColor);
            material.uniforms.spotPosition.value = mesh.position;

            mesh.rotation.set(Math.PI / 2 - Math.PI / 18, 0, 0);
            mesh.name = bikeLightName;
            scene.add(mesh);
        }

        useEffect(() => {
            if (store.isTwoDimension) {
                cameraControlsRef.current.enabled = false;
            } else {
                cameraControlsRef.current.enabled = true;
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [store.isTwoDimension]);

        return (
            <Selection>
                <EffectComposer multisampling={0} autoClear={!showOutline}>
                    <Outline
                        visibleEdgeColor={0x35e3e3}
                        // hiddenEdgeColor={0xf09c99}
                        xRay={true}
                        edgeStrength={10}
                        kernelSize={8}
                    />
                </EffectComposer>
                {part3ds.map((obj, index) => {
                    if (selectedProductTypeId && selectedProductTypeId === obj.userData.id) {
                        return (
                            <Select key={index} enabled={true}>
                                <primitive key={index} object={obj} />
                            </Select>
                        );
                    }
                    return (
                        <Select key={index} enabled={false}>
                            <primitive key={index} object={obj} />
                        </Select>
                    );
                })}
            </Selection>
        );
    },
);

export default Model;
