import { GET_BIKEID_BY_SID } from 'api/queries/configurator';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';
import { Button } from 'shared/components/ui/Button/Button';
import { Input } from 'shared/components/ui/Input/Input';
import { apolloClient } from 'shared/lib/apollo';

import styles from './SearchSidForm.module.scss';
interface SearchFormType {
    sid: string;
}

export const SearchSidForm: React.FC<{ onSuccess?: () => void }> = observer(({ onSuccess }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors, isValid, touchedFields },
    } = useForm<SearchFormType>({
        mode: 'onChange',
        defaultValues: {
            sid: '',
        },
    });

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: SearchFormType) => {
        setIsLoading(true);
        try {
            const response = await apolloClient.query({
                query: GET_BIKEID_BY_SID,
                variables: { sid: data.sid.toUpperCase() },
            });
            if (response?.data?.configuration?.id) {
                reset();
                window.location.href = `${AppRoutes.configurator}/${data.sid.toUpperCase()}`;
                onSuccess?.();
            } else {
                setError('sid', { message: t('searchCode.notFound') });
            }
        } catch (error: any) {
            console.error('Network or other error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
            <div className={styles.body}>
                <h5>{t('searchCode.title')}</h5>
                <Input
                    {...register('sid', {
                        required: t('fieldRequired'),
                        minLength: {
                            value: 8,
                            message: t('searchCode.codeLength'),
                        },
                    })}
                    style={{
                        textTransform: 'uppercase',
                    }}
                    maxLength={8}
                    placeholder={t('searchCode.placeholder')}
                    error={touchedFields?.sid && errors.sid?.message}
                />
                <Button htmlType="submit" className={styles.button} isDisabled={!isValid} isLoading={isLoading}>
                    {t('sendRequest')}
                </Button>
            </div>
        </form>
    );
});
