export interface CheckoutType {
    id: string;
    email?: string;
    token: string;
    metadata: MetadataType[];
    shippingAddress?: AddressType;
    billingAddress?: AddressType;
    shippingPrice?: PriceType;
    subtotalPrice: PriceType;
    totalPrice: PriceType;
    discount?: MoneyType;
    user?: UserType;
    availableCollectionPoints: WarehouseType[];
    shippingMethods?: ShippingMethodType[];
    deliveryMethod?: DeliveryMethodType;
    availablePaymentGateways?: PaymentGatewaysType[];
    voucherCode?: string;
    lines: any;
    linesByVariantGroup?: LinesByVariantGroup[];
    configurationImage?: string;
    files: CheckoutFile[];
    phone: string | null;
    financingStatus: string | null;
    financingId: string | null;
}

export interface CheckoutFile {
    id: string;
    fileName: string;
    base64File: string;
}

export interface MetadataObject {
    checkoutToken?: string;
    templateId?: string;
    sittingPosition?: string;
    size?: string;
    color?: string;
    frameColor1?: string;
    frameColor2?: string;
    barColor?: string;
    colorType?: string;
}

export interface CustomColorType {
    id: string;
    hex: string;
    name: string;
    group: string;
}

export interface LinesByVariantGroup {
    group: string;
    lines: LinesType[];
}

export interface MetadataType {
    key: string;
    value: string;
}

export interface LinesType {
    id: string;
    totalPrice: { net: MoneyType; gross: MoneyType };
    variant: {
        id: string;
        name: string;
        product: {
            name: string;
            translation?: any;
            productType: {
                name: string;
                slug: string;
            };
        };
    };
}

export interface PaymentGatewaysType {
    id: string;
    name: string;
    config: {
        field: string;
        value: string;
    };
}

export interface AddressType {
    id: string;
    firstName: string;
    lastName: string;
    country: CountryType;
    countryArea?: string;
    streetAddress1: string;
    postalCode: string;
    city: string;
    phone: string;
    companyName?: string;
}

export interface AddressInputType {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    streetAddress1?: string;
    streetAddress2?: string;
    city?: string;
    postalCode?: string;
    country?: string;
    countryArea?: string;
    phone?: string;
}

export const mapAddressTypeToAddressInputType = (address: AddressType) => {
    const addressInput: AddressInputType = {
        firstName: address.firstName,
        lastName: address.lastName,
        companyName: address.companyName,
        streetAddress1: address.streetAddress1,
        // streetAddress2: address.streetAddress1,
        postalCode: address.postalCode,
        city: address.city,
        country: address.country.code,
        countryArea: address.countryArea,
        phone: address.phone,
    };
    return addressInput;
};

export interface CountryType {
    code: string;
    country: string;
}

export interface PriceType {
    currency?: string;
    gross?: MoneyType;
    tax?: MoneyType;
    net?: MoneyType;
}

export interface MoneyType {
    amount: number;
    currency: string;
}

export interface UserType {
    id: string;
    token: string;
    email: string;
    firstName: string;
    lastName: string;
    addresses: AddressType[];
}

export interface WarehouseType {
    id: string;
    name: string;
    email: string;
    latitude: string;
    longitude: string;
    openingHours: OpeningHoursType[];
    address: AddressType;
}

export interface OpeningHoursType {
    id: string;
    weekday: string;
    fromHour: string;
    toHour: string;
}

export interface ShippingMethodType {
    id: string;
    name: string;
    price: MoneyType;
    translation?: {
        name: string;
    };
}

export interface DeliveryMethodType {
    __typename: string;
    name: string;
}

export interface HubspotArgsType {
    configurationShared?: boolean;
    configurationSaved?: boolean;
    orderId?: string;
    amount?: number;
    sid?: string;
    email?: string;
    dealId?: string;
}
