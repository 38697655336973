import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { DeviceDetectorContext } from 'shared/providers/DeviceDetector';
import ConfiguratorStore from 'stores/Configurator.store';

import CheckoutStore from '../../../../stores/Checkout.store';
import { LocalStorageKey } from '../../../helpers/localStorage';
import { EMAIL_REGEX } from '../../../helpers/validators';
import { getDatetime } from '../../../providers/utils';
import { gtmEvents } from '../../../types/gtmEvents';
import { hubspotEvents } from '../../../types/hubspotEvents';
import { Button } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { Loader } from '../Loader/Loader';
import { Modal } from '../Modal/Modal';

import styles from './CopyLink.module.scss';

interface EmailFormType {
    name: string;
    email: string;
    message: string;
}

export const CopyLink = observer(() => {
    const { device } = useContext(DeviceDetectorContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [copy, setCopy] = useState(false);
    const [linkText, setLinkText] = useState('saveForLater');
    const [isLoading, setIsLoading] = useState(true);
    const [userEmail, setUserEmail] = useState('');
    const [isMarketingAccepted, setIsMarketingAccepted] = useState(true);

    const store = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);

    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmailFormType>();

    useEffect(() => {
        if (device === 'desktop') setLinkText(t('saveForLater'));
        if (device === 'tablet') setLinkText('Copy');
        if (device === 'mobile') setLinkText('');
    }, [device, t]);

    const copyToClipboard = () => {
        setCopy(true);
        setTimeout(() => setCopy(false), 5000);
        const copyText = `${process.env.REACT_APP_PUBLIC_URL}/share/${store.shareableID}`;

        window.dataLayer.push({
            event: gtmEvents.BIKE_SID_COPY,
            copyText,
        });

        checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_SHARABLE_ID_COPY, getDatetime());

        navigator.clipboard.writeText(copyText);
    };

    const getSID = async () => {
        // only generate new sid if some parts are changed
        if (!store.shareableID) {
            store.saveConfiguration();
        } else {
            store.createScreenshot();
        }
        setModalOpen(true);
        const email = localStorage.getItem(LocalStorageKey.EMAIL) ?? '';
        await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_PRECONFIGURATION_CREATE, store.shareableID);
        setUserEmail(email);

        window.dataLayer.push({
            event: gtmEvents.BIKE_SID_MODAL,
        });
        // await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_SID_MODAL, store.shareableID);
        await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_SHARABLE_ID_MODAL, getDatetime());
    };

    useEffect(() => {
        if (store.takeScreenshot) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.takeScreenshot]);

    useEffect(() => {
        localStorage.setItem(LocalStorageKey.MARKETING_ACCEPTED, `${isMarketingAccepted}`);
    }, [isMarketingAccepted]);

    const onSubmit = async (data: EmailFormType) => {
        localStorage.setItem(LocalStorageKey.EMAIL, data.email);
        await checkoutStore.updateConfiguration(data.email, store.configurationID);
        await store.sendConfigurationToEmail(store.configurationID, [data.email]);
        await checkoutStore.hubspotDealUpdate({
            configurationSaved: true,
            amount: store.totalPrice,
            sid: store.shareableID,
        });

        setModalOpen(false);
    };

    useEffect(() => {
        if (store?.shareableID) {
            window.history.replaceState(
                {},
                '',
                `${process.env.REACT_APP_PUBLIC_URL}/configurator/${store.shareableID}`,
            );
        }
    }, [store?.shareableID]);

    return (
        <>
            <Button
                type="text"
                preIcon={device === 'mobile' ? 'copy-bold' : 'copy'}
                onClick={getSID}
                className={styles.copyButton}
                isDisabled={store.initialLoading}
            >
                {linkText}
            </Button>
            <Modal
                isOpen={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    // clear screenshot from store because camera view is stacked otherwise
                    store.screenshot = '';
                }}
                maxWidth={510}
                className={styles.modal}
                clickOutsideToClose={false}
            >
                <div className={styles.wrapper}>
                    {isLoading && store.shareableID ? (
                        <Loader />
                    ) : (
                        <>
                            <h3>{t('saveForLater')}</h3>
                            <p>{t('saveForLaterExplanation')}</p>

                            <div className={styles.codeWrapper}>
                                <div className={styles.code}>{store.shareableID}</div>
                                <div className={styles.link}>
                                    {t('SaveForLaterYourLink')}: {process.env.REACT_APP_PUBLIC_URL}
                                    /share/<span>{store.shareableID}</span>
                                </div>
                                <Icon onClick={() => copyToClipboard()} className={styles.copy_icon} name="copy-text" />
                            </div>
                            <div className={styles.buttonWrapper}>
                                <div className={clsx(styles.successMessage, copy && styles.show)}>
                                    <Icon name="check" />
                                    {t('SaveForLaterLinkCopied')}
                                </div>
                                <div
                                    className={styles.cashback}
                                    dangerouslySetInnerHTML={{ __html: t('checkoutProgress.step4') }}
                                />
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className={styles.email_wrapper}>
                                        <h5 className={styles.email_label}>{t('yourEmail')}</h5>
                                        <Input
                                            placeholder="alex.wonderlist@example.com"
                                            {...register('email', {
                                                required: t('fieldRequired'),
                                                pattern: {
                                                    value: EMAIL_REGEX,
                                                    message: t('emailIncorrect'),
                                                },
                                            })}
                                            defaultValue={checkoutStore?.data?.email ?? userEmail}
                                            error={errors.email?.message}
                                        />
                                        <div className={styles.checkbox_container}>
                                            <Checkbox
                                                color="gray"
                                                onChange={() => setIsMarketingAccepted(!isMarketingAccepted)}
                                                checked={isMarketingAccepted}
                                            />
                                            <span className={styles.checkbox_text}>
                                                <Trans i18nKey="emailAcceptPolicy">
                                                    I accept the
                                                    <a
                                                        href={t('privacyPolicyLink')}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            fontWeight: 'bolder',
                                                            margin: '0 4px',
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                    and would like to receive news from Thömus.
                                                </Trans>
                                            </span>
                                            {/* <span className={styles.checkbox_text}>{t('emailAcceptMarketing')}</span> */}
                                        </div>
                                    </div>

                                    <Button htmlType="submit" type="primary">
                                        {t('save')}
                                    </Button>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
});
