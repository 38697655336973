import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'shared/helpers/helpers';
import { localizePrice } from 'shared/providers/utils';
import CheckoutStore from 'stores/Checkout.store';
import ConfiguratorStore from 'stores/Configurator.store';

import styles from './OrderOverviewProductsList.module.scss';

export const OrderOverviewProductsList = () => {
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const { t } = useTranslation();

    const { is_trade_in, discount_amount } = checkoutStore?.expertCheckoutData;

    const discountPct = configuratorStore.discountPercentage ?? 0;
    const discountChf = discount_amount ? discount_amount : 0;

    const pricingArray = [
        { id: 'totalPrice', label: t('totalPriceIncludeTax'), value: `${localizePrice(configuratorStore.totalPrice)}` },
        {
            id: 'discountAmount',
            label: `${t('discountForm.specialCondition')} (${discountPct}% ${t('discountForm.discount')})`,
            value: localizePrice(discountChf),
        },
        {
            id: 'totalPriceWithDiscount',
            label: `${t('overviewPage.specifications.price')} (inkl. MwSt.)`,
            value: localizePrice(configuratorStore.totalPrice - discountChf),
        },
        {
            id: 'tradeIn',
            label: `${t('discountForm.tradeIn')} ${t('discountForm.yes')} / ${t('discountForm.no')}`,
            value: is_trade_in ? t('discountForm.yes') : t('discountForm.no'),
        },
    ];

    const filteredPricingArray = pricingArray.filter(({ id }) => {
        if (!configuratorStore.discountPercentage && (id === 'discountAmount' || id === 'totalPriceWithDiscount')) {
            return false;
        }
        if (id === 'tradeIn' && !is_trade_in) {
            return false;
        }
        return true;
    });

    return (
        <div className={styles.productsList}>
            <h2>{t('overviewPage.specifications.title')}</h2>
            <div className={styles.tableWrapper}>
                <table>
                    <thead>
                        <tr>
                            <th>{t('overviewPage.specifications.component')}</th>
                            <th>{t('overviewPage.specifications.specification')}</th>
                            <th>{t('overviewPage.specifications.price')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {configuratorStore.selectedParts.map(part => (
                            <tr key={part.id}>
                                <td>{capitalizeFirstLetter(part.partName)}</td>

                                <td>{part.variantName}</td>
                                <td>{part.price > 0 ? `CHF ${localizePrice(part.price)}` : 'inkl.'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className={styles.prices}>
                    {filteredPricingArray.map(item => (
                        <div key={item.id} className={styles.discount}>
                            <div className={styles.name}>{item.label}</div>
                            <div className={styles.value}>{item.value}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
