import { gql } from '@apollo/client';

export const SAVE_CONFIGURATION_TO_EMAIL = gql`
    mutation RequestConfigurationSave(
        $id: ID!
        $emails: [String]!
        $channel: String
        $languageCode: LanguageCodeEnum!
    ) {
        requestConfigurationSave(id: $id, emails: $emails, channel: $channel, languageCode: $languageCode) {
            errors {
                field
                message
            }
        }
    }
`;

export const SHARE_CONFIGURATION_TO_EMAILS = gql`
    mutation requestConfigurationSend(
        $id: ID!
        $senderEmail: String!
        $name: String!
        $recipients: [Recipient]!
        $languageCode: LanguageCodeEnum!
    ) {
        requestConfigurationSend(
            id: $id
            senderEmail: $senderEmail
            senderFirstName: $name
            recipients: $recipients
            languageCode: $languageCode
        ) {
            errors {
                field
                message
            }
        }
    }
`;

export const CREATE_CONFIGURATION = gql`
    mutation CreateConfigurationSID(
        $template: ID
        $title: String
        $frameSize: String
        $sittingPosition: String
        $parts: GenericScalar
        $colors: GenericScalar
    ) {
        configurationCreate(
            input: {
                title: $title
                template: $template
                frameSize: $frameSize
                sittingPosition: $sittingPosition
                parts: $parts
                colors: $colors
                published: false
            }
        ) {
            configuration {
                id
                sid
                parts
            }
            errors {
                field
                message
            }
        }
    }
`;

export const UPDATE_CONFIGURATION_PARTS = gql`
    mutation updateConfigurationParts(
        $id: ID!
        $template: ID
        $title: String
        $frameSize: String
        $sittingPosition: String
        $parts: GenericScalar
        $colors: GenericScalar
    ) {
        configurationUpdate(
            id: $id
            input: {
                title: $title
                template: $template
                frameSize: $frameSize
                sittingPosition: $sittingPosition
                parts: $parts
                colors: $colors
                published: false
            }
        ) {
            configuration {
                id
                sid
            }
            errors {
                field
                message
            }
        }
    }
`;

export const CONFIGURATION_UPDATE = gql`
    mutation configurationUpdate($id: ID!, $input: ConfigurationInput!) {
        configurationUpdate(id: $id, input: $input) {
            configuration {
                id
                sid
            }
        }
    }
`;

export const SAVE_CONFIGURATION_IMAGE = gql`
    mutation SaveConfigurationImage($id: ID!, $image: Upload) {
        configurationCoverUpload(id: $id, base64String: $image) {
            configuration {
                id
                cover
            }
            errors {
                field
                message
            }
        }
    }
`;

export const UPLOAD_AR_FILE = gql`
    mutation UploadARFile($input: ArModelInput!) {
        arModelCreate(input: $input) {
            arModel {
                id
                file
            }
            errors {
                field
                message
            }
        }
    }
`;

export const createHubspotDeal = gql`
    mutation createHubspotDeal(
        $email: String!
        $sid: ID!
        $checkoutId: ID
        $amount: Int!
        $hasMarketingAccepted: Boolean!
        $configurationSaved: Boolean
        $configurationShared: Boolean
        $orderId: ID
    ) {
        hubspotDealCreate(
            email: $email
            sid: $sid
            checkoutId: $checkoutId
            amount: $amount
            hasMarketingAccepted: $hasMarketingAccepted
            orderId: $orderId
            configurationShared: $configurationShared
            configurationSaved: $configurationSaved
        ) {
            errors {
                field
                message
            }
            dealId
        }
    }
`;

export const createHubspotLead = gql`
    mutation createHubspotLead($email: String!, $marketingAccepted: Boolean!) {
        hubspotContactCreate(email: $email, hasMarketingAccepted: $marketingAccepted) {
            errors {
                field
                message
            }
        }
    }
`;

export const updateHubspotDeal = gql`
    mutation hubspotDealUpdate(
        $amount: Int!
        $checkoutId: ID
        $dealId: String!
        $email: String!
        $hasMarketingAccepted: Boolean!
        $sid: ID!
        $configurationSaved: Boolean
        $configurationShared: Boolean
        $orderId: ID
    ) {
        hubspotDealUpdate(
            dealId: $dealId
            email: $email
            sid: $sid
            checkoutId: $checkoutId
            amount: $amount
            hasMarketingAccepted: $hasMarketingAccepted
            orderId: $orderId
            configurationShared: $configurationShared
            configurationSaved: $configurationSaved
        ) {
            errors {
                field
                message
                __typename
            }
            dealId
        }
    }
`;
