export enum LocalStorageKey {
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken',
    AUTH_SUCCESS_REDIRECT_URL = 'authSuccessRedirectUrl',
    CATEGORY_ID = 'categoryId',
    SELECTED_BIKE_ID = 'selectedBikeId', // deprecated
    EXPERT_CHECKOUT_TOKEN = 'expertCheckoutToken',
    BIKE_SID = 'sid', // deprecated
    LANGUAGE = 'thoemusLanguage',
    CHECKOUT_ID = 'thoemusCheckoutID',
    CONTINUING_CONFIG = 'continuingConfig',
    REFERRAL_ITEM = 'thoemusConfigurationId',
    MARKETING_ACCEPTED = 'marketingAccepted',
    DEAL_ID = 'dealId',
    DEAL_SID = 'dealSid',

    EMAIL = 'thoemusUserEmail',
    SALESPERSON = 'thoemusSalesPerson',
    POS = 'thoemusPos',
    USER_EXPERT_CHECKOUT = 'userExpertCheckout',
}
