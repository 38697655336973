import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import fallbackBike from '../../assets/images/mock-bike.jpg';
import { AppRoutes } from '../../Routes';
import { Button, Icon } from '../../shared/components/ui';
import { Loader } from '../../shared/components/ui';
import CheckoutStore from '../../stores/Checkout.store';

import styles from './PaymentStatus.module.scss';

type PaymentStatusProps = {};

export const PaymentStatus: FC<PaymentStatusProps> = observer(() => {
    const navigate = useNavigate();
    const checkoutStore = useContext(CheckoutStore);
    const [status, setStatus] = useState('');
    // const [statusMessage, setStatusMessage] = useState('');
    const { id } = useParams();
    const { t } = useTranslation();

    const configurationImage =
        checkoutStore.orderData?.order?.metadata.find((item: { key: string }) => item.key === 'configurationImage')
            ?.value + '?h=200&trim=color&trim-tol=50' || fallbackBike;

    useEffect(() => {
        // function generateStatusMessage(redirect_status: string) {
        //     switch (redirect_status) {
        //         case 'processing':
        //             return setStatusMessage(t('paymentProcessing'));
        //         case 'requires_payment_method':
        //             return setStatusMessage(t('paymentFailed'));
        //         default:
        //             return setStatusMessage(t('failedContactSupport'));
        //     }
        // }

        async function getOrderId() {
            await checkoutStore.checkoutComplete(id);
            // await checkoutStore.hubspotDealUpdate({ orderId: id });
        }

        getOrderId();

        const redirect_status = new URLSearchParams(window.location.search).get('redirect_status');
        if (redirect_status) {
            // generateStatusMessage(redirect_status);
            setStatus(redirect_status);
        }
    }, [checkoutStore, id, t]);

    if (!checkoutStore.orderData) {
        return (
            <div className={styles.payment_status}>
                <Loader />
            </div>
        );
    }

    return (
        <div className={styles.payment_status}>
            <div className={styles.infos}>
                {status === 'succeeded' ? (
                    <>
                        <img src={configurationImage} alt="Screenshot of the bike" />
                        <h1 className={styles.success_title}>{t('thanksForTheOrder')}</h1>
                        <p>
                            {t('orderStatusGreatChoice')} <br />
                            {t('youWillGetEmailConfirmation')}{' '}
                            <span
                                className={styles.order_number}
                            >{`${checkoutStore.orderData?.order?.userEmail}.`}</span>
                        </p>
                        <div className={styles.reference}>
                            {t('yourOrderNumber')}
                            <div className={styles.orderNumber}>#{checkoutStore.orderData?.order?.number}</div>
                        </div>
                        <Button type="primary" onClick={() => navigate(AppRoutes.home)} className={styles.button}>
                            {t('goToHomepage').toUpperCase()}
                        </Button>
                    </>
                ) : (
                    <>
                        <Icon name="error" className={styles.error} />
                        <h1 className={styles.error_title}>{t('somethingWentWrong')}</h1>
                        <p>{t('orderStatusIncovenience')}</p>
                        <p dangerouslySetInnerHTML={{ __html: t('orderStatusContactUs') }} />
                        <Button
                            type="primary"
                            // go back
                            onClick={() => navigate(-1)}
                            className={styles.button}
                        >
                            {t('goBackToCheckout').toUpperCase()}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
});
