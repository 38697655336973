export const parseEditorJSData = (jsonStringData?: string) => {
    if (!jsonStringData) {
        return null;
    }
    let data;
    try {
        data = JSON.parse(jsonStringData.replace(/&nbsp;/g, ' '));
    } catch (e) {
        return null;
    }

    if (!data.blocks?.length) {
        // No data to render
        return null;
    }

    // Path for compatibility with data from older version od EditorJS
    if (!data.time) {
        data.time = Date.now().toString();
    }
    if (!data.version) {
        data.version = '2.22.2';
    }

    return data;
};

export const localizePrice = (price?: number, { decimals = 2, alwaysPositive = false } = {}) => {
    let newPrice = price ?? 0;
    newPrice = (alwaysPositive ? Math.abs(newPrice) : newPrice) || 0;

    return newPrice.toLocaleString('de-CH', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
};

export const getSignFromNumber = (n = 0) => {
    if (Math.sign(n) === 1) return '+';
    if (Math.sign(n) === -1) return '−';
    if (Math.sign(n) === 0) return '';
    return '';
};

export const sortByAttribute = (arr: any, attr: string) => {
    const sorted = arr.sort((a: any, b: any) => (a[attr] > b[attr] ? 1 : b[attr] > a[attr] ? -1 : 0)); // eslint-disable-line
    return sorted;
};

export const areArraysDifferent = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
        return false;
    } else {
        const items = arr1.filter((item: any) => !arr2.find((item2: any) => item.id === item2.id));
        return items.length === 0;
    }
};

export const setLSKeyWithExpiry = (key: string, value: string, ttl: number) => {
    const now = new Date();

    const LSKey = {
        value,
        // ttl in milliseconds
        expiry: now.getTime() + ttl,
    };

    localStorage.setItem(key, JSON.stringify(LSKey));
};

export const getLSKeyWithExpiry = (key: string) => {
    const LSKey = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!LSKey) {
        return null;
    }
    const item = JSON.parse(LSKey);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
};

export const daysToMilliseconds = (days: number) => {
    //           hour  min  sec  ms
    return days * 24 * 60 * 60 * 1000;
};

export const groupSelectedParts = (parts: any) => {
    const drive = parts.filter((part: any) => part.group === 'DRIVE');
    const wheels = parts.filter((part: any) => part.group === 'WHEELS');
    const suspension = parts.filter((part: any) => part.group === 'SUSPENSION');
    const ergonomics = parts.filter((part: any) => part.group === 'ERGONOMICS');
    const accessories = parts.filter((part: any) => part.group === 'ACCESSORIES');

    return [
        { group: 'DRIVE', parts: [...drive] },
        { group: 'WHEELS', parts: [...wheels] },
        { group: 'SUSPENSION', parts: [...suspension] },
        { group: 'ERGONOMICS', parts: [...ergonomics] },
        { group: 'ACCESSORIES', parts: [...accessories] },
    ];
};

export const getDatetime = () => {
    const date = new Date();
    return date.toISOString();
};
