import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';
// import { EMAIL_REGEX } from 'shared/helpers/validators';
import { DeviceDetectorContext } from 'shared/providers/DeviceDetector';
import { getDatetime, parseEditorJSData } from 'shared/providers/utils';
import ConfiguratorStore from 'stores/Configurator.store';

import CheckoutStore from '../../../../stores/Checkout.store';
import { gtmEvents } from '../../../types/gtmEvents';
import { hubspotEvents } from '../../../types/hubspotEvents';
import { Button } from '../Button/Button';

import styles from './ShareButton.module.scss';
import { ShareModal } from './ShareModal';

export const ShareButton: React.FC = observer(() => {
    const { device } = useContext(DeviceDetectorContext);
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);

    const { t } = useTranslation();

    const canShare = navigator.share !== undefined;

    const [isMailModalOpen, setIsMailModalOpen] = useState(false);
    // const [modalInput, setModalInput] = useState('');
    // const [modalInputError, setModalInputError] = useState<string>();
    // const [emails, setEmails] = useState(new Set<string>());

    // const emailsArray = useMemo(() => Array.from(emails), [emails]);
    // const isEmailValid = useMemo(() => EMAIL_REGEX.test(modalInput), [modalInput]);

    const share = async () => {
        if (!canShare) return;

        await getSID();

        const linkToShare = `${process.env.REACT_APP_PUBLIC_URL}${AppRoutes.configurator}/${configuratorStore.shareableID}`;
        const dataToShare: ShareData = {
            title: configuratorStore.selectedBike?.name ?? '',
            text:
                configuratorStore.selectedBike &&
                parseEditorJSData(configuratorStore.selectedBike?.description).blocks[0].data.text,
            url: linkToShare,
        };

        navigator.share(dataToShare).catch(console.warn);

        await checkoutStore.hubspotEventTrack(hubspotEvents.CONFIGURATION_SHARED, getDatetime());
    };

    // const addEmail = () => {
    //     if (!modalInput) return;

    //     if (!isEmailValid) {
    //         setModalInputError(t('SharingEmailWrongError'));
    //         return;
    //     }

    //     if (emailsArray.length >= 20) {
    //         setModalInputError(t('SharingEmailTooManyEmails'));
    //         return;
    //     }

    //     setEmails(previousState => new Set([...Array.from(previousState), modalInput]));
    //     setModalInput('');
    //     setModalInputError(undefined);
    // };

    // const deleteEmail = (email: string) => {
    //     const filteredEmails = emailsArray.filter(e => e !== email);
    //     setEmails(new Set(filteredEmails));
    // };

    // const handleKeyDown = (event: KeyboardEvent) => {
    //     if (event.key === 'Enter') {
    //         addEmail();
    //     }
    // };

    // Get sharable ID
    const getSID = async () => {
        if (!configuratorStore.shareableID) {
            await configuratorStore.saveConfiguration();
            await checkoutStore.hubspotEventTrack(
                hubspotEvents.BIKE_PRECONFIGURATION_CREATE,
                configuratorStore.shareableID,
            );
        }
    };

    const openMailModal = async () => {
        await getSID();
        setIsMailModalOpen(true);

        window.dataLayer.push({
            event: gtmEvents.BIKE_EMAIL_MODAL,
        });

        await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_EMAIL_MODAL, getDatetime());
    };

    // const sendEmails = async () => {
    //     await configuratorStore.sendConfigurationToEmail(configuratorStore.configurationID, emailsArray);

    //     window.dataLayer.push({
    //         event: gtmEvents.BIKE_EMAIL_SHARE,
    //         emailList: emailsArray.join(', '),
    //     });

    //     setIsMailModalOpen(false);
    // };

    return (
        <>
            {canShare && device !== 'desktop' ? (
                <Button
                    type="text"
                    preIcon={device === 'mobile' ? 'share-bold' : 'share'}
                    className={styles.button}
                    onClick={() => share()}
                    isDisabled={configuratorStore.initialLoading}
                >
                    <span className={styles.text}>Share</span>
                </Button>
            ) : (
                <Button
                    type="text"
                    preIcon="mail"
                    className={styles.button}
                    onClick={() => openMailModal()}
                    isDisabled={configuratorStore.initialLoading}
                >
                    <span className={styles.text}>{t('SharingSendPerEmail')}</span>
                </Button>
            )}

            {/* <Modal isOpen={isMailModalOpen} onClose={() => setIsMailModalOpen(false)} maxWidth={450}>
                <h3 className={styles.title}>{t('SharingSendPerEmail')}</h3>
                <p className={styles.subtitle}>{t('SharingSendPerEmailDescription')}</p>
                {emailsArray.length !== 0 && (
                    <div className={styles.emailsWrapper}>
                        {emailsArray.map(email => (
                            <Chip key={email} label={email} onDelete={() => deleteEmail(email)} />
                        ))}
                    </div>
                )}
                <Input
                    value={modalInput}
                    onChange={e => setModalInput(e.target.value.toLowerCase())}
                    placeholder="muster@beispiel.ch"
                    onKeyDown={handleKeyDown}
                    suffix={
                        isEmailValid && (
                            <span className={styles.inputSuffix} onClick={addEmail}>
                                {t('SharingSendPerEmailAdd')}
                            </span>
                        )
                    }
                    error={modalInputError}
                />

                <Button
                    type="ghost"
                    className={styles.modalButton}
                    isDisabled={emailsArray.length === 0}
                    onClick={sendEmails}
                >
                    {t('SharingSendPerEmailButton')}
                </Button>
            </Modal> */}

            <ShareModal isOpen={isMailModalOpen} onClose={() => setIsMailModalOpen(false)} />
        </>
    );
});
