import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'shared/components/ui';

import { Loader3DButton } from '../Loader3DButton/Loader3DButton';

type ConfiguratorLoaderProps = {
    progress: number;
    image: string;
};

const TOTAL_FACTS = 3;
const FACTS_INTERVAL = 10000;

export const ConfiguratorLoader: React.FC<ConfiguratorLoaderProps> = ({ progress, image }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const { t } = useTranslation();
    // const [fact, setFact] = useState('');

    useEffect(() => {
        // setFact(t('configuratorLoaderText'));
        let i = 0;
        const interval = setInterval(() => {
            i = i === TOTAL_FACTS ? 1 : i + 1;
            // setFact(t(`loaderFacts.fact${i}`));
        }, FACTS_INTERVAL);

        return () => clearInterval(interval);
    }, [t]);

    return (
        <>
            <div id="loadingScreen" className="bike3d__loading-screen">
                <div className="loading-screen__items d-col">
                    {!isImageLoaded && (
                        <div className="spinner">
                            <Loader />
                        </div>
                    )}
                    <div className={'image-wrapper'} style={{ display: isImageLoaded ? 'block' : 'none' }}>
                        <Loader3DButton progress={progress} />
                        {image && (
                            <img
                                src={`${image}?trim=color&trim-tol=50`}
                                alt="Bike"
                                onLoad={() => setIsImageLoaded(true)}
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* <Portal>
                <div id="config-backdrop"></div>
            </Portal> */}
        </>
    );
};
