import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import styles from './StockTrafficColor.module.scss';

const colorMap = {
    green: '#4CAF50',
    yellow: '#FFEB3B',
    red: '#F44336',
};

export type ProductVariant = {
    id: string;
    stockTrafficColor: 'green' | 'yellow' | 'red';
    name: string;
    stock: number;
    approomStock: {
        stock: number;
        subProducts: ProductVariant[];
    };
};

export type StockTrafficColorProps = {
    dataVariant: ProductVariant;
    className?: string;
    tooltip?: {
        value: boolean;
        showTooltip: () => void;
        hideTooltip: () => void;
    };
};

const StockTrafficColor: React.FC<StockTrafficColorProps> = ({ dataVariant, className, tooltip }) => {
    const { t } = useTranslation();

    const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number }>({
        top: 0,
        left: 0,
    });

    // const { subProducts } = dataVariant?.approomStock;
    const isPos = process.env.REACT_APP_POS === 'true';

    const hexColor = colorMap[dataVariant?.stockTrafficColor];

    const circleRef = useRef<HTMLDivElement>(null);
    const handleMouseEnter = () => {
        if (circleRef.current) {
            const rect = circleRef.current.getBoundingClientRect();
            const top = rect.top - 8;
            const left = rect.left + rect.width / 2;
            setTooltipPosition({ top, left });
        }
        tooltip?.showTooltip?.();
    };
    return (
        <div className={clsx(styles.wrapper, className)}>
            <div
                className={styles.circle}
                style={{ backgroundColor: hexColor }}
                ref={circleRef}
                tabIndex={0}
                onPointerEnter={handleMouseEnter}
            />
            {tooltip?.value &&
                isPos &&
                ReactDOM.createPortal(
                    <div
                        className={styles.tooltip}
                        style={{
                            top: tooltipPosition.top,
                            left: tooltipPosition.left,
                            transform: 'translate(-100%, -100%)',
                        }}
                    >
                        {dataVariant?.approomStock?.subProducts && dataVariant?.approomStock?.subProducts.length > 0 ? (
                            dataVariant?.approomStock?.subProducts.map(product => (
                                <div key={product.id} className={styles.tooltipItem}>
                                    <div className={styles.name}>{product.name}</div>
                                    <div className={styles.stock}>
                                        <div>{product?.stock}</div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className={styles.tooltipItem}>
                                <div className={styles.name}>{t('remainingStocks')}</div>
                                <div className={styles.stock}>
                                    <div>{dataVariant?.approomStock?.stock ?? 'N/A'}</div>
                                </div>
                            </div>
                        )}
                    </div>,
                    document.body,
                )}
        </div>
    );
};

export default StockTrafficColor;
