import clsx from 'clsx';
import React from 'react';

import styles from './ToggleDimensionButton.module.scss';

interface ToggleDimensionButtonProps {
    setView: (view: string) => void;
    store: any;
}
export const ToggleDimensionButton: React.FC<ToggleDimensionButtonProps> = ({ store, setView }) => {
    const handleDimensionToggle = () => {
        store.setIsTwoDimension(!store.isTwoDimension);
        setView(store.isTwoDimension ? 'twoDimension' : 'default');
    };

    return (
        <div className={clsx(styles.control, styles.reset)} onClick={handleDimensionToggle}>
            {store.isTwoDimension ? '3D' : '2D'}
        </div>
    );
};
