import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/ui/Button/Button';

import { Textarea } from '../Textarea/Textarea';

import styles from './NotesForm.module.scss';

interface NotesFormType {
    internal_note: string;
    external_note: string;
}

export const NotesForm: React.FC<{
    defaultValues: {
        internal_note: string;
        external_note: string;
    };
    onSuccess?: (val: NotesFormType) => void;
}> = observer(({ onSuccess, defaultValues: { internal_note = '', external_note = '' } }) => {
    const { t } = useTranslation();

    const {
        handleSubmit,
        register,
        formState: { isValid },
    } = useForm<NotesFormType>({
        mode: 'onChange',
        defaultValues: {
            internal_note,
            external_note,
        },
    });

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: NotesFormType) => {
        setIsLoading(true);
        try {
            onSuccess?.(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            onSuccess?.(data);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
            <div className={styles.body}>
                <h4>{t('notesForm.title')}</h4>

                {/* Discounted Final Price Field */}
                <div className={styles.formLine}>
                    <Textarea
                        label={t('notesForm.internalNotes')}
                        placeholder={t('expertCheckout.internalComments')}
                        textareaClassName={styles.input}
                        {...register('internal_note')}
                    />
                </div>
                {/* Discounted Final Price Field */}
                <div className={styles.formLine}>
                    <Textarea
                        label={t('notesForm.notes')}
                        placeholder={t('expertCheckout.officialComments')}
                        textareaClassName={styles.input}
                        {...register('external_note')}
                    />
                </div>

                <Button htmlType="submit" className={styles.button} isDisabled={!isValid} isLoading={isLoading}>
                    {t('salesPersonPopup.button')}
                </Button>
            </div>
        </form>
    );
});
