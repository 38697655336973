import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import Slider from 'react-slick';
import { DeviceDetectorContext } from 'shared/providers/DeviceDetector';

import { Portal } from '../../../../shared/components/ui';
import ConfiguratorStore from '../../../../stores/Configurator.store';
import { BikePart } from '../BikePart/BikePart';
import { CarouselArrow } from '../CarouselArrow/CarouselArrow';
import { MobileTabletVariantsMenu } from '../MobileTabletVariantsMenu/MobileTabletVariantsMenu';

import styles from './BikeCarousel.module.scss';

type BikeCarouselProps = {
    data: any;
    bikeCategories: any;
};

const settings = {
    slidesToScroll: 2,
    speed: 200,
    arrows: false,
    centerPadding: '8px',
    variableWidth: true,
    infinite: true,
    swipeToSlide: false,
    draggable: false,
    responsive: [
        {
            breakpoint: 415,
            settings: {
                swipeToSlide: true,
                draggable: true,
            },
        },
    ],
};

export const BikeCarousel: FC<BikeCarouselProps> = observer(({ data, bikeCategories }) => {
    const slider = useRef<Slider>(null);
    const [showArrows, setShowArrows] = useState(false);
    const [currentCarouselItemIndex, setCurrentCarouselItemIndex] = useState(0);
    const [mobileTabletMenu, setMobileTabletMenu] = useState(false);
    const [clickedPartIndex, setClickedPartIndex] = useState(0);
    const { device } = useContext(DeviceDetectorContext);
    const { t } = useTranslation();
    const selectedPart = data?.[clickedPartIndex];
    const configuratorStore = useContext(ConfiguratorStore);

    const currentGroup = useMemo(() => {
        if (!data) return '';
        return data[currentCarouselItemIndex]?.group;
    }, [currentCarouselItemIndex, data]);

    const currentGroupProgress = useMemo(() => {
        const groupLength = data.filter((d: any) => d.group === currentGroup)?.length;
        if (!groupLength) return 0;
        const groupIndexStart = data.findIndex((d: any) => d.group === currentGroup);
        const percentage = Math.floor(((currentCarouselItemIndex + 1 - groupIndexStart) / groupLength) * 100);

        return percentage;
    }, [currentCarouselItemIndex, currentGroup, data]);

    useEffect(() => {
        if (device !== 'desktop') {
            // get current active group and make it visible
            const activeGroup = document.getElementById(currentGroup);
            activeGroup?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }, [currentGroup, device]);

    const goToSliderCategory = (category: string) => {
        // get index of the first category that satisfies the criteria and scroll to it
        const indexOfCategory = data.map((item: any) => item.group).indexOf(category);
        slider.current?.slickGoTo(indexOfCategory, false);
    };

    const checkSwipe = (direction: string) => {
        if (direction === 'left') {
            if (currentCarouselItemIndex === data.length - 1) return;
            slider.current?.slickNext();
        } else {
            if (currentCarouselItemIndex === 0) return;
            slider.current?.slickPrev();
        }
    };

    const showMobileTabletMenu = (index: number) => {
        setMobileTabletMenu(true);
        setClickedPartIndex(index);
    };

    return (
        <div
            className={clsx(
                styles.bike_carousel,
                // configuratorStore.initialLoading && styles.loading
            )}
        >
            <div className={clsx(styles.carousel_categories)}>
                {bikeCategories.length === 0 && <Skeleton width={600} height={30} highlightColor="#f3f1f5" />}

                {bikeCategories.map((category: any, i: number) => {
                    return (
                        <div
                            id={category}
                            key={`${category}-${i}`}
                            onClick={() => goToSliderCategory(category)}
                            className={clsx(styles.category_name, currentGroup === category && styles.category_active)}
                        >
                            <h3>{t(category)}</h3>
                            {currentGroup === category && (
                                <div className={styles.category_progress}>
                                    <span
                                        className={styles.progress}
                                        style={{
                                            width: currentGroupProgress + '%',
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            {data.length > 0 ? (
                <div
                    className={styles.slider}
                    onMouseEnter={() => setShowArrows(true)}
                    onMouseLeave={() => setShowArrows(false)}
                    // required width because of sidebar width is dynamic - 45px is padding
                    style={{
                        width:
                            device === 'desktop'
                                ? `calc(100vw - ${
                                      !configuratorStore.sidebarExpanded ? 410 : configuratorStore.sidebarWidth + 45
                                  }px)`
                                : '100%',
                    }}
                >
                    {device === 'desktop' && (
                        <>
                            <div className={clsx(styles.left_arrow, showArrows && styles.visible)}>
                                <CarouselArrow
                                    onClick={() => {
                                        slider.current?.slickPrev();
                                    }}
                                    isDisabled={false}
                                    arrowDirection="left"
                                />
                            </div>
                            <div className={clsx(styles.right_arrow, showArrows && styles.visible)}>
                                <CarouselArrow
                                    onClick={() => {
                                        slider.current?.slickNext();
                                    }}
                                    isDisabled={false}
                                    arrowDirection="right"
                                />
                            </div>
                        </>
                    )}

                    <Slider
                        ref={slider}
                        beforeChange={(_, newIndex) => setCurrentCarouselItemIndex(newIndex)}
                        onSwipe={direction => checkSwipe(direction)}
                        {...settings}
                        infinite={data.length > 8}
                    >
                        {data.map((part: any, index: number) => {
                            const nextIndex = index + 1 >= data.length ? data.length - 1 : index + 1;
                            // show divider after each group (last group included as well)
                            const showDivider =
                                data[index].group !== data[nextIndex].group || part.type === data.at(-1)?.type;

                            return (
                                <BikePart
                                    key={`${part.type}-${index}`}
                                    data={part}
                                    showDivider={showDivider}
                                    onClick={device !== 'desktop' ? () => showMobileTabletMenu(index) : undefined}
                                />
                            );
                        })}
                    </Slider>

                    <Portal>
                        <MobileTabletVariantsMenu
                            isOpen={mobileTabletMenu}
                            onClose={() => setMobileTabletMenu(false)}
                            selectedVariant={selectedPart}
                        />
                    </Portal>
                </div>
            ) : (
                <Skeleton
                    count={device === 'desktop' ? 10 : 4}
                    width={152}
                    height={116}
                    containerClassName={styles.skeleton}
                />
            )}
        </div>
    );
});
