import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { useHubspotDealCreateUpdate } from '../../../../shared/hooks/useHubspotHook';
import { gtmEvents } from '../../../../shared/types/gtmEvents';
import { hubspotEvents } from '../../../../shared/types/hubspotEvents';
import CheckoutStore from '../../../../stores/Checkout.store';
import ConfiguratorStore from '../../../../stores/Configurator.store';
import { Color } from '../Color/Color';
import { ColorPickerPopup } from '../ColorPickerPopup/ColorPickerPopup';

import styles from './ColorSection.module.scss';

// const hexColorRegex = /^#([0-9a-f]{3}){1,2}$/i;

export const ColorSection = observer(() => {
    const store = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const { t } = useTranslation();
    const [localActiveColorId, setLocalActiveColorId] = useState('');
    const [hasCustomColor, setHasCustomColor] = useState(store.hasSelectedColors);
    const [showPicker, setShowPicker] = useState(false);
    const [createOrUpdateDeal] = useHubspotDealCreateUpdate();
    const filteredVariants = store.selectedBike?.variants.filter((variant: any) =>
        variant.metadata?.some(
            (meta: any) => meta?.key === 'size' && meta?.value === (store?.selectedSize?.name || store?.selectedSize),
        ),
    );
    const customVariant = filteredVariants?.find((variant: any) => variant?.name?.toLowerCase().includes('custom'));

    useEffect(() => {
        if (store.hasSelectedColors) {
            setHasCustomColor(true);
        }
    }, [store.hasSelectedColors]);

    useEffect(() => {
        if (store.loadingEnded && store.hasSelectedColors) {
            setColor(
                {
                    id: customVariant.id,
                    value: customVariant?.attributes?.[0]?.values[0]?.value,
                    colorName: customVariant.attributes[0]?.values[0]?.name,
                    weight: customVariant.attributes[0]?.values[0]?.weight,
                },
                true,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.loadingEnded]);

    useEffect(() => {
        if (!store.selectedSize || !filteredVariants || filteredVariants.length === 0) return;

        if (store.loadingEnded) {
            const localSelectedVariant = filteredVariants.find((variant: any) => variant.id === localActiveColorId);
            const preselectedVariant = filteredVariants.find(
                (variant: any) => variant.attributes[0]?.values[0]?.name === store.selectedBikeVariant?.colorName,
            );

            const selectedVariant = localSelectedVariant || preselectedVariant;

            if (selectedVariant) {
                setColor(
                    {
                        id: selectedVariant.id,
                        value: selectedVariant.attributes[0]?.values[0]?.value,
                        colorName: selectedVariant.attributes[0]?.values[0]?.name,
                        weight: selectedVariant.weight?.value,
                    },
                    store.hasSelectedColors,
                );
            }
        }
    }, [store.selectedSize, store.loadingEnded, localActiveColorId]); //eslint-disable-line

    const setColor = async (color: any, hasCustomColor: boolean = false) => {
        store.selectedBikeVariant = {
            id: color.id,
            color: color.value,
            colorName: color.colorName,
            weight: color.weight,
        };
        setLocalActiveColorId(color.id);
        if (!hasCustomColor) {
            setHasCustomColor(false);
            store.selectedColors = [];
        }
        window.dataLayer.push({
            event: gtmEvents.BIKE_COLOR,
            category: store.bike,
            bikeModel: store.selectedBike.name,
            color: color.colorName,
            hexColor: color.value,
        });

        await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_COLOR_SELECTED, color.colorName);
        await createOrUpdateDeal();
    };

    if (!store.selectedBike?.variants) {
        return (
            <Skeleton
                count={3}
                circle={true}
                width={40}
                height={40}
                containerClassName={styles.skeleton_wrap}
                baseColor="#000000"
                highlightColor="#3A383D"
            />
        );
    }

    return (
        <div className={styles.color_picker}>
            <p className={clsx(styles.title, store.sidebarWidth > 370 && store.sidebarExpanded && styles.smaller)}>
                {t('sidebarFrameColorTitle')}
            </p>
            <div className={styles.color_list}>
                {filteredVariants
                    ?.filter((variant: any) => variant.id !== customVariant?.id)
                    .map((variant: any) => {
                        return (
                            <Color
                                key={variant.id}
                                color={{
                                    id: variant?.id,
                                    value: variant.attributes[0]?.values[0]?.value || '#CCCCCC',
                                    weight: variant?.weight?.value,
                                    colorName: variant.attributes[0]?.values[0]?.name,
                                    hasTexture:
                                        variant?.attributes?.find((a: any) => a?.attribute?.slug === 'primtexture')
                                            ?.values?.length > 0,
                                }}
                                colorSelected={!hasCustomColor && store.selectedBikeVariant?.id === variant?.id}
                                setSelectedColor={(color: any) => {
                                    color?.hasTexture > 0
                                        ? setColor({
                                              id: color.id,
                                              colorName: color.colorName,
                                              value: null,
                                              weight: color.weight,
                                          })
                                        : setColor(color);
                                }}
                                smallerColor={store.sidebarWidth > 370 && store.sidebarExpanded}
                            />
                        );
                    })}
                {store.selectedBike?.isCustomColorActive && (
                    <ColorPickerPopup
                        active={showPicker}
                        clicked={() => {
                            setShowPicker(true);
                            setHasCustomColor(true);
                        }}
                        onColorClick={() => {
                            setColor(
                                {
                                    id: customVariant?.id,
                                    value: customVariant?.attributes[0]?.values[0]?.value,
                                    colorName: customVariant?.attributes[0]?.values[0]?.name,
                                    weight: customVariant?.attributes[0]?.values[0]?.weight,
                                },
                                true,
                            );
                        }}
                        hasCustomColor={hasCustomColor}
                        onClose={() => {
                            setShowPicker(false);
                            if (store.selectedColors.length === 0) {
                                setHasCustomColor(false);
                            }
                        }}
                        smaller={store.sidebarWidth > 370 && store.sidebarExpanded}
                    />
                )}
            </div>
        </div>
    );
});
