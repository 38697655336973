import React, { useEffect, useState } from 'react';
import { LocalStorageKey } from 'shared/helpers/localStorage';

import { FrameSizeButton } from '../FrameSizeButton/FrameSizeButton';

type SmartFitProps = {
    product: any;
    onConfirmSize: any;
    className?: string;
    smaller?: boolean;
};
export const SmartFit: React.FC<SmartFitProps> = ({ product, onConfirmSize, className, smaller }) => {
    const [showButton, setShowButton] = useState(true);

    const hideButton = () => {
        setShowButton(false);
    };

    const OZ_CONFIG = {
        settings: {
            apiKey: 'oz_RLJxixf4qpO/qe23R9gSlOy5iwUFKQOglJqNcgO4XZQ27kXAe1EeC',
            language: localStorage.getItem(LocalStorageKey.LANGUAGE)?.toLowerCase(),
            primary: '#DB1913',
            cta: '#DB1913',
            text: '',
            background: '',
            buttonTextColor: '#FFFFFF',
            roundness: '',
            unitsToggle: false,
            frameSizeDisplay: 'manufacturer',
            defaultGender: 'male',
        },
        events: {
            confirmSize: {
                callback: onConfirmSize,
            },
            sizingUnavailable: {
                callback: hideButton,
            },
        },
    };

    useEffect(() => {
        const initOnlineSizing = (win: any, doc: any, script: string, key: string, config: any, src: string) => {
            if (win['___OnlineSizing']) return;
            win['___OnlineSizing'] = key;
            win['___OnlineSizingConfig'] = config;
            const js = doc.createElement(script);
            js.id = key;
            js.src = src;
            js.async = true;

            const scripts = doc.getElementsByTagName(script);
            const lastScript = scripts[scripts.length - 1];
            lastScript.parentNode.insertBefore(js, lastScript);
        };
        initOnlineSizing(window, document, 'script', 'oz', OZ_CONFIG, 'https://widgets.onlinesizing.bike/loader.js');
        // When changing bikes in configuration update the OZ button
        // so it uses proper data to load/work
        if (window.oz?.namespace) {
            const customButton = document.getElementById('my-custom-oz-button')!;
            customButton.setAttribute(`data-${window.oz.namespace}-code`, `${product.id}-M`);
            customButton.setAttribute(`data-${window.oz.namespace}-name`, product.name);
            customButton.setAttribute(`data-${window.oz.namespace}-image`, product.media[0]?.url);

            window.oz.updateWidgetConfigurations([customButton]);
            window.oz.destroy();
            window.oz.initialize();
        }
        //eslint-disable-next-line
    }, [window]);

    if (!showButton) return <div></div>;

    return (
        <button
            id="my-custom-oz-button"
            data-oz-widget="sizing"
            data-oz-embed="custom"
            data-oz-code={`${product.id}-M`}
            data-oz-name={product.name}
            data-oz-image={product.media[0]?.url}
            data-oz-fullscreen="true"
            className={className}
        >
            <FrameSizeButton smaller={smaller} />
        </button>
    );
};
