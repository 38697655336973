import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { useNavigationChangeInterceptor } from 'shared/hooks/useNavigationChangeInterceptor';
import CheckoutStore from 'stores/Checkout.store';

import { Portal, Sidebar } from '../../shared/components/ui';
import { areArraysDifferent, getDatetime } from '../../shared/providers/utils';
import { gtmEvents } from '../../shared/types/gtmEvents';
import { hubspotEvents } from '../../shared/types/hubspotEvents';
import ConfiguratorStore from '../../stores/Configurator.store';

import { Bike3D } from './_components/Bike3D/Bike3D';
import { BikeCarousel } from './_components/BikeCarousel/BikeCarousel';
import { ExitIntentModal } from './_components/ExitIntentModal/ExitIntentModal';
import { OrderOverview } from './_components/OrderOverview/OrderOverview';
import { PreconfigurationModal } from './_components/PreconfigurationModal/PreconfigurationModal';
import { UserExpertCheckoutForm } from './_components/UserExpertCheckoutForm/UserExpertCheckoutForm';
import styles from './Configurator.module.scss';

type ConfiguratorProps = {};

export const Configurator: FC<ConfiguratorProps> = observer(() => {
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const { listenForNavigationChange } = configuratorStore;
    const { sid } = useParams();
    const [searchParams] = useSearchParams();
    const bikeId = searchParams.get('bike');
    const configContinue = window.location.search.includes('continue');
    // const emailFromlocal = localStorage.getItem(LocalStorageKey.EMAIL);
    const isPos = process.env.REACT_APP_POS === 'true';
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [navigationChanged, confirmNavigation, cancelNavigation] =
        useNavigationChangeInterceptor(listenForNavigationChange);

    const [preconfigurationPopup, setPreconfigurationPopup] = useState(false);
    const [exitIntentPopup, setExitIntentPopup] = useState(false);
    // const [emailPopup, setEmailPopup] = useState(false);

    useEffect(() => {
        configuratorStore.loadingEnded = false;
        if (!listenForNavigationChange) {
            configuratorStore.listenForNavigationChange = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Show warning prompt on tab close
        const warningPromptOnUnload = (e: Event) => {
            e.preventDefault();
            // sendHubspotDeal();
            window.dataLayer.push({
                event: gtmEvents.BIKE_LEAVE_CONFIGURATOR_POPUP,
            });
            return '';
        };

        window.addEventListener('beforeunload', warningPromptOnUnload);

        return () => {
            window.removeEventListener('beforeunload', warningPromptOnUnload);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useIdleTimer({
        timeout: 1000 * 60 * 5, // 5 minutes of inactivity
        onIdle: () => {
            if (!configuratorStore.exitIntent) return;
            if (
                configuratorStore.exitIntent &&
                configuratorStore.loadingEnded &&
                !areArraysDifferent(configuratorStore.initialParts, configuratorStore.selectedParts)
            ) {
                setExitIntentPopup(true);
                configuratorStore.exitIntent = false;

                window.dataLayer.push({
                    event: gtmEvents.EXIT_INTENT_POPUP,
                });
                checkoutStore.hubspotEventTrack(hubspotEvents.EXIT_INTENT_POPUP, getDatetime());
            }
        },
        startOnMount: true,
    });
    // REPLACE LOGIC OF EXIT DOCUMENT WITH TIMER OF INACTIVITY

    // useEffect(() => {
    //     const mouseLeaveEvent = (event: any) => {
    //         if (!configuratorStore.exitIntent) return;
    //         if (
    //             event.clientY <= 0 ||
    //             event.clientX <= 0 ||
    //             event.clientX >= window.innerWidth ||
    //             event.clientY >= window.innerHeight
    //         ) {
    //             setExitIntentPopup(true);
    //             configuratorStore.exitIntent = false;

    //             window.dataLayer.push({
    //                 event: gtmEvents.EXIT_INTENT_POPUP,
    //             });
    //             checkoutStore.hubspotEventTrack(hubspotEvents.EXIT_INTENT_POPUP, getDatetime());

    //             document.removeEventListener('mouseleave', () => {});
    //         }
    //     };

    //     if (
    //         configuratorStore.exitIntent &&
    //         configuratorStore.loadingEnded &&
    //         !areArraysDifferent(configuratorStore.initialParts, configuratorStore.selectedParts)
    //     ) {
    //         document.addEventListener('mouseleave', e => mouseLeaveEvent(e));
    //     }

    //     return () => {
    //         document.removeEventListener('mouseleave', mouseLeaveEvent);
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [configuratorStore.loadingEnded, JSON.stringify(configuratorStore.selectedParts)]);

    useEffect(() => {
        if (navigationChanged && configuratorStore.listenForNavigationChange) {
            window.dataLayer.push({
                event: gtmEvents.BIKE_LEAVE_CONFIGURATOR_POPUP,
            });
            if (window.confirm(t('changesLost'))) {
                // sendHubspotDeal();
                confirmNavigation();
                window.dataLayer.push({
                    event: gtmEvents.BIKE_LEAVE_CONFIGURATOR_CONFIRM,
                });
            } else {
                cancelNavigation();
                window.dataLayer.push({
                    event: gtmEvents.BIKE_LEAVE_CONFIGURATOR_CANCEL,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigationChanged, configuratorStore.listenForNavigationChange]);

    useEffect(() => {
        configuratorStore.selectedProductTypeId = null;
        const shareableId = sid;

        if (shareableId) {
            // localStorage.setItem(LocalStorageKey.DEAL_SID, shareableId);
            configuratorStore.getProductBySid(shareableId.toUpperCase(), configContinue).then(configuration => {
                if (!configuration) {
                    configuratorStore.listenForNavigationChange = false;
                    setTimeout(() => navigate(AppRoutes.notFound), 500);
                    return;
                }
                // create local selected parts list and remove all upsell items by default
                // later add them if they exist in checkout
                // let selectedPartsList = configuration?.parts.filter((parts: any) => parts.group !== 'OTHER');
                // set upsell items if they exist in checkout
                // const upsell = checkoutStore.data?.linesByVariantGroup?.find((item: any) => item.group === 'OTHER');
                // if (upsell?.lines?.length !== 0) {
                //     upsell?.lines.forEach(item => {
                //         const exists = selectedPartsList.find((part: any) => part.variantId === item.variant.id);
                //         if (!exists) {
                //             selectedPartsList.push({
                //                 variantId: item.variant.id,
                //                 price: item.totalPrice.gross.amount,
                //                 group: 'OTHER',
                //             });
                //         }
                //     });
                // }
                // set upsell items in selected parts array
                // return (configuratorStore.selectedParts = selectedPartsList);
            });
            checkoutStore.clearTokens();
        } else if (bikeId) {
            configuratorStore.fetchProduct(bikeId as string);
            configuratorStore.getBikeConfigurationParts(bikeId as string);
        } else {
            navigate(AppRoutes.home, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        configuratorStore.getBikePreconfigurations(true, bikeId as string);

        if (configuratorStore.preconfigurationChanged) {
            setPreconfigurationPopup(true);
        }
    }, [configuratorStore, configuratorStore.preconfigurationChanged, bikeId]);

    useEffect(() => {
        if (!configuratorStore.isFirstLoad) {
            configuratorStore.getLoadingData();
        }
    }, [configuratorStore, configuratorStore.triggerSelect]);

    // Check if the bike is a twinner bike
    useEffect(() => {
        if (configuratorStore.selectedBike) {
            configuratorStore.isTwinner = configuratorStore.selectedBike.name.toLowerCase().includes('twinner');
        }
        return () => {
            configuratorStore.isTwinner = false;
        };
    }, [configuratorStore.selectedBike, configuratorStore]);

    useEffect(() => {
        if (!configuratorStore.shareableID && localStorage.getItem(LocalStorageKey.DEAL_SID) !== null) {
            if (sid && !configContinue) {
                return localStorage.removeItem(LocalStorageKey.DEAL_SID);
            }
            configuratorStore.getProductBySid(localStorage.getItem(LocalStorageKey.DEAL_SID)!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const sendHubspotDeal = async () => {
    //     if (localStorage.getItem(LocalStorageKey.EMAIL) !== null) {
    //         const emailLS = localStorage.getItem(LocalStorageKey.EMAIL);
    //         const marketingAccepted = localStorage.getItem(LocalStorageKey.MARKETING_ACCEPTED);
    //         const sidFallback = configuratorStore.preconfigurationsList?.find(
    //             (item: any) => item.id === configuratorStore.preconfigurationId,
    //         )?.sid;
    //
    //         const payload = {
    //             email: emailLS,
    //             sid: configuratorStore.shareableID || sidFallback,
    //             amount: configuratorStore.totalPrice,
    //             hasMarketingAccepted: marketingAccepted === 'true',
    //             checkoutId: checkoutStore.data?.id || '',
    //         };
    //
    //         const data = {
    //             mutation: createHubspotDeal,
    //             variables: payload,
    //         };
    //
    //         const url = `${process.env.REACT_APP_BASE_API_URL}`;
    //         const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    //
    //         navigator.sendBeacon(url, blob);
    //     }
    // };

    // if (configuratorStore.configuratorPageLoading) {
    //     return (
    //         <div className={styles.loader}>
    //             <Loader />
    //             <h6>{t('pleaseWait')}</h6>
    //         </div>
    //     );
    // }

    // if (configuratorStore?.configuratorParts?.length === 0) {
    //     return (
    //         <div className={styles.something_went_wrong}>
    //             <h3>{t('configuratorError')}</h3>
    //         </div>
    //     );
    // }

    // TODO: remove in the future
    // useEffect(() => {
    //     if (!checkoutStore.data?.email && !emailFromlocal && !configContinue) {
    //         setEmailPopup(true);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <>
            {configuratorStore.isCheckout && <OrderOverview />}
            <div className={styles.configurator}>
                {!isPos && (
                    <div className={clsx(styles.blurLayer, { [styles.active]: configuratorStore.isCheckout })} />
                )}

                {/* <div id="config-backdrop"></div> */}
                <div className={styles.main_container}>
                    <UserExpertCheckoutForm
                        onSuccess={(data: any) => {
                            localStorage.setItem(LocalStorageKey.EMAIL, data?.email || '');
                            localStorage.setItem(LocalStorageKey.USER_EXPERT_CHECKOUT, JSON.stringify(data));
                        }}
                    />

                    <div className={styles.wrap3d_container}>
                        <div className={styles.weight_container}>
                            <h3 className={styles.bike_name}>{configuratorStore.selectedBike?.name}</h3>
                            {/* {!configuratorStore.isTwinner && (
                            <div className={styles.weight_wrapper}>
                                <h6 className={styles.weight_text}>{t('estWeight')}</h6>
                                <h6>{configuratorStore.totalWeight} kg</h6>
                            </div>
                        )} */}
                        </div>
                        <div className={clsx(styles.configuratorView, styles.image)}>
                            <Bike3D modelData={configuratorStore.loadingData} />
                        </div>
                    </div>
                    <BikeCarousel
                        data={configuratorStore.configuratorParts}
                        bikeCategories={configuratorStore.configuratorCategories}
                    />
                </div>
                <Sidebar type="configurator" />
                <PreconfigurationModal onClose={() => setPreconfigurationPopup(false)} isOpen={preconfigurationPopup} />
                <Portal>
                    <ExitIntentModal isOpen={exitIntentPopup} onClose={() => setExitIntentPopup(false)} />
                </Portal>
                {/* {emailPopup && <EmailPopup isOpen={emailPopup} onClose={() => setEmailPopup(false)} />} */}
            </div>
        </>
    );
});
