import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceDetectorContext } from '../../../providers/DeviceDetector';
import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

import { SearchSidForm } from './_components/SearchSidForm/SearchSidForm';
import styles from './SearchSidModal.module.scss';

export const SearchSidModal = observer(() => {
    const { device } = useContext(DeviceDetectorContext);
    const isMobile = device === 'mobile' || device === 'small-tablet';

    const [showContactModal, setShowContactModal] = useState(false);

    const onClose = () => {
        setShowContactModal(false);
    };

    const { t } = useTranslation();

    return (
        <>
            <Button
                type="text"
                preIcon={isMobile ? 'code_bold' : 'code'}
                className={styles.contactButton}
                onClick={() => setShowContactModal(true)}
            >
                {!isMobile && t('searchCode.headerBtn')}
            </Button>

            <Modal isOpen={showContactModal} onClose={onClose} maxWidth="450px" className={styles.modal}>
                <SearchSidForm onSuccess={onClose} />
            </Modal>
        </>
    );
});
