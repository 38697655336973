import fallbackImg from 'assets/images/default-part-image-popup.png';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Portal } from 'shared/components/ui';
import { Rating } from 'shared/components/ui/Rating/Rating';
import StockTrafficColor from 'shared/components/ui/StockTrafficColor/StockTrafficColor';
import { getSignFromNumber, localizePrice } from 'shared/providers/utils';
import { BikeVariantType } from 'shared/types/configurator';
import { hubspotEvents } from 'shared/types/hubspotEvents';
import ConfiguratorStore from 'stores/Configurator.store';

import { LocalStorageKey } from '../../../../shared/helpers/localStorage';
import CheckoutStore from '../../../../stores/Checkout.store';

import { ConflictModal } from './ConflictsModal';
import { LongDescriptionModal } from './LongDescriptionModal';
import { PartDescription } from './PartDescription';
import styles from './PartVariant.module.scss';

type PartVariantProps = {
    data: BikeVariantType;
    closePopup: () => void;
    typeId: string;
    selectedVariant?: any;
    partGroup: string;
    isPartMandatory?: boolean;
    zoomedImage?: (url: any) => void;
};

export const PartVariant: FC<PartVariantProps> = observer(
    ({ data, closePopup, typeId, selectedVariant, partGroup, isPartMandatory }) => {
        const store = useContext(ConfiguratorStore);
        const checkoutStore = useContext(CheckoutStore);
        const [isActive, setIsActive] = useState(false);
        const [dataVariant] = useState<any>(data?.referenceProduct?.variants?.[0]);
        const [localPrice] = useState<any>(data?.referenceProduct?.variants?.[0]?.pricing?.price?.gross?.amount);
        const [longDescModal, setLongDescModal] = useState(false);
        const [conflictModal, setConflictModal] = useState(false);
        const imageExists = data.referenceProduct?.media?.[0]?.url || false;
        const [showSubProductTooltip, setShowSubProductTooltip] = useState(false);

        if (data?.referenceProduct?.translation) {
            const prod = data.referenceProduct;
            const { name, description, shortDescription } = prod.translation;
            prod.name = name;
            prod.description = description;
            prod.shortDescription = shortDescription;
        }

        const { t } = useTranslation();

        const removedPartName = selectedVariant?.variantName ?? '';

        const setVariant = (id: any) => {
            if (!store?.loadingEnded) return;
            const { referenceProduct } = data;
            const notLoaded = !store.loadingModelIds.find(i => i.id === referenceProduct?.id)?.isLoaded;
            const no3dRequired = !referenceProduct.no3dRequired;

            if (notLoaded && no3dRequired) return;

            const weight = dataVariant?.weight?.value;
            const name = referenceProduct?.name || data.name;
            const shortName = referenceProduct?.shortTitle || null;

            const isAlreadyInScene = store.selectedParts.find(item => item.id === referenceProduct.id);
            if (isAlreadyInScene) {
                if (isPartMandatory) return;
                store.removeSelectedPart(id);
                // send empty string to google analytic if part is not mandatory and removed
                // sendGAEvent('', removedPartName);
                checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_PART_CHANGE, `Removed ${removedPartName}`);
                sendHubspotDealUpdate();
                closePopup();
            } else {
                handleConflictingTypes(referenceProduct);
                handleConflictingProducts(referenceProduct);
                handleRequiredTypes(referenceProduct);
                handleRequiredProducts(referenceProduct);

                // Check if no product/product type is added as conflicting/required
                // If any are added show the popup, otherwise add the selected product to scene
                // and close the popup
                const allEmpty = Object.keys(store.modalParts).every(key => store.modalParts[key].length === 0);

                if (allEmpty) {
                    store.setSelectedPart(id, typeId, localPrice, weight, name, partGroup, dataVariant?.id, shortName);
                    // sendGAEvent(name, removedPartName);
                    checkoutStore.hubspotEventTrack(
                        hubspotEvents.BIKE_PART_CHANGE,
                        `Added ${name} ${removedPartName && `- Removed ${removedPartName}`}`,
                    );
                    sendHubspotDealUpdate();
                    closePopup();
                } else {
                    store.menuPopupIgnoreClickOutside = true;
                    setConflictModal(true);
                }
            }
        };

        // Check if selected part product type is in conflict with any already selected parts
        const handleConflictingTypes = (referenceProduct: any) => {
            const conflictingTypes = referenceProduct?.productType?.conflictingProductTypes;

            if (conflictingTypes && conflictingTypes.length > 0) {
                for (const prodType of conflictingTypes) {
                    const products = store.selectedParts.filter(part => part.productTypeId === prodType.id);
                    for (const prod of products) {
                        store.modalParts.conflictTypes.push(prod);
                    }
                }
            }
        };

        // Check if the selected part has any products that are conflicting
        const handleConflictingProducts = (referenceProduct: any) => {
            if (referenceProduct.conflictingProducts.length > 0) {
                for (const confProd of referenceProduct.conflictingProducts) {
                    const isObjInScene = store.selectedParts.find(p => p.id === confProd.id);
                    if (isObjInScene) store.modalParts.conflict.push(confProd);
                }
            }
        };

        const handleRequiredTypes = (referenceProduct: any) => {
            // Check if product has required types and add first one
            const requiredTypes = referenceProduct?.productType?.requiredTypes;
            if (requiredTypes && requiredTypes.length > 0) {
                for (const reqType of requiredTypes) {
                    // Check if required part is alreay added and if so do not add it again
                    // Also check if part exists as available to be added at all, if not skip it
                    const alreadyAdded = store.selectedParts?.find(p => p.productTypeId === reqType.id);
                    const existsAsOption = store.configuratorParts?.find(p => p.typeId === reqType.id);
                    if (alreadyAdded || !existsAsOption) continue;

                    store.modalParts.requiredTypes.push(reqType);
                }
            }
        };

        const handleRequiredProducts = (referenceProduct: any) => {
            const requiredProducts = referenceProduct.requiredProducts;
            if (requiredProducts && requiredProducts.length > 0) {
                for (const reqProd of requiredProducts) {
                    const alreadyAdded = store.selectedParts?.find(p => p.id === reqProd.id);
                    if (alreadyAdded) continue;

                    store.modalParts.required.push(reqProd);
                }
            }
        };

        const selectProduct = (product: any, group: any) => {
            if (group && product) {
                store.setSelectedPart(
                    product?.referenceProduct?.id,
                    product?.referenceProduct?.productType?.id,
                    product?.referenceProduct?.variants[0]?.pricing?.price?.gross?.amount,
                    product?.referenceProduct.weight?.value,
                    product?.referenceProduct?.name,
                    group,
                    product?.referenceProduct?.variants[0].id,
                    product?.referenceProduct.shortTitle,
                );
            }
        };

        // When confirming the modal, perform all the actions needed regarding the
        // conflicting and/or required products/types
        const confirmModal = () => {
            const weight = dataVariant?.weight?.value;
            const name = data.referenceProduct?.name || data.name;
            const shortName = data.referenceProduct?.shortTitle || null;

            if (store.modalParts.conflictTypes.length > 0) {
                for (const product of store.modalParts.conflictTypes) {
                    store.removeSelectedPart(product.id);

                    // send empty string to google analytic if part is not mandatory and removed
                    // sendGAEvent(name, removedPartName);
                    checkoutStore.hubspotEventTrack(
                        hubspotEvents.BIKE_PART_CHANGE,
                        `Added ${name} ${removedPartName && `- Removed ${removedPartName}`}`,
                    );
                    sendHubspotDealUpdate();
                }
            }
            if (store.modalParts.conflict.length > 0) {
                for (const prod of store.modalParts.conflict) {
                    store.removeSelectedPart(prod.id);
                    // send empty string to google analytic if part is not mandatory and removed
                    // sendGAEvent('', removedPartName);
                    checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_PART_CHANGE, `Removed ${removedPartName}`);
                    sendHubspotDealUpdate();
                }
            }

            if (store.modalParts.requiredTypes.length > 0) {
                for (const type of store.modalParts.requiredTypes) {
                    const products = store.configuratorParts?.find(p => p.typeId === type.id);
                    if (!products) return;
                    const product = products?.values[0];
                    selectProduct(product, products.group);
                    // sendGAEvent(name, removedPartName);
                    checkoutStore.hubspotEventTrack(
                        hubspotEvents.BIKE_PART_CHANGE,
                        `Added ${name} ${removedPartName && `- Removed ${removedPartName}`}`,
                    );
                    sendHubspotDealUpdate();
                }
            }

            if (store.modalParts.required.length > 0) {
                for (const prod of store.modalParts.required) {
                    const group = store.configuratorParts?.find(a =>
                        a.values.find(b => b.referenceProduct.id === prod.id),
                    );
                    const product = group?.values.find(p => p.referenceProduct.id === prod.id);
                    selectProduct(product, group?.group);
                    // sendGAEvent(name, removedPartName);
                    checkoutStore.hubspotEventTrack(
                        hubspotEvents.BIKE_PART_CHANGE,
                        `Added ${name} ${removedPartName && `- Removed ${removedPartName}`}`,
                    );
                    sendHubspotDealUpdate();
                }
            }

            store.setSelectedPart(
                data.referenceProduct.id,
                typeId,
                localPrice,
                weight,
                name,
                partGroup,
                dataVariant?.id,
                shortName,
            );

            // checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_PART_CHANGE,dataVariant.)

            store.modalParts = { conflict: [], conflictTypes: [], required: [], requiredTypes: [] };

            setConflictModal(false);
            store.menuPopupIgnoreClickOutside = false;
            closePopup();
        };

        const declineModal = () => {
            setConflictModal(false);
            store.menuPopupIgnoreClickOutside = false;
            store.modalParts = { conflict: [], conflictTypes: [], required: [], requiredTypes: [] };
        };

        useEffect(() => {
            if (store?.selectedParts?.length !== 0) {
                // const isSelected = store.selectedParts.map(item => item.id).includes(data?.referenceProduct?.id);
                // setIsActive(isSelected);
                const item = store.selectedParts.find(i => i.id === data.referenceProduct.id);
                if (item && selectedVariant) selectedVariant.price = item.price;
                if (item) setIsActive(true);
            }
        }, [data, store.selectedParts, store.selectedParts.length, selectedVariant]);

        useEffect(() => {
            if (isActive) {
                const element = document.getElementById(data.referenceProduct?.id);
                if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, [isActive, data.referenceProduct.id]);

        const getPrice = () => {
            const initialPrice = dataVariant?.pricing?.price?.gross?.amount ?? 0;
            const currency = dataVariant?.pricing?.price?.gross?.currency;
            const changeablePrice = selectedVariant?.price ? initialPrice - selectedVariant.price : initialPrice;
            const sign = getSignFromNumber(changeablePrice);
            const localPrice = localizePrice(changeablePrice, { alwaysPositive: true });

            return `${sign} ${currency} ${localPrice}`;
        };

        const openLongDescModal = () => {
            setLongDescModal(true);
            store.menuPopupIgnoreClickOutside = true;
        };

        const closeLongDescModal = () => {
            setLongDescModal(false);
            store.menuPopupIgnoreClickOutside = false;
        };

        const selectProductFromModal = () => {
            setLongDescModal(false);
            store.menuPopupIgnoreClickOutside = false;
            setVariant(data?.referenceProduct?.id);
        };

        // const sendGAEvent = async (addedPart: any, removedPart: any) => {
        //     window.dataLayer.push({
        //         event: gtmEvents.BIKE_PART_CHANGE,
        //         bikeCategory: store.bike,
        //         bikeModel: store.selectedBike.name,
        //         addedPart: addedPart,
        //         removedPart: removedPart,
        //     });
        //
        //     await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_PART_CHANGE, addedPart);
        // };

        const sendHubspotDealUpdate = async () => {
            await store.updateConfigurationParts();

            if (localStorage.getItem(LocalStorageKey.EMAIL) === null) return;

            if (store.shareableID && localStorage.getItem(LocalStorageKey.DEAL_ID) !== null) {
                await checkoutStore.hubspotDealUpdate({
                    amount: store.totalPrice,
                    sid: store.shareableID,
                });
            }
        };
        return (
            <>
                <div
                    onMouseLeave={() => setShowSubProductTooltip(false)}
                    className={clsx(styles.part_variant, isActive && styles.active_variant)}
                    onClick={() => setVariant(data?.referenceProduct?.id)}
                    id={data?.referenceProduct?.id}
                >
                    <div
                        className={styles.image}
                        onClick={e => {
                            e.stopPropagation(); // prevent adding item to store if image is clicked
                            openLongDescModal();
                        }}
                    >
                        <img
                            src={imageExists ? `${data?.referenceProduct?.media[0]?.url}?w=130&h=130` : fallbackImg}
                            alt=""
                        />
                        <div className={styles.expand}>
                            <Icon name="expand" />
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div>
                            <h6 className={styles.variant_name}>{data?.referenceProduct?.name}</h6>
                            {data?.referenceProduct?.rating > 0 && <Rating rating={data?.referenceProduct.rating} />}
                            {dataVariant?.pricing?.onSale ? (
                                <div className={styles.price_container}>
                                    {isActive ? (
                                        <h6 className={styles.price}>{t('variantSelected')}</h6>
                                    ) : (
                                        <>
                                            <h6 className={styles.price_with_discount}>{getPrice()}</h6>
                                            <h6 className={styles.discount}>
                                                + {dataVariant?.pricing?.priceUndiscounted?.gross?.currency}{' '}
                                                {localizePrice(dataVariant?.pricing?.priceUndiscounted?.gross?.amount)}
                                            </h6>
                                        </>
                                    )}
                                </div>
                            ) : (
                                <h6 className={styles.price}>{isActive ? t('variantSelected') : getPrice()}</h6>
                            )}
                        </div>
                        <PartDescription
                            shortDescription={data?.referenceProduct?.shortDescription}
                            description={data?.referenceProduct?.description}
                            readMore={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                e.stopPropagation();
                                openLongDescModal();
                            }}
                        />
                        <div className={styles.weight_container}>
                            {/* {!store.isTwinner && (
                                <div className={styles.weight}>
                                    <Icon name="weight" />
                                    <h6>{dataVariant?.weight?.value?.toFixed(2) || 0} kg</h6>
                                </div>
                            )} */}
                            {/* <h6 className={styles.stock}>{dataVariant?.encryptedStock}</h6> */}
                            <StockTrafficColor
                                dataVariant={dataVariant}
                                tooltip={{
                                    value: showSubProductTooltip,
                                    showTooltip: () => setShowSubProductTooltip(true),
                                    hideTooltip: () => setShowSubProductTooltip(false),
                                }}
                            />
                        </div>
                    </div>
                    {selectedVariant ? (
                        <h5 className={clsx(styles.add_remove, isActive && styles.remove_btn)}>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {isPartMandatory ? '' : isActive ? t('variantDelete') : ''}
                        </h5>
                    ) : (
                        <h5 className={clsx(styles.add_remove)}>{t('variantAdd')}</h5>
                    )}

                    {!store.loadingModelIds.find(i => i.id === data?.referenceProduct?.id)?.isLoaded &&
                        !data?.referenceProduct?.no3dRequired && (
                            // <div className={styles.loading_container}>
                            //     <Loader />
                            // </div>
                            <div className={styles.newLoader}>{t('loading3DFile')}</div>
                        )}
                    <Portal>
                        <LongDescriptionModal
                            data={data}
                            isOpen={longDescModal}
                            onClose={closeLongDescModal}
                            onConfirm={selectProductFromModal}
                        />
                        <ConflictModal
                            isOpen={conflictModal}
                            data={data}
                            parts={store.modalParts}
                            onClose={() => declineModal()}
                            onConfirm={() => confirmModal()}
                        />
                    </Portal>
                </div>
            </>
        );
    },
);
