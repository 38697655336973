import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';

import { Button, Sidebar } from '../../shared/components/ui';
import { InstalmentsModal } from '../../shared/components/ui/InstalmentsModal/InstalmentsModal';
import CheckoutStore, { CheckoutMode, CheckoutStep } from '../../stores/Checkout.store';

import { Accordion } from './_components/Accordion/Accordion';
import { AccordionTitle } from './_components/AccordionTitle/AccordionTitle';
import { CheckoutHero } from './_components/CheckoutHero/CheckoutHero';
import { ContactDetails } from './_components/ContactDetails/ContactDetails';
import { Delivery } from './_components/Delivery/Delivery';
import { ExpertCheckout } from './_components/ExpertCheckout/ExpertCheckout';
import { GetAQuote } from './_components/GetAQuote/GetAQuote';
import { GetAQuoteModal } from './_components/GetAQuoteModal/GetAQuoteModal';
// import { InstalmentsInfo } from './_components/InstalmentsInfo/InstalmentsInfo';
import { Payment } from './_components/Payment/Payment';
import { Shipping } from './_components/Shipping/Shipping';
import styles from './Checkout.module.scss';

const DELIVERY_METHOD_NAME = 'Heimlieferung';

export const Checkout = observer(() => {
    const checkoutStore = useContext(CheckoutStore);
    const { checkoutStepsOrder, checkoutStepProgress, expandedStep, data } = checkoutStore;
    const [quoteModal, setQuoteModal] = useState(false);
    const [quoteLoading, setQuoteLoading] = useState(false);
    const [moreInfo, setMoreInfo] = useState(false);
    const [isCreateOfferLoading, setIsCreateOfferLoading] = useState(false);
    const navigate = useNavigate();

    const { id } = useParams();
    const { t } = useTranslation();

    // Generate an address string to use on the accordion
    const address = useMemo(() => {
        const addr = data?.shippingAddress;
        if (addr) {
            return `${t('checkoutBilling')}${addr.firstName} ${addr.lastName} • ${addr.streetAddress1}, ${
                addr?.postalCode
            } ${addr.city} - ${addr.country.country}`;
        } else {
            return '';
        }
    }, [data?.shippingAddress, t]);

    const delivery = useMemo(() => {
        const addr = data?.shippingAddress;
        if (data?.deliveryMethod?.name === DELIVERY_METHOD_NAME) {
            return `${t('checkoutDeliverTo')}${addr?.streetAddress1}, ${addr?.postalCode} ${addr?.city} - ${
                addr?.country.country
            }`;
        } else {
            return `${t('checkoutInStorePickup')}${data?.deliveryMethod?.name}`;
        }
    }, [data?.deliveryMethod, data?.shippingAddress, t]);

    const contact = useMemo(() => {
        if (data?.phone) {
            return `${t('checkoutSentTo')}${data?.email} • ${data?.phone}`;
        } else {
            return `${t('checkoutSentTo')}${data?.email}`;
        }
    }, [data?.phone, data?.email, t]);

    const CHECKOUT_STEPS_DATA = {
        [CheckoutStep.CONTACT]: {
            title: t('checkout_contact'),
            subtitle: contact,
            content: <ContactDetails />,
        },
        [CheckoutStep.SHIPPING]: {
            title: t('checkout_shipping'),
            subtitle: address,
            content: <Shipping />,
        },
        [CheckoutStep.DELIVERY]: {
            title: t('checkout_delivery'),
            subtitle: delivery,
            content: <Delivery />,
        },
        [CheckoutStep.PAYMENT]: {
            title: t('checkout_payment'),
            subtitle: '',
            content: <Payment />,
        },
        [CheckoutStep.EXPERT_CHECKOUT]: {
            title: t('checkout_expert'),
            subtitle: '',
            content: <ExpertCheckout />,
        },
    };

    useEffect(() => {
        checkoutStore.mode = CheckoutMode.BASIC;
        checkoutStore.getCountries();
        checkoutStore.getShopData();
    }, []); //eslint-disable-line

    useEffect(() => {
        if (id && !checkoutStore.data) {
            checkoutStore.getCheckout(id);
        } else {
            checkoutStore.handleSelectedStep();
        }
    }, [id, checkoutStore]); //eslint-disable-line

    const canExpandStep = (step: CheckoutStep) => {
        return step !== expandedStep && step <= checkoutStepProgress;
    };

    const isStepDisabledStyle = (step: CheckoutStep) => {
        return step > checkoutStepProgress ? styles.titleDisabled : undefined;
    };

    const accordionOnClick = (step: CheckoutStep) => {
        if (canExpandStep(step)) {
            checkoutStore.expandedStep = step;
        }
    };

    const getAnQuote = async (data: any) => {
        if (data.phone) data.phone = data.phone?.includes('+') ? data.phone : `+${data.phone}`;

        await checkoutStore.updateMail(data.email);
        if (data.phone) await checkoutStore.updatePhone(data.phone);
        if (data.phone && checkoutStore.phoneValidationFailed) return;
        sendQuoteRequest();
    };

    const sendQuoteRequest = async () => {
        setQuoteLoading(true);
        try {
            const expertCheckoutPaymentGateway = checkoutStore.data?.availablePaymentGateways?.find(
                gateway => gateway.id === 'mirumee.payments.dummy',
            );
            if (!expertCheckoutPaymentGateway) return;
            await checkoutStore.createPayment(expertCheckoutPaymentGateway.id, true);

            await checkoutStore.checkoutComplete(checkoutStore.data?.id, true);

            window.dataLayer.push({
                event: 'Checkout',
                action: 'Next',
                category: 'Create offer',
                label: checkoutStore.productData?.name,
            });

            // await checkoutStore.sendDataToCustomerIo('Checkout quote request');

            navigate(AppRoutes.orderStatus.replace(':id', checkoutStore.data?.id ?? ''));
        } catch (error) {
            console.error(error);
        } finally {
            setQuoteLoading(false);
        }
    };

    if (!checkoutStore.data) {
        return null;
    }
    const createOffer = async () => {
        try {
            setIsCreateOfferLoading(true);

            const expertCheckoutPaymentGateway = checkoutStore.data?.availablePaymentGateways?.find(
                gateway => gateway.id === 'mirumee.payments.dummy',
            );

            if (!expertCheckoutPaymentGateway) return;

            await checkoutStore.createPayment(expertCheckoutPaymentGateway.id, true);
            await checkoutStore.checkoutComplete(checkoutStore.data?.id, true);

            window.dataLayer.push({
                event: 'Checkout',
                action: 'Next',
                category: 'Create offer',
                label: checkoutStore.productData?.name,
            });

            navigate(AppRoutes.orderStatus.replace(':id', checkoutStore.data?.id ?? ''));
        } catch (error) {
            console.error(error);
        } finally {
            setIsCreateOfferLoading(false);
        }
    };

    return (
        <>
            <CheckoutHero data={checkoutStore.data} />
            <section className={styles.container}>
                <div className={styles.checkoutWrapper}>
                    {/* <InstalmentsInfo /> */}
                    <div className={styles.accordionWrapper}>
                        {checkoutStepsOrder.map((step, index) => (
                            <Accordion
                                key={step}
                                expanded={expandedStep === step}
                                titleComponent={
                                    <AccordionTitle
                                        title={`${index + 1}. ${CHECKOUT_STEPS_DATA[step].title}`}
                                        titleStyle={isStepDisabledStyle(step)}
                                        onClick={() => accordionOnClick(step)}
                                        showIcon={canExpandStep(step)}
                                        summary={CHECKOUT_STEPS_DATA[step].subtitle}
                                    />
                                }
                                contentComponent={CHECKOUT_STEPS_DATA[step].content}
                            />
                        ))}
                    </div>

                    <div className={styles.buttonWrap}>
                        <Button
                            className={styles.button}
                            type="ghost"
                            preIcon="offer"
                            onClick={createOffer}
                            isLoading={isCreateOfferLoading}
                            isDisabled={checkoutStepProgress < 4}
                        >
                            {t('paymentCreateOffer')}
                        </Button>
                    </div>
                    {/* <CheckoutModeSwitch /> */}
                </div>

                <div>
                    <Sidebar type="checkout" />
                    <GetAQuote openModal={() => setQuoteModal(true)} openMoreModal={() => setMoreInfo(true)} />
                    <InstalmentsModal open={moreInfo} onClose={() => setMoreInfo(false)} />
                    <GetAQuoteModal
                        data={checkoutStore.data}
                        isOpen={quoteModal}
                        onClose={() => setQuoteModal(false)}
                        onRequestOffer={getAnQuote}
                        isLoading={quoteLoading}
                    />
                </div>
            </section>
        </>
    );
});
