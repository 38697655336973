import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from 'shared/components/ui';

import fallbackImg from '../../../../assets/images/default-part-image-popup.png';
import ConfiguratorStore from '../../../../stores/Configurator.store';
import { PartVariant } from '../PartVariant/PartVariant';

import styles from './MobileTabletVariantsMenu.module.scss';

type MobileTabletVariantsMenuProps = {
    isOpen: boolean;
    onClose: () => void;
    selectedVariant: any;
};

export const MobileTabletVariantsMenu: FC<MobileTabletVariantsMenuProps> = observer(
    ({ selectedVariant, isOpen, onClose }) => {
        const [isIphone, setIsIphone] = useState(false);
        const [isTablet, setIsTablet] = useState(false);
        const [zoomedImage, setZoomedImage] = useState({ url: '', title: '' });
        const store = useContext(ConfiguratorStore);
        const { t } = useTranslation();

        useEffect(() => {
            const userAgent = window.navigator.userAgent;
            if (userAgent.match(/iPhone/i)) {
                setIsIphone(true);
            }
            if (userAgent.match(/iPad/i)) {
                setIsTablet(true);
            }
        }, []);

        useEffect(() => {
            if (isOpen) {
                if (store.selectedGroupType !== selectedVariant.typeId)
                    store.selectedGroupType = selectedVariant.typeId;

                if (selectedVariant.values) {
                    for (const item of selectedVariant.values) {
                        const itemId = store.loadingModelIds.find(i => i.id === item.referenceProduct.id);
                        if (!itemId) {
                            store.loadingModelIds.push({
                                id: item.referenceProduct?.id,
                                isLoaded: false,
                                isLoading: false,
                            });
                        }
                    }
                }
            } else if (store.selectedGroupType === selectedVariant?.type) {
                store.selectedGroupType = '';
            }
        }, [store, store.selectedGroupType, isOpen, selectedVariant]);

        return (
            <>
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    className={clsx(styles.variants_popup, isTablet && styles.tablet_apple)}
                    overlayClassName={styles.overlay}
                    closeClassName={styles.close_icon}
                >
                    <div className={styles.variants_wrapper}>
                        <h3 className={styles.title}>{selectedVariant?.type}</h3>
                        <div className={styles.variants}>
                            {selectedVariant?.values?.map((item: any) => {
                                return (
                                    <PartVariant
                                        key={item.id}
                                        data={item}
                                        closePopup={onClose}
                                        typeId={selectedVariant.typeId}
                                        selectedVariant={selectedVariant}
                                        partGroup={selectedVariant?.group}
                                        zoomedImage={url => setZoomedImage(url)}
                                        isPartMandatory={store.mandatoryParts.find(
                                            (i: any) => i.name === item.referenceProduct?.productType?.name,
                                        )}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className={clsx(styles.footer, isIphone && styles.footer_iphone)}>
                        <Button type="ghost" className={styles.button} onClick={onClose}>
                            {t('select')}
                        </Button>
                    </div>
                </Modal>
                {zoomedImage && (
                    <Modal
                        isOpen={zoomedImage?.url !== ''}
                        onClose={() => setZoomedImage({ url: '', title: '' })}
                        className={styles.zoom_image_container_tablet}
                        maxWidth={444}
                    >
                        <h3 className={styles.zoom_title}>{zoomedImage?.title}</h3>
                        <div className={styles.image_container}>
                            <Icon name="edge" className={styles.top_left} />
                            <Icon name="edge" className={styles.top_right} />
                            <Icon name="edge" className={styles.bottom_left} />
                            <Icon name="edge" className={styles.bottom_right} />
                            <img src={zoomedImage?.url || fallbackImg} alt="" />
                        </div>
                    </Modal>
                )}
            </>
        );
    },
);
