import { PRODUCT_LIGHT } from 'api/queries/configurator';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useUpdateCheckoutMetadata } from 'pages/Configurator/hooks/useUpdateCheckoutMetadata';
import { FC, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { Button, Icon, Loader, Modal, Tags } from 'shared/components/ui';
import { DiscountForm } from 'shared/components/ui/DiscountForm/DiscountForm';
import { NotesForm } from 'shared/components/ui/NotesForm/NotesForm';
import { SalesPersonForm } from 'shared/components/ui/SalesPersonForm/SalesPersonForm';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { apolloClient } from 'shared/lib/apollo';
import { localizePrice } from 'shared/providers/utils';
import { SittingPosition } from 'shared/types/configurator';
import ConfiguratorStore from 'stores/Configurator.store';

import { InstalmentsModal } from '../../../../shared/components/ui/InstalmentsModal/InstalmentsModal';
import { useHubspotDealCreateUpdate } from '../../../../shared/hooks/useHubspotHook';
import { gtmEvents } from '../../../../shared/types/gtmEvents';
import { hubspotEvents } from '../../../../shared/types/hubspotEvents';
import CheckoutStore from '../../../../stores/Checkout.store';
import { ColorSection } from '../ColorSection/ColorSection';
import { PreConfigurations } from '../Preconfigurations/Preconfigurations';
import { PublicCheckoutForm } from '../PublicCheckoutForm/PublicCheckoutForm';
import { QuantityCheckPopup } from '../QuantityCheckPopup/QuantityCheckPopup';
import { SummaryDrawer } from '../SummaryDrawer/SummaryDrawer';

import styles from './ConfiguratorSidebar.module.scss';

type ConfiguratorSidebarProps = {
    onExpand: (isOpen: boolean) => void;
    drawerHeight: number;
    sidebarMainRef: any;
    sidebarRef: any;
};

export const ConfiguratorSidebar: FC<ConfiguratorSidebarProps> = observer(
    ({ onExpand, drawerHeight, sidebarMainRef, sidebarRef }) => {
        const configuratorStore = useContext(ConfiguratorStore);
        const checkoutStore = useContext(CheckoutStore);
        const [loading, setLoading] = useState(false);
        const [openSummary, setOpenSummary] = useState(false);
        const [instalmentsModal, setInstalmentsModal] = useState(false);
        const [overflowHidden, setOverflowHidden] = useState(false);
        const [posPopup, setPosPopup] = useState(false);
        const [clientPopup, setClientPopup] = useState<string>('');
        const [quantityCheckPopup, setQuantityCheckPopup] = useState(false);
        const [updatedPartsCheck, setUpdatedPartsCheck] = useState<any[]>([]);
        const [discount, setDiscount] = useState<any>({
            percentage: null,
            chf: null,
            discount: null,
        });

        const [showDiscountPopup, setShowDiscountPopup] = useState(false);
        const [eintausch, setEintausch] = useState(false);
        const [showNotesPopup, setShowNotesPopup] = useState(false);
        const [notes, setNotes] = useState({
            internal_note: '',
            external_note: '',
        });

        useEffect(() => {
            configuratorStore.discountPercentage = discount.percentage;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [discount]);

        const { t } = useTranslation();
        const navigator = useNavigate();
        const checkoutID = localStorage.getItem(LocalStorageKey.CHECKOUT_ID);
        const configPrice = localizePrice(configuratorStore.totalPrice / 24);

        const [updateCheckoutMetadata] = useUpdateCheckoutMetadata();
        const [createOrUpdateDeal] = useHubspotDealCreateUpdate();

        const isPos = process.env.REACT_APP_POS === 'true';

        useEffect(() => {
            if (checkoutID) checkoutStore.getCheckout(checkoutID);
        }, [checkoutID]); // eslint-disable-line

        // After the checkout data is loaded compare the bike used in the checkout
        // with the loaded bike. If the same bike is used, leave the checkoutID and use it for the session
        // but if it's a different bike loaded than the one in the saved checkout, remove the checkoutID from localStorage
        // so a new checkout object is created
        useEffect(() => {
            if (checkoutStore.data && configuratorStore.selectedBike) {
                const checkoutTemplate = checkoutStore?.data?.metadata?.find(
                    (item: any) => item.key === 'templateId',
                )?.value;
                if (checkoutTemplate && checkoutTemplate !== configuratorStore.selectedBike.id) {
                    localStorage.removeItem(LocalStorageKey.CHECKOUT_ID);
                    localStorage.removeItem(LocalStorageKey.DEAL_ID);
                    localStorage.removeItem(LocalStorageKey.DEAL_SID);
                }
            }
        }, [checkoutStore.data, configuratorStore.selectedBike]);

        const createScreenshot = async () => {
            setLoading(true);
            checkoutStore.createScreenshot();
        };
        const setIsCheckout = (val: boolean) => {
            onExpand(val);
            setOpenSummary(val);
            configuratorStore.setIsCheckout(val);
        };
        const isCheckout = configuratorStore.isCheckout;

        useEffect(() => {
            async function goToCheckout() {
                try {
                    setLoading(true);
                    configuratorStore.listenForNavigationChange = false;

                    if (checkoutID) {
                        await checkoutStore.updateCheckoutLines(
                            configuratorStore.selectedParts,
                            configuratorStore.selectedColors,
                            configuratorStore.selectedBikeVariant,
                            checkoutID,
                        );
                    } else {
                        const createCheckoutResponse = await checkoutStore.createCheckout(
                            configuratorStore.selectedParts,
                            configuratorStore.selectedColors,
                            configuratorStore.selectedBikeVariant,
                        );

                        if (createCheckoutResponse?.checkout && isPos) {
                            await updateCheckoutMetadata();
                        }
                    }

                    const id = checkoutStore.data?.id;
                    if (!id) {
                        return console.error('No checkout ID.');
                    }

                    await checkoutStore.saveScreenshot(id);
                    // required if statement because of hubspot
                    if (!configuratorStore.shareableID) {
                        await configuratorStore.saveConfiguration(true);
                    }
                    await updateCheckoutMetadata();

                    // if (
                    //     localStorage.getItem(LocalStorageKey.EMAIL) !== null &&
                    //     localStorage.getItem(LocalStorageKey.DEAL_ID) === null
                    // ) {
                    //     await configuratorStore.hubspotDealCreate(id);
                    // }

                    window.dataLayer.push({
                        event: gtmEvents.CHECKOUT_CREATE,
                    });

                    // await checkoutStore.sendDataToCustomerIo(gtmEvents.CHECKOUT_CREATE);

                    // Replace the configurator URL with the one including SID
                    // So if user uses browsers back arrow it still will load the latest configuration he created
                    const SID = checkoutStore.data?.metadata.find(d => d.key === 'sid')?.value;
                    // await checkoutStore.sendDataToCustomerIo(gtmEvents.CHECKOUT_CREATE, SID);
                    // await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_PRECONFIGURATION_CREATE, SID!);
                    if (localStorage.getItem(LocalStorageKey.EMAIL) !== null) {
                        await checkoutStore.hubspotDealUpdate({
                            amount: configuratorStore.totalPrice,
                            sid: configuratorStore.shareableID,
                        });
                    }
                    navigator(`${AppRoutes.configurator}/${SID}`, { replace: true });
                    configuratorStore.configurationExists = true;

                    setClientPopup(id);
                    // navigator(AppRoutes.checkout.replace(':id', id));
                    // navigator(AppRoutes.serviceUpgrade);

                    localStorage.setItem(LocalStorageKey.CHECKOUT_ID, id);
                } catch (error) {
                    console.error('error', error);
                } finally {
                    setLoading(false);
                }
            }

            if (checkoutStore.screenshot && !isPos) {
                goToCheckout();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [checkoutStore.screenshot]);

        useLayoutEffect(() => {
            if (configuratorStore.sidebarExpanded) {
                setOverflowHidden(true);
            } else {
                setTimeout(() => {
                    setOverflowHidden(false);
                }, 400);
            }
        }, [configuratorStore.sidebarExpanded]);

        const filteredSizes = configuratorStore.sizes.filter((size: any) =>
            configuratorStore.selectedBike?.variants.some((variant: any) =>
                variant.metadata?.some(
                    (meta: any) => meta?.key === 'size' && meta?.value?.toLowerCase() === size.name.toLowerCase(),
                ),
            ),
        );

        const fetchPartsData = async () => {
            try {
                setLoading(true);
                const selectedPartIds = configuratorStore.selectedParts.map((part: any) => part.id);

                if (selectedPartIds.length === 0) {
                    setLoading(false);
                    return;
                }

                const responses = await Promise.all(
                    selectedPartIds.map(productId =>
                        apolloClient.query({
                            query: PRODUCT_LIGHT,
                            variables: {
                                productId,
                                channel: process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL,
                                bikeProductId: configuratorStore.selectedBike?.id,
                            },
                        }),
                    ),
                );

                const allParts = responses.map(response => response.data);
                const filteredParts = allParts.filter(
                    (part: any) => part.product?.variants?.[0]?.stockTrafficColor === 'red',
                );
                setUpdatedPartsCheck(filteredParts);
                if (filteredParts?.length > 0) {
                    setQuantityCheckPopup(true);
                } else {
                    createScreenshot();
                }
            } catch (error) {
                console.error('Error fetching parts:', error);
            } finally {
                setLoading(false);
            }
        };

        const weiterFunction = async () => {
            if (isPos) {
                setPosPopup(true);
            } else {
                fetchPartsData();
            }
        };

        useEffect(() => {
            if (discount.percentage) {
                setDiscount({
                    percentage: discount?.percentage,
                    chf: configuratorStore.totalPrice - configuratorStore.totalPrice * (discount?.percentage / 100),
                    discount: configuratorStore.totalPrice * (discount?.percentage / 100),
                });

                checkoutStore.updateExpertCheckoutData({
                    discount_percent: discount?.percentage,
                    discount_amount: configuratorStore.totalPrice * (discount?.percentage / 100),
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [configuratorStore.totalPrice]);

        useEffect(() => {
            if (!isCheckout) {
                setOpenSummary(false);
                onExpand(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isCheckout]);

        return (
            <>
                <div
                    className={clsx(
                        styles.configurator,
                        overflowHidden && styles.overflow_hidden,
                        configuratorStore.sidebarWidth > 400 && configuratorStore.sidebarExpanded && styles.smaller,
                        isCheckout && styles.isCheckout,
                    )}
                    id="conf"
                    ref={sidebarRef}
                >
                    {!isCheckout && (
                        <>
                            <h5>{t('sidebarSpecificationTitle')}</h5>
                            <div
                                className={clsx(
                                    styles.sidebar_main,
                                    configuratorStore.sidebarWidth > 400 && styles.wrap,
                                )}
                                id="sidebarMain"
                                ref={sidebarMainRef}
                            >
                                <ColorSection />

                                {filteredSizes.length > 0 ? (
                                    <Tags
                                        className={styles.bikeSizes}
                                        type="frame size"
                                        data={filteredSizes}
                                        title={t('sidebarFrameSizeTitle')}
                                        active={configuratorStore.selectedSize.name || configuratorStore.selectedSize}
                                        onSelect={async (size: any) => {
                                            configuratorStore.handleChangeSize(size);
                                            window.dataLayer.push({
                                                event: gtmEvents.BIKE_SIZE,
                                                size: size.name,
                                                category: configuratorStore.bike,
                                                bikeModel: configuratorStore.selectedBike.name,
                                            });
                                            // await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_SIZE, size.name);
                                            await checkoutStore.hubspotEventTrack(
                                                hubspotEvents.BIKE_SIZE_SELECTED,
                                                size.name,
                                            );
                                            await createOrUpdateDeal();
                                        }}
                                    />
                                ) : (
                                    !configuratorStore.loadingEnded && (
                                        <Skeleton
                                            count={5}
                                            circle={true}
                                            width={40}
                                            height={40}
                                            containerClassName={styles.skeleton}
                                            baseColor="#000000"
                                            highlightColor="#3A383D"
                                        />
                                    )
                                )}

                                {configuratorStore.sittingPositions?.length > 0 ? (
                                    <Tags
                                        type="list"
                                        data={configuratorStore.sittingPositions}
                                        title={t('sidebarSittingPositionTitle')}
                                        active={
                                            configuratorStore.selectedSittingPosition?.name ||
                                            configuratorStore.selectedSittingPosition
                                        }
                                        onSelect={async (position: SittingPosition) => {
                                            configuratorStore.selectedSittingPosition = position;
                                            window.dataLayer.push({
                                                event: gtmEvents.BIKE_SITTING_POSITION,
                                                sittingPosition: position.name,
                                                category: configuratorStore.bike,
                                                bikeModel: configuratorStore.selectedBike.name,
                                            });
                                            // await checkoutStore.sendDataToCustomerIo(
                                            //     gtmEvents.BIKE_SITTING_POSITION,
                                            //     position.name,
                                            // );
                                            await checkoutStore.hubspotEventTrack(
                                                hubspotEvents.BIKE_SITTING_POSITION_SELECTED,
                                                position.name,
                                            );
                                            await createOrUpdateDeal();
                                        }}
                                    />
                                ) : (
                                    !configuratorStore.loadingEnded && (
                                        <Skeleton
                                            count={3}
                                            width={80}
                                            height={30}
                                            containerClassName={styles.skeleton}
                                            baseColor="#000000"
                                            highlightColor="#3A383D"
                                            borderRadius={6}
                                        />
                                    )
                                )}
                            </div>
                        </>
                    )}
                    <div className={styles.preconig_wrapper}>
                        {configuratorStore.preconfigurationsList?.length > 0 ? (
                            <PreConfigurations />
                        ) : (
                            !configuratorStore.loadingEnded && (
                                <Skeleton
                                    count={3}
                                    width={150}
                                    height={144}
                                    containerClassName={styles.skeleton}
                                    baseColor="#000000"
                                    highlightColor="#3A383D"
                                    borderRadius={6}
                                    inline
                                />
                            )
                        )}
                        <SummaryDrawer
                            open={openSummary}
                            isCheckout={isCheckout}
                            onClose={() => {
                                setOpenSummary(false);
                                onExpand(false);
                            }}
                            height={drawerHeight}
                        />
                    </div>
                </div>

                {instalmentsModal && (
                    <InstalmentsModal open={instalmentsModal} onClose={() => setInstalmentsModal(false)} />
                )}

                {!isCheckout && (
                    <div
                        className={clsx(styles.summary, openSummary && styles.hide, {
                            [styles.discount]: isCheckout,
                            [styles.zero]: !discount.percentage,
                            [styles.tradeIn]: eintausch,
                        })}
                        onClick={() => {
                            if (configuratorStore.loadingEnded) {
                                setOpenSummary(!openSummary);
                                onExpand(true);
                            }
                        }}
                    >
                        <h6>{t('showSummary')}</h6>
                        {configuratorStore.loadingEnded ? (
                            <Icon name="arrow_down" className={styles.icon} />
                        ) : (
                            <div className={styles.loaderWrap}>
                                <Loader className={styles.loader} />
                            </div>
                        )}
                    </div>
                )}

                <div
                    className={clsx(
                        styles.configurator_footer,
                        configuratorStore.sidebarWidth > 370 && configuratorStore.sidebarExpanded && styles.smaller,
                    )}
                >
                    <div className={styles.price_from_wrapper}>
                        <p className={styles.total_price}>{t('priceStartingFrom')}</p>
                        <Icon
                            name="question"
                            className={styles.question_mark}
                            onClick={() => setInstalmentsModal(true)}
                        />
                    </div>
                    <div className={styles.price}>
                        <span className={styles.per_month}>
                            {configuratorStore.selectedBike?.configuratorPrice?.currency} {parseInt(configPrice)}
                        </span>
                        <span className={styles.per_month}> / {t('month')}</span>
                    </div>
                    <div className={styles.include_taxes}>
                        <h6>{t('totalPriceIncludeTax')}</h6>
                        <h3>
                            {configuratorStore.selectedBike?.configuratorPrice?.currency}{' '}
                            {localizePrice(configuratorStore.totalPrice)}
                        </h3>
                    </div>
                    {isCheckout && isPos && (
                        <div className={styles.discountSection}>
                            {discount?.percentage > 0 && (
                                <>
                                    <h6 className={styles.line}>
                                        {t('discountForm.discountPercentage')} <div>{discount.percentage}%</div>
                                    </h6>
                                    <h6 className={styles.line}>
                                        {t('discountForm.netPrice')} (inkl. MWSt)
                                        <div>CHF {localizePrice(discount.chf)}</div>
                                    </h6>
                                </>
                            )}
                            {eintausch && (
                                <h6 className={styles.line}>
                                    {t('discountForm.tradeIn')}
                                    <div>{t('discountForm.yes')}</div>
                                </h6>
                            )}
                            <div>
                                <div className={styles.actions}>
                                    <Button
                                        type={
                                            notes.external_note || notes.internal_note || showNotesPopup
                                                ? 'secondary'
                                                : 'primaryDark'
                                        }
                                        className={styles.discountButton}
                                        onClick={() => setShowNotesPopup(true)}
                                        isDisabled={loading || !configuratorStore.loadingEnded}
                                    >
                                        {notes.external_note || notes.internal_note || showNotesPopup ? '- ' : '+ '}
                                        {t('notesForm.button')}
                                    </Button>

                                    <Button
                                        type={discount.percentage || showDiscountPopup ? 'secondary' : 'primaryDark'}
                                        className={styles.discountButton}
                                        onClick={() => setShowDiscountPopup(true)}
                                        isDisabled={loading || !configuratorStore.loadingEnded}
                                    >
                                        {discount.percentage || showDiscountPopup ? '- ' : '+ '}
                                        {t('discountForm.specialCondition')}
                                    </Button>
                                </div>
                                <Button
                                    type={eintausch ? 'secondary' : 'primaryDark'}
                                    className={styles.discountButton}
                                    onClick={() => {
                                        setEintausch(!eintausch);
                                        checkoutStore.updateExpertCheckoutData({
                                            is_trade_in: !eintausch,
                                        });
                                    }}
                                    isDisabled={loading || !configuratorStore.loadingEnded}
                                >
                                    {eintausch ? '-' : '+'} {t('discountForm.tradeIn')}
                                </Button>
                            </div>
                        </div>
                    )}

                    {!isCheckout && (
                        <Button
                            type="primaryDark"
                            postIcon="arrow_right"
                            className={styles.button}
                            onClick={weiterFunction}
                            isLoading={loading}
                            loaderClassName={styles.btn_loader}
                            loadingText={t('sidebarLoadingImage')}
                            isDisabled={loading || !configuratorStore.loadingEnded}
                        >
                            {t('sidebarBuyButton')}
                        </Button>
                    )}
                </div>
                {isPos && (
                    <>
                        <Modal
                            isOpen={posPopup}
                            onClose={() => setPosPopup(false)}
                            maxWidth="600px"
                            className={styles.modal}
                        >
                            <SalesPersonForm
                                onSuccess={() => {
                                    setPosPopup(false);
                                    setIsCheckout(true);
                                    // move to final step screenshot
                                    // createScreenshot();
                                }}
                            />
                        </Modal>
                        <Modal
                            isOpen={showDiscountPopup}
                            onClose={() => setShowDiscountPopup(false)}
                            maxWidth="600px"
                            className={styles.modal}
                        >
                            <DiscountForm
                                defaultValues={{
                                    percentage: discount.percentage,
                                    chf: discount.chf,
                                    discount: discount.discount,
                                }}
                                onSuccess={val => {
                                    setDiscount({
                                        percentage: val?.percentage ?? 0,
                                        chf: val?.chf ?? configuratorStore.totalPrice,
                                        discount: val.discount ?? 0,
                                    });

                                    checkoutStore.updateExpertCheckoutData({
                                        is_trade_in: eintausch,
                                        discount_percent: val?.percentage,
                                        discount_amount: val?.discount,
                                    });
                                    setShowDiscountPopup(false);
                                }}
                            />
                        </Modal>

                        <Modal
                            isOpen={showNotesPopup}
                            onClose={() => setShowNotesPopup(false)}
                            maxWidth="600px"
                            className={styles.modal}
                        >
                            <NotesForm
                                defaultValues={notes}
                                onSuccess={val => {
                                    setNotes(val);
                                    checkoutStore.updateExpertCheckoutData({
                                        internal_note: val?.internal_note,
                                        external_note: val?.external_note,
                                    });
                                    setShowNotesPopup(false);
                                }}
                            />
                        </Modal>
                    </>
                )}

                {!isPos && (
                    <>
                        <Modal
                            isOpen={quantityCheckPopup}
                            onClose={() => setQuantityCheckPopup(false)}
                            maxWidth="600px"
                            className={styles.modal}
                        >
                            <QuantityCheckPopup
                                data={updatedPartsCheck}
                                close={() => setQuantityCheckPopup(false)}
                                onSuccess={() => {
                                    setQuantityCheckPopup(false);
                                    createScreenshot();
                                }}
                            />
                        </Modal>
                        <Modal
                            isOpen={!!clientPopup}
                            onClose={() => setClientPopup('')}
                            maxWidth="600px"
                            className={styles.modal}
                        >
                            <PublicCheckoutForm id={clientPopup} close={() => setClientPopup('')} />
                        </Modal>
                    </>
                )}
            </>
        );
    },
);
