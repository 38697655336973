import { RefObject, useEffect, useMemo } from 'react';

type Event = MouseEvent | TouchEvent;

function useClickOutside<T extends HTMLElement = HTMLElement>(
    refs: RefObject<T> | RefObject<T>[],
    handler: (event: Event) => void,
    ignoreClickOutside: boolean = false,
) {
    const refsArray = useMemo(() => (Array.isArray(refs) ? refs : [refs]), [refs]);

    useEffect(() => {
        const listener = (event: Event) => {
            if (ignoreClickOutside) return;

            const clickedInside = refsArray.some(ref => {
                const el = ref?.current;
                return el && el.contains(event.target as Node);
            });
            if (!clickedInside) {
                handler(event);
            }
        };
        document.addEventListener('click', listener, true);
        return () => {
            document.removeEventListener('click', listener, true);
        };
    }, [refsArray, handler, ignoreClickOutside]);
}

export default useClickOutside;
