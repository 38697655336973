export const PRODUCT_FIELDS = `
    id
    name
    description
    configuratorCover
    isEBike
    configuratorPriceLabel
    isCustomColorActive
    isAvailable
    bikeDescriptionTitle
    weight {
        value
        unit
    }
    translation(languageCode: $language) {
        bikeDescriptionTitle
        description
        name
        usp1
        usp2
        usp3
        shortTitle
        shortDescription
    }
    customColorVariantsByGroup(languageCode: $language) {
        group
        key
        values {
            code
            name
            variant { 
                id
            }
        }
    }
    media {
        id
        url
    }
    attributes {
        attribute {
            slug
        }
        values {
            id
            name
        }
    }
    configuratorPrice {
        currency
        amount
    }
    collections {
        name
    }
    sittingPositions {
        id
        name
        note
    }
    variants {
        id
        name
        stockTrafficColor
        metadata {
            key
            value
        }
        media {
            id
            url
            configuratorOnly
        }
        weight {
            value
            unit
        }
        attributes {
            attribute {
                name
                slug
            }
            values {
                id
                name
                value
                file {
                    url
                }
            }
        }
    }
    usp1
    usp2
    usp3
    sittingPositions {
        name
        id
        note
    }
`;

export const PRODUCT_FIELDS_STOCK = `
    id
    name
    description
    configuratorCover
    isEBike
    configuratorPriceLabel
    isCustomColorActive
    isAvailable
    bikeDescriptionTitle
    weight {
        value
        unit
    }
    translation(languageCode: $language) {
        bikeDescriptionTitle
        description
        name
        usp1
        usp2
        usp3
        shortTitle
        shortDescription
    }
    customColorVariantsByGroup(languageCode: $language) {
        group
        key
        values {
            code
            name
            variant { 
                id
            }
        }
    }
    media {
        id
        url
    }
    attributes {
        attribute {
            slug
        }
        values {
            id
            name
        }
    }
    configuratorPrice {
        currency
        amount
    }
    collections {
        name
    }
    sittingPositions {
        id
        name
        note
    }
    variants {
        id
        name
        stockTrafficColor
        metadata {
            key
            value
        }
        media {
            id
            url
            configuratorOnly
        }
        weight {
            value
            unit
        }
        approomStock(bikeProductId: $id) {
            stock
        }
        attributes {
            attribute {
                name
                slug
            }
            values {
                id
                name
                value
                file {
                    url
                }
            }
        }
    }
    usp1
    usp2
    usp3
    sittingPositions {
        name
        id
        note
    }
`;

export const PARTS_FIELD = `
    requiredTypes {
        id
        name
    }
    configuratorModels {
        id
    }
    optionsByType(languageCode: $language) {
        type
        typeId
        group
        image
        conflictingProductTypes {
            id
            name
        }
        values {
            id
            name
            referenceProduct {
                id
                  name
            }
        }
    }
    attributes {
        attribute {
            name
            id
        }
        values {
            name
        }
    }
`;
