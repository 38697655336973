import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/ui';

import styles from './OrderOverviewFooter.module.scss';

type OrderOverviewFoorerProps = {
    isDisabled: boolean;
    createCheckout: (noMail: boolean) => void;
    loadingOffer?: boolean;
    loadingCheckout?: boolean;
};

export const OrderOverviewFooter: React.FC<OrderOverviewFoorerProps> = ({
    isDisabled,
    createCheckout,
    loadingCheckout,
    loadingOffer,
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.footer}>
            <Button
                type="primary"
                className={styles.button}
                isLoading={loadingOffer}
                loaderClassName={styles.btn_loader}
                onClick={() => createCheckout(false)}
                isDisabled={isDisabled}
            >
                {t('overviewPage.buttons.send')}
            </Button>

            <Button
                type="primary"
                className={styles.button}
                isLoading={loadingCheckout}
                onClick={() => createCheckout(true)}
                loaderClassName={styles.btn_loader}
                isDisabled={isDisabled}
            >
                {t('overviewPage.buttons.finalize')}
            </Button>
        </div>
    );
};
