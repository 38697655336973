import { CHECKOUT_EXPERT_PIN_VERIFY } from 'api/mutations/checkout';
import clsx from 'clsx';
// eslint-disable-next-line import/order
import { observer } from 'mobx-react-lite';

import 'react-phone-input-2/lib/style.css';

import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { Button, Checkbox, Input, Label, Select } from 'shared/components/ui';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { EMAIL_REGEX, PHONE_REGEX } from 'shared/helpers/validators';
import { apolloClient } from 'shared/lib/apollo';
import { CountryType } from 'shared/types/checkout';
import CheckoutStore from 'stores/Checkout.store';
import ConfiguratorStore from 'stores/Configurator.store';

import styles from './UserExpertCheckoutForm.module.scss';

interface UserExpertCheckoutFormProps {
    onSuccess?: (data: any) => void;
    // goBack: () => void;
}
export interface UserExpertFormValues {
    companyName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    country: string;
    streetAddress1: string;
    postalCode: string;
    city: string;
}
export const UserExpertCheckoutForm: React.FC<UserExpertCheckoutFormProps> = observer(({ onSuccess }) => {
    const checkoutStore = useContext(CheckoutStore);
    const configuratorStore = useContext(ConfiguratorStore);
    const { t } = useTranslation();
    const countries = checkoutStore.countries;
    const [isMarketingAccepted, setIsMarketingAccepted] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const isPos = process.env.REACT_APP_POS === 'true';
    const [loading, setLoading] = useState(false);

    const { data } = checkoutStore;
    const savedJSON = localStorage.getItem(LocalStorageKey.USER_EXPERT_CHECKOUT);
    const savedValues: Partial<UserExpertFormValues> = savedJSON ? JSON.parse(savedJSON) : {};

    const initialValues: UserExpertFormValues = {
        companyName: savedValues.companyName ?? '',
        firstName: savedValues.firstName ?? '',
        lastName: savedValues.lastName ?? '',
        email: savedValues.email ?? '',
        phone: savedValues.phone ?? '',
        country: savedValues.country ?? 'CH',
        streetAddress1: savedValues.streetAddress1 ?? '',
        postalCode: savedValues.postalCode ?? '',
        city: savedValues.city ?? '',
    };

    const defaultCountryCode = initialValues.country || 'CH';
    const defaultCountry = countries.find((c: any) => c.code === defaultCountryCode);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | null = null;

        if (configuratorStore.isCheckout) {
            timer = setTimeout(() => {
                setShowForm(true);
            }, 200);
        } else {
            setShowForm(false);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [configuratorStore.isCheckout]);

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm<UserExpertFormValues>({
        mode: 'onChange',
        defaultValues: initialValues,
    });

    const goBack = () => {
        setShowForm(false);
        setTimeout(() => {
            configuratorStore.setIsCheckout(false);
        }, 200);
    };

    const clearForm = () => {
        localStorage.removeItem(LocalStorageKey.USER_EXPERT_CHECKOUT);
        localStorage.removeItem(LocalStorageKey.EMAIL);

        reset({
            companyName: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            country: '',
            streetAddress1: '',
            postalCode: '',
            city: '',
        });
    };

    useEffect(() => {
        if (configuratorStore?.screenshot) {
            configuratorStore.isOverview = true;
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuratorStore.screenshot]);
    const onSubmit = async (data: any) => {
        onSuccess?.(data);
        setLoading(true);
        configuratorStore.screenshot = '';
        configuratorStore.createScreenshot();
    };

    const switchToExpertCheckout = async () => {
        try {
            const { data } = await apolloClient.mutate({
                mutation: CHECKOUT_EXPERT_PIN_VERIFY,
                variables: { checkout: checkoutStore.data?.id, pin: process.env.REACT_APP_EXPERT_PASSWORD },
            });
            const token = data.checkoutExpertPinVerify.token;
            if (!token) return;
            localStorage.setItem(LocalStorageKey.EXPERT_CHECKOUT_TOKEN, token);
        } catch (error) {
            console.error(error);
        } finally {
            reset();
        }
    };

    useEffect(() => {
        const load = async () => {
            if (data?.id) {
                await switchToExpertCheckout();
            }
        };
        if (data?.id) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id, configuratorStore?.discountPercentage]);

    useEffect(() => {
        checkoutStore.getCountries();
        checkoutStore.getShopData();
    }, []); //eslint-disable-line

    useEffect(() => {
        localStorage.setItem(LocalStorageKey.MARKETING_ACCEPTED, `${isMarketingAccepted}`);
    }, [isMarketingAccepted]);

    if (!isPos || !showForm) return null;

    return (
        <div className={styles.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.title}>
                    <h2> {t('userExpertCheckoutForm.title')}</h2>
                    <Button type="secondary" className={styles.clear} onClick={clearForm} preIcon="reset">
                        {t('userExpertCheckoutForm.clear')}
                    </Button>
                </div>

                <div className={styles.inputsWrapper}>
                    <div>
                        <Input
                            className={styles.input}
                            labelWrapperClassName={styles.labelWrapper}
                            label={t('companyInput')}
                            placeholder={t('companyPlaceholder')}
                            {...register('companyName')}
                        />
                    </div>
                    <div className={styles.row}>
                        <Input
                            className={styles.input}
                            labelWrapperClassName={styles.labelWrapper}
                            label={t('firstNameInput')}
                            placeholder={t('firstNamePlaceholder')}
                            error={errors.firstName?.message}
                            {...register('firstName', { required: t('fieldRequired') })}
                        />

                        <Input
                            className={styles.input}
                            labelWrapperClassName={styles.labelWrapper}
                            label={t('lastNameInput')}
                            placeholder={t('lastNamePlaceholder')}
                            error={errors.lastName?.message}
                            {...register('lastName', { required: t('fieldRequired') })}
                        />
                    </div>

                    <div className={styles.row}>
                        <Input
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: t('checkoutEmailError'),
                                },
                                onBlur: e => {
                                    localStorage.setItem(LocalStorageKey.EMAIL, e.target.value);
                                },
                            })}
                            className={styles.input}
                            label={t('checkoutEmailLabel')}
                            placeholder={t('checkoutEmailPlaceholder')}
                            labelWrapperClassName={styles.labelWrapper}
                            autoFocus
                            error={errors?.email as any}
                        />
                        {errors?.email && <div className={styles.error}>{t('checkoutEmailError')}</div>}
                        <div className={styles.input}>
                            <Label
                                title={t('phoneNumberTitle')}
                                tooltipText={t('phoneNumberTooltip')}
                                className={styles.labelWrapper}
                            />
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: PHONE_REGEX,
                                        message: t('phoneRequiresDigits'),
                                    },
                                }}
                                render={({ field: { value, onChange } }) => (
                                    <PhoneInput
                                        placeholder={t('phonePlaceholder')}
                                        value={value}
                                        onChange={onChange}
                                        country={'ch'}
                                        preferredCountries={['ch', 'de', 'at']}
                                    />
                                )}
                            />
                            {errors?.phone && <div className={styles.error}>{t('phoneRequiresDigits')}</div>}
                        </div>
                    </div>

                    <div className={styles.row}>
                        {defaultCountry?.code && (
                            <div className={styles.input}>
                                <Controller
                                    name="country"
                                    control={control}
                                    rules={{ required: t('fieldRequired') }}
                                    render={({ field: { name, onChange } }) => (
                                        <Select<CountryType>
                                            label={t('countryInput')}
                                            labelWrapperClassName={styles.labelWrapper}
                                            options={countries}
                                            name={name}
                                            defaultValue={{
                                                country: defaultCountry?.country,
                                                code: defaultCountry?.code,
                                            }}
                                            getOptionLabel={data => data.country}
                                            getOptionValue={data => data.code}
                                            onChange={data => {
                                                onChange(data?.code);
                                            }}
                                            error={errors.country?.message}
                                        />
                                    )}
                                />
                            </div>
                        )}

                        <Input
                            className={styles.input}
                            labelWrapperClassName={styles.labelWrapper}
                            label={t('streetAddressInput')}
                            placeholder={t('streetAddressPlaceholder')}
                            error={errors.streetAddress1?.message}
                            {...register('streetAddress1', {
                                required: t('fieldRequired'),
                            })}
                        />
                    </div>

                    <div className={styles.row}>
                        <Input
                            type="number"
                            className={clsx(styles.input, styles.postalCode)}
                            labelWrapperClassName={styles.labelWrapper}
                            label={t('postcodeInput')}
                            placeholder={t('postcodePlaceholder')}
                            error={errors.postalCode?.message}
                            {...register('postalCode', { required: t('fieldRequired') })}
                        />

                        <Input
                            className={clsx(styles.input, styles.city)}
                            labelWrapperClassName={styles.labelWrapper}
                            label={t('cityInput')}
                            placeholder={t('cityPlaceholder')}
                            error={errors.city?.message}
                            {...register('city', { required: t('fieldRequired') })}
                        />
                    </div>
                    <div className={styles.checkbox_container}>
                        <Checkbox
                            color="gray"
                            onChange={() => setIsMarketingAccepted(!isMarketingAccepted)}
                            checked={isMarketingAccepted}
                        />
                        <span className={styles.checkbox_text}>
                            <Trans i18nKey="emailAcceptPolicy">
                                I accept the
                                <a
                                    href={t('privacyPolicyLink')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        fontWeight: 'bolder',
                                        margin: '0 4px',
                                        color: 'black',
                                    }}
                                >
                                    Privacy Policy
                                </a>
                                and would like to receive news from Thömus.
                            </Trans>
                        </span>
                    </div>
                </div>

                <div className={styles.action}>
                    <Button
                        type="primaryDark"
                        className={styles.button}
                        onClick={() => goBack()}
                        loaderClassName={styles.btn_loader}
                    >
                        {t('userExpertCheckoutForm.back')}
                    </Button>

                    <Button
                        type="primary"
                        className={styles.button}
                        loaderClassName={styles.btn_loader}
                        isLoading={loading}
                        isDisabled={loading || !configuratorStore.loadingEnded}
                        htmlType="submit"
                    >
                        {t('next')}
                    </Button>
                </div>
            </form>
        </div>
    );
});
