import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/ui/Button/Button';
import { Select } from 'shared/components/ui/Select/Select';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import CheckoutStore from 'stores/Checkout.store';

import { Loader } from '../Loader/Loader';

import styles from './SalesPersonForm.module.scss';

interface SearchFormType {
    point_of_sale: string;
    salesperson: string;
}

export const SalesPersonForm: React.FC<{ onSuccess?: () => void }> = observer(({ onSuccess }) => {
    const { t } = useTranslation();
    const checkoutStore = useContext(CheckoutStore);
    const [isLoading, setIsLoading] = useState(false);
    const [dropdownFields, setDropdownFields] = useState<any>(null);
    const [sellersFilteredOption, setSellersFilteredOptions] = useState<any>([]);
    const posID = localStorage.getItem(LocalStorageKey.POS);
    const salesID = localStorage.getItem(LocalStorageKey.SALESPERSON);

    const locationOptions = dropdownFields?.expertCheckoutLocations?.edges.map(({ node }: any) => ({
        label: node.location,
        value: node.approomId,
    }));

    const defaultLocation = locationOptions?.find((option: any) => option?.value === Number(posID));

    const { handleSubmit, control, watch, setValue } = useForm<SearchFormType>({
        mode: 'onChange',
    });
    const point_of_sale = watch('point_of_sale');
    const salesperson = watch('salesperson');

    const onSubmit = async (data: SearchFormType) => {
        setIsLoading(true);
        localStorage.setItem(LocalStorageKey.SALESPERSON, data.salesperson);
        localStorage.setItem(LocalStorageKey.POS, data.point_of_sale);
        try {
            checkoutStore.updateExpertCheckoutData(data);
            onSuccess?.();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            onSuccess?.();
        }
    };

    useEffect(() => {
        const selectedLocation = dropdownFields?.expertCheckoutLocations?.edges.find(
            (location: any) => location.node.approomId === point_of_sale,
        );

        setSellersFilteredOptions(
            selectedLocation?.node?.sellers?.map((seller: any) => ({
                label: `${seller.firstName} ${seller.lastName}`,
                value: seller.email,
            })) || [],
        );
        setValue('salesperson', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [point_of_sale]);

    useEffect(() => {
        const fetchExpertCheckoutPosData = async () => {
            try {
                const dropdownFields = await checkoutStore.getExpertCheckoutPosData();
                setDropdownFields(dropdownFields);
            } catch (error) {
                console.error(error);
            }
        };

        fetchExpertCheckoutPosData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dropdownFields && posID && salesID) {
            // setValue('salesperson', defaultSalesPerson);
            setValue('point_of_sale', defaultLocation.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultLocation?.value]);

    useEffect(() => {
        const defaultSalesPerson = sellersFilteredOption?.find((option: any) => option?.value === salesID) || '';
        if (dropdownFields && posID && salesID && defaultSalesPerson) {
            setValue('salesperson', defaultSalesPerson?.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sellersFilteredOption]);

    if (!dropdownFields)
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '0 auto',
                }}
            >
                <Loader />
            </div>
        );

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
            <div className={styles.body}>
                <h4>{t('salesPersonPopup.title')}</h4>
                {/* Location Field */}
                <Controller
                    name="point_of_sale"
                    control={control}
                    rules={{ required: t('salesPersonPopup.locationRequired') }}
                    render={({ field: { name, onChange } }) => {
                        return (
                            <Select
                                options={dropdownFields?.expertCheckoutLocations?.edges.map(({ node }: any) => ({
                                    label: node.location,
                                    value: node.approomId,
                                }))}
                                defaultValue={defaultLocation}
                                name={name}
                                maxMenuHeight={150}
                                placeholder={t('salesPersonPopup.locationPlaceholder')}
                                onChange={(data: any) => {
                                    onChange(data?.value);
                                }}
                            />
                        );
                    }}
                />
                {/* Salesperson Field */}
                {sellersFilteredOption && (
                    <Controller
                        name="salesperson"
                        control={control}
                        rules={{ required: t('salesPersonPopup.salesPersonRequired') }}
                        render={({ field: { name, onChange, value } }) => {
                            return (
                                <Select
                                    options={sellersFilteredOption}
                                    name={name}
                                    value={sellersFilteredOption?.find((option: any) => option.value === value) || null}
                                    isDisabled={!point_of_sale}
                                    defaultValue={sellersFilteredOption?.find(
                                        (option: any) => option.value === salesID,
                                    )}
                                    maxMenuHeight={150}
                                    placeholder={t('salesPersonPopup.salesPersonPlaceholder')}
                                    onChange={(data: any) => {
                                        onChange(data?.value);
                                    }}
                                />
                            );
                        }}
                    />
                )}
                <Button
                    htmlType="submit"
                    className={styles.button}
                    isDisabled={!salesperson || !point_of_sale}
                    isLoading={isLoading}
                >
                    {t('salesPersonPopup.button')}
                </Button>
            </div>
        </form>
    );
});
