import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button } from 'shared/components/ui/Button/Button';
import { ContactModalContext, ContactModalScreen } from 'shared/components/ui/ContactModal/stores/ContactModal.store';
import { ErrorMessage } from 'shared/components/ui/ErrorMessage/ErrorMessage';
import { Input } from 'shared/components/ui/Input/Input';
import { Label } from 'shared/components/ui/Label/Label';
import { Textarea } from 'shared/components/ui/Textarea/Textarea';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { EMAIL_REGEX, PHONE_REGEX } from 'shared/helpers/validators';
import { gtmEvents } from 'shared/types/gtmEvents';
import ConfiguratorStore from 'stores/Configurator.store';

import CheckoutStore from '../../../../../../../../stores/Checkout.store';
import { hubspotEvents } from '../../../../../../../types/hubspotEvents';

import styles from './EmailForm.module.scss';

interface EmailFormType {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    message: string;
}

export const EmailForm = () => {
    const contactModalStore = useContext(ContactModalContext);
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);

    const location = useLocation();
    const isConfigurator = location.pathname.includes(AppRoutes.configurator);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<EmailFormType>();

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: EmailFormType) => {
        const languageCode = localStorage.getItem(LocalStorageKey.LANGUAGE)?.toUpperCase() || 'en';
        try {
            setIsLoading(true);
            if (isConfigurator) {
                await configuratorStore.saveConfiguration();
                await checkoutStore.hubspotEventTrack(
                    hubspotEvents.BIKE_PRECONFIGURATION_CREATE,
                    configuratorStore.shareableID,
                );
            }
            await contactModalStore.sendForm({
                ...data,
                configurationSid: configuratorStore.shareableID,
                channel: process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL,
                languageCode,
            });
            contactModalStore.successScreenMessage = t(`successScreenMessage`, { email: data.email });
            contactModalStore.currentScreen = ContactModalScreen.SUCCESS;
            window.dataLayer.push({
                event: gtmEvents.CONTACT_FORM_EMAIL,
                option: 'EMAIL',
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                message: data.message,
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
            <div className={styles.inputWrapper}>
                <Input
                    label={t('contactFormName')}
                    {...register('firstName', { required: t('fieldRequired') })}
                    error={errors.firstName?.message}
                />
                <Input
                    label={t('contactFormLastName')}
                    {...register('lastName', { required: t('fieldRequired') })}
                    error={errors.lastName?.message}
                />
                <Input
                    label={t('contactFormEmail')}
                    {...register('email', {
                        required: t('fieldRequired'),
                        pattern: {
                            value: EMAIL_REGEX,
                            message: t('emailIncorrect'),
                        },
                    })}
                    error={errors.email?.message}
                />
                <div>
                    <Label title={t('phoneTitle')} className={styles.label} />
                    <Controller
                        name="phone"
                        control={control}
                        rules={{
                            pattern: {
                                value: PHONE_REGEX,
                                message: t('phoneNumberTooShort'),
                            },
                        }}
                        render={({ field: { value, onChange } }) => (
                            <PhoneInput
                                placeholder={t('phonePlaceholder')}
                                value={value}
                                onChange={onChange}
                                country={'ch'}
                                preferredCountries={['ch', 'de', 'at']}
                                inputStyle={{
                                    borderColor: errors.phone?.message && '#f54522',
                                }}
                                buttonStyle={{
                                    borderColor: errors.phone?.message && '#f54522',
                                }}
                            />
                        )}
                    />

                    {errors.phone?.message && <ErrorMessage message={errors.phone?.message} className={styles.error} />}
                </div>
                <Textarea
                    label={t('message')}
                    {...register('message', { required: t('fieldRequired') })}
                    error={errors.message?.message}
                />
            </div>

            <Button htmlType="submit" className={styles.button} isLoading={isLoading}>
                {t('sendRequest')}
            </Button>
        </form>
    );
};
