import styles from './OrderOverviewImage.module.scss';

type OrderOverviewImageProps = {
    image: string | false;
};
export const OrderOverviewImage: React.FC<OrderOverviewImageProps> = ({ image }) => {
    if (!image) return null;
    return (
        <div className={styles.bikeImageWrapper}>
            <img src={image.toString()} alt="Bike" className={styles.bikeImage} />
        </div>
    );
};
