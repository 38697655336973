import { Canvas } from '@react-three/fiber';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Loader } from 'shared/components/ui';
import { DeviceDetectorContext } from 'shared/providers/DeviceDetector';
import ConfiguratorStore from 'stores/Configurator.store';
import { Vector3 } from 'three';

import TurnOffLightImg from '../../../../assets/images/turnofflight.png';
import TurnOnLightImg from '../../../../assets/images/turnonlight.png';
import { getDatetime } from '../../../../shared/providers/utils';
import { gtmEvents } from '../../../../shared/types/gtmEvents';
import { hubspotEvents } from '../../../../shared/types/hubspotEvents';
import CheckoutStore from '../../../../stores/Checkout.store';
import { ARButton } from '../AR/ARButton';
import CameraControls from '../CameraControls/CameraControls';
import { ConfiguratorControls } from '../ConfiguratorControls/ConfiguratorControls';
import Model from '../Model/Model';
import { OverlayCanvas } from '../OverlayCanvas/OverlayCanvas';

import './Bike3D.modules.scss';
import { ConfiguratorLoader } from './ConfiguratorLoader';

type Bike3DType = {
    initCanvasConfig?: {
        orthographic?: boolean;
        shadows?: boolean;
        orbitControls: {
            enableZoom: boolean;
            enablePan: boolean;
            enableRotate: boolean;
            minPolarAngle: number;
            maxPolarAngle: number;
        };
    };
    radius?: number;
    modelData?: any;
};

export const Bike3D: FC<Bike3DType> = observer(
    ({
        initCanvasConfig = {
            orthographic: true,
            shadows: true,
            orbitControls: {
                enableZoom: true,
                enablePan: true,
                enableRotate: true,
                minPolarAngle: -Math.PI,
                maxPolarAngle: Math.PI,
            },
        },
        radius = 0,
        modelData,
    }) => {
        const store = useContext(ConfiguratorStore);
        const checkoutStore = useContext(CheckoutStore);
        const { device } = useContext(DeviceDetectorContext);
        const isMobile = device === 'small-tablet' || device === 'mobile';

        const cameraControlsRef = useRef<{ reset: (isAnimate: boolean) => any; enabled: boolean }>(null);
        const modelRef = useRef<any>(null);
        const [isLightOn, setIsLightOn] = useState<boolean>(true);
        const [progress, setProgress] = useState(0);
        let pr = 0;

        function onCloseLoading() {
            setTimeout(() => {
                const loadingScreenEl = document.getElementById('loadingScreen');
                const backdrop = document.getElementById('config-backdrop');
                if (loadingScreenEl) {
                    loadingScreenEl.style.opacity = '0';
                    setTimeout(() => {
                        loadingScreenEl.style.display = 'none';
                        store.loadingEnded = true;
                    }, 1500);
                }
                if (backdrop) backdrop.style.display = 'none';
                triggerAnimation();
            }, 1000);
        }

        function onSelectView(viewType: string) {
            if (modelRef.current[viewType]) {
                modelRef.current[viewType]();
            }
        }

        function triggerAnimation() {
            setTimeout(() => {
                modelRef?.current?.onAnimationBegin();
            }, 500);

            setTimeout(() => {
                store.openOverlayCanvas();
            }, 3000);
        }

        const loadingProgress = (p: any) => {
            if (p > pr) {
                pr = p;
                setProgress(p);
            }
        };

        useEffect(() => {
            if (checkoutStore.takeScreenshot || store.takeScreenshot) {
                setTimeout(() => {
                    setIsLightOn(true);
                    onSelectView('left');
                }, 100);
            }
        }, [checkoutStore.takeScreenshot, store.takeScreenshot]);

        const setLightsOn = () => {
            setIsLightOn(!isLightOn);

            window.dataLayer.push({
                event: isLightOn ? gtmEvents.BIKE_LIGHTS_OFF : gtmEvents.BIKE_LIGHTS_ON,
            });
        };

        return (
            <>
                <ConfiguratorLoader progress={progress} image={store.selectedBike?.media[0]?.url} />
                {store.configuratorSizeLoading && (
                    <div className="sizes-loader">
                        <Loader />
                    </div>
                )}

                {!(checkoutStore.takeScreenshot || store.takeScreenshot) && (
                    <>
                        <ConfiguratorControls setView={view => onSelectView(view)} />

                        <div className="control-screen__btns">
                            <div className="control-screen__btn mt-1" onClick={() => setLightsOn()}>
                                <img
                                    className="control-screen__btn-img"
                                    src={isLightOn ? TurnOnLightImg : TurnOffLightImg}
                                    alt="light"
                                />
                            </div>
                            <ARButton
                                sendHubspotEvent={async () =>
                                    await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_AR_VIEW, getDatetime())
                                }
                            />
                        </div>
                    </>
                )}

                {!isMobile && <OverlayCanvas overlayId="controlsScreen" />}
                {(checkoutStore.screenshot || store.screenshot) && <div className="flash" id="flashed" />}
                <Canvas
                    id="bikeCanvasId"
                    orthographic={initCanvasConfig.orthographic}
                    shadows={initCanvasConfig.shadows}
                    camera={{ zoom: 1, fov: 80, position: new Vector3(0, 0, -1), near: -500, far: 500 }}
                    style={{
                        borderRadius: radius ? radius : '0',
                        pointerEvents: checkoutStore.takeScreenshot || store.takeScreenshot ? 'none' : 'auto',
                    }}
                    gl={{ preserveDrawingBuffer: true, antialias: true }}
                    dpr={2}
                >
                    {!isLightOn && <color attach="background" args={['#393939']} />}
                    <CameraControls ref={cameraControlsRef} />
                    <Model
                        ref={modelRef}
                        modelData={modelData}
                        colorFrame={store.selectedBikeVariant}
                        myColor={store.selectedColors}
                        materialType={store.selectedColorType}
                        cameraControlsRef={cameraControlsRef}
                        onCloseLoading={onCloseLoading}
                        selectedProductTypeId={store.selectedProductTypeId}
                        isTurnOnLight={isLightOn}
                        selectedGroupType={store.selectedGroupType}
                        configuratorParts={store.configuratorParts}
                        loadingModelIds={store.loadingModelIds}
                        allVariants={store.selectedBike?.variants}
                        isMobile={isMobile}
                        loadingProgress={loadingProgress}
                        takingScreenshot={checkoutStore.takeScreenshot || store.takeScreenshot}
                    />
                </Canvas>
            </>
        );
    },
);
