import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'shared/components/ui';
import ConfiguratorStore from 'stores/Configurator.store';

import RotateImg from '../../../../assets/images/rotate.png';
import ZoomImg from '../../../../assets/images/zoom.png';

import './OverlayCanvas.module.scss';

type OverlayCanvasProps = {
    overlayId?: string;
};

export const OverlayCanvas: FC<OverlayCanvasProps> = observer(({ overlayId = 'controlsScreen' }) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const store = useContext(ConfiguratorStore);
    const { t } = useTranslation();
    const [hideOverlay, setHideOverlay] = useState<any>(
        localStorage.getItem('thoemusHideOverlay') === 'true' ? true : false,
    );

    useEffect(() => {
        localStorage.setItem('thoemusHideOverlay', hideOverlay);
    }, [hideOverlay]);

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
            return;
        }

        if (!store.isOpenOverlayCanvas) {
            closeControlScreen();
        } else {
            openControlScreen();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.isOpenOverlayCanvas]);

    useEffect(() => {
        return () => {
            store.closeOverlayCanvas();
        };
    }, [store]);

    function closeControlScreen() {
        const controlsScreenEl = document.getElementById(overlayId);
        if (controlsScreenEl) {
            controlsScreenEl.style.opacity = '0';

            setTimeout(() => {
                controlsScreenEl.style.display = 'none';
            }, 1200);
        }
    }

    function openControlScreen() {
        const controlsScreenEl = document.getElementById(overlayId);
        if (controlsScreenEl) {
            controlsScreenEl.style.display = 'flex';
            setTimeout(() => {
                controlsScreenEl.style.opacity = '1';
                // setTimeout(() => {
                //     closeControlScreen();
                // }, 4800);
            }, 400);
        }
    }
    useEffect(() => {
        if (store.isCheckout) setHideOverlay(true);
    }, [store.isCheckout]);

    if (hideOverlay) return null;

    return (
        <div id={overlayId} className="bike3d__loading-screen opacity-0 fade-bg" onClick={closeControlScreen}>
            <div className="loading-screen__items">
                <div className="controls-screen__group">
                    <img className="loading-screen__items-control" src={RotateImg} alt="rotate" />
                    <div className="main-desc">{t('dragAndDropFirstPart')}</div>
                    <div className="sub-desc">{t('dragAndDropSecondPart')}</div>
                </div>
                <div className="controls-screen__group">
                    <img className="loading-screen__items-control" src={ZoomImg} alt="zoom" />
                    <div className="main-desc">{t('scrollToZoomFirstPart')}</div>
                    <div className="sub-desc">{t('scrollToZoomSecondPart')}</div>
                </div>
                <div className="loading-screen__checkbox">
                    <Checkbox
                        label={t('hideOverlayText')}
                        onChange={e => setHideOverlay(e.target.checked)}
                        checked={hideOverlay}
                    />
                </div>
            </div>
        </div>
    );
});
