import { toJS } from 'mobx';
// eslint-disable-next-line import/order
import { observer } from 'mobx-react-lite';

import 'react-phone-input-2/lib/style.css';

import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { Button, Input, Label, Select } from 'shared/components/ui';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { EMAIL_REGEX, PHONE_REGEX } from 'shared/helpers/validators';
import { WarehouseType } from 'shared/types/checkout';
import CheckoutStore from 'stores/Checkout.store';
import ConfiguratorStore from 'stores/Configurator.store';

import styles from './PublicCheckoutForm.module.scss';

interface PublicCheckoutFormProps {
    id: string;
    close: () => void;
}

export const PublicCheckoutForm: React.FC<PublicCheckoutFormProps> = observer(({ id, close }) => {
    const checkoutStore = useContext(CheckoutStore);
    const configuratorStore = useContext(ConfiguratorStore);
    const { t } = useTranslation();
    const [isCreateOfferLoading, setIsCreateOfferLoading] = useState(false);

    const [step, setStep] = useState<'form' | 'success' | 'error'>('form');

    const {
        register,
        handleSubmit,
        control,

        formState: { errors },
    } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            email: localStorage.getItem(LocalStorageKey.EMAIL) || '',
            phone: '',
        },
    });
    useEffect(() => {
        if (id && !checkoutStore.data) {
            checkoutStore.getCheckout(id);
        }
    }, [id, checkoutStore]); //eslint-disable-line

    const createOffer = async () => {
        try {
            const expertCheckoutPaymentGateway = checkoutStore.data?.availablePaymentGateways?.find(
                gateway => gateway.id === 'mirumee.payments.dummy',
            );

            if (!expertCheckoutPaymentGateway) return;

            await checkoutStore.createPayment(expertCheckoutPaymentGateway.id, true);
            const response = await checkoutStore.checkoutComplete(checkoutStore.data?.id, true);

            window.dataLayer.push({
                event: 'Checkout',
                action: 'Next',
                category: 'Create offer',
                label: checkoutStore.productData?.name,
            });
            if (response.errors.length > 0) {
                setStep('error');
            } else {
                setStep('success');
            }
        } catch (error) {
            console.error(error);
            setStep('error');
        } finally {
            setIsCreateOfferLoading(false);
        }
    };

    const proceedAsGuest = async (data: any) => {
        setIsCreateOfferLoading(true);

        try {
            if (!data?.email) {
                return;
            }

            if (data.phone) {
                data.phone = data.phone.includes('+') ? data.phone : `+${data.phone}`;
                if (checkoutStore.phoneValidationFailed) {
                    return;
                }
            }

            if (checkoutStore.data?.user !== null) {
                try {
                    await checkoutStore.disconnectUserFromCheckout();
                    checkoutStore.clearTokens();
                } catch (error) {
                    console.error('Error disconnecting user from checkout:', error);
                }
            }

            try {
                await checkoutStore.updateMail(data.email);
                if (data.email) {
                    localStorage.setItem(LocalStorageKey.EMAIL, data.email);
                }
            } catch (error) {
                console.error('Error updating email:', error);
                return; // Stop execution if updating email fails
            }

            try {
                if (!localStorage.getItem(LocalStorageKey.DEAL_ID)) {
                    await configuratorStore.hubspotDealCreate('', id);
                } else {
                    await checkoutStore.hubspotDealUpdate();
                }
            } catch (error) {
                console.error('Error updating HubSpot deal:', error);
            }

            if (data.phone) {
                try {
                    await checkoutStore.updatePhone(data.phone);
                } catch (error) {
                    console.error('Error updating phone:', error);
                }
            }
            await pickUpUpdate();

            await createOffer();
        } catch (error) {
            console.error('Unexpected error in proceedAsGuest:', error);
        }
    };

    const availableCollectionPoints = toJS(checkoutStore.data?.availableCollectionPoints) ?? [];
    const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseType | undefined>(availableCollectionPoints[0]);

    const pickUpUpdate = async () => {
        try {
            if (selectedWarehouse) {
                await checkoutStore.setDeliveryMethod(selectedWarehouse?.id);
            }
            await checkoutStore.hubspotDealUpdate();
        } catch (error) {
            console.error(error);
        }
    };
    const formContent = {
        form: (
            <form className={styles.form} onSubmit={handleSubmit(proceedAsGuest)}>
                <h4>{t('publicCheckoutPopup.title')}</h4>
                <p>{t('publicCheckoutPopup.description')}</p>

                <div className={styles.inputs}>
                    <div>
                        <Input
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: t('checkoutEmailError'),
                                },
                            })}
                            className={styles.input}
                            label={t('checkoutEmailLabel')}
                            placeholder={t('checkoutEmailPlaceholder')}
                            labelWrapperClassName={styles.labelWrapper}
                            autoFocus
                            error={errors?.email as any}
                        />
                        {errors?.email && <div className={styles.error}>{t('checkoutEmailError')}</div>}
                    </div>
                    <div>
                        <Label title={t('delivery_pickup')} className={styles.labelWrapper} />
                        <Select<WarehouseType>
                            value={selectedWarehouse}
                            options={availableCollectionPoints}
                            getOptionLabel={option => option.name}
                            onChange={async value => {
                                setSelectedWarehouse(value as WarehouseType);
                            }}
                        />
                    </div>

                    <div>
                        <Label
                            title={t('phoneNumberTitle')}
                            tooltipText={t('phoneNumberTooltip')}
                            className={styles.labelWrapper}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            rules={{
                                required: true,
                                pattern: {
                                    value: PHONE_REGEX,
                                    message: t('phoneRequiresDigits'),
                                },
                            }}
                            render={({ field: { value, onChange } }) => (
                                <PhoneInput
                                    containerClass={styles.phoneInput}
                                    placeholder={t('phonePlaceholder')}
                                    value={value}
                                    onChange={onChange}
                                    country={'ch'}
                                    preferredCountries={['ch', 'de', 'at']}
                                    dropdownStyle={{
                                        marginBottom: '30px',
                                    }}
                                />
                            )}
                        />
                        {errors?.phone && <div className={styles.error}>{t('phoneRequiresDigits')}</div>}
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button htmlType="submit" className={styles.next} isLoading={isCreateOfferLoading}>
                        {t('checkoutLoginNext')}
                    </Button>
                </div>
            </form>
        ),
        success: (
            <div className={styles.responsePopup}>
                <h4>{t('publicCheckoutPopup.successTitle')}</h4>

                <Button className={styles.next} onClick={close}>
                    {t('publicCheckoutPopup.successButton')}
                </Button>
            </div>
        ),
        error: (
            <div className={styles.responsePopup}>
                <h4>{t('publicCheckoutPopup.errorTitle')}</h4>

                <Button className={styles.next} onClick={close}>
                    {t('publicCheckoutPopup.errorButton')}
                </Button>
            </div>
        ),
    };
    return <div>{formContent[step]}</div>;
});
