import { PRODUCT_LIGHT } from 'api/queries/configurator';
import bike from 'assets/images/preconfiguration_img.png';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useUpdateCheckoutMetadata } from 'pages/Configurator/hooks/useUpdateCheckoutMetadata';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { Button, Icon, Modal } from 'shared/components/ui';
import { DiscountForm } from 'shared/components/ui/DiscountForm/DiscountForm';
import { Loader } from 'shared/components/ui/Loader/Loader';
import { NotesForm } from 'shared/components/ui/NotesForm/NotesForm';
import { SalesPersonForm } from 'shared/components/ui/SalesPersonForm/SalesPersonForm';
import { useHubspotDealCreateUpdate } from 'shared/hooks/useHubspotHook';
import { apolloClient } from 'shared/lib/apollo';
import { localizePrice } from 'shared/providers/utils';
import CheckoutStore from 'stores/Checkout.store';
import ConfiguratorStore from 'stores/Configurator.store';

import { InstalmentsModal } from '../../../../shared/components/ui/InstalmentsModal/InstalmentsModal';
import { LocalStorageKey } from '../../../../shared/helpers/localStorage';
import { gtmEvents } from '../../../../shared/types/gtmEvents';
import { hubspotEvents } from '../../../../shared/types/hubspotEvents';
import { PublicCheckoutForm } from '../PublicCheckoutForm/PublicCheckoutForm';
import { QuantityCheckPopup } from '../QuantityCheckPopup/QuantityCheckPopup';
import { SummaryDrawer } from '../SummaryDrawer/SummaryDrawer';
import { UserExpertCheckoutForm } from '../UserExpertCheckoutForm/UserExpertCheckoutForm';

import styles from './MobileTabletSidebar.module.scss';
import { Section } from './Section/Section';

type SectionsType = 'colors' | 'sizes' | 'sitting position' | 'preconfiguration' | '';

type MobileSidebarProps = {};

export const MobileTabletSidebar: FC<MobileSidebarProps> = observer(() => {
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);

    const [isSectionOpened, setIsSectionOpened] = useState(false);
    const [activeSection, setActiveSection] = useState<SectionsType>('');
    // const [isTablet, setIsTablet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSummary, setOpenSummary] = useState(false);
    const [instalmentsModal, setInstalmentsModal] = useState(false);
    const [clientPopup, setClientPopup] = useState('');
    const [quantityCheckPopup, setQuantityCheckPopup] = useState(false);
    const [updatedPartsCheck, setUpdatedPartsCheck] = useState<any[]>([]);
    const [posPopup, setPosPopup] = useState(false);
    const isPos = process.env.REACT_APP_POS === 'true';
    const [localActiveColorId, setLocalActiveColorId] = useState('');
    const [discount, setDiscount] = useState<any>({
        percentage: null,
        chf: null,
        discount: null,
    });
    const [showNotesPopup, setShowNotesPopup] = useState(false);
    const [notes, setNotes] = useState({
        internal_note: '',
        external_note: '',
    });

    const [showDiscountPopup, setShowDiscountPopup] = useState(false);
    const [eintausch, setEintausch] = useState(true);

    const configPrice = localizePrice(configuratorStore.totalPrice / 24);

    const { t } = useTranslation();
    const navigator = useNavigate();
    const checkoutID = localStorage.getItem(LocalStorageKey.CHECKOUT_ID);

    const [updateCheckoutMetadata] = useUpdateCheckoutMetadata();
    const [createOrUpdateDeal] = useHubspotDealCreateUpdate();

    const filteredVariants = configuratorStore.selectedBike?.variants.filter((variant: any) =>
        variant.metadata?.some(
            (meta: any) =>
                meta?.key === 'size' &&
                meta?.value === (configuratorStore?.selectedSize?.name || configuratorStore?.selectedSize),
        ),
    );

    // useEffect(() => {
    //     const userAgent = window.navigator.userAgent;
    //     if (userAgent.match(/iPhone/)) {
    //         setIsTablet(true);
    //     }
    // }, []);

    const fetchPartsData = async () => {
        try {
            setLoading(true);
            const selectedPartIds = configuratorStore.selectedParts.map((part: any) => part.id);

            if (selectedPartIds.length === 0) {
                setLoading(false);
                return;
            }

            const responses = await Promise.all(
                selectedPartIds.map(productId =>
                    apolloClient.query({
                        query: PRODUCT_LIGHT,
                        variables: {
                            productId,
                            channel: process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL,
                            bikeProductId: configuratorStore.selectedBike?.id,
                        },
                    }),
                ),
            );

            const allParts = responses.map(response => response.data);
            const filteredParts = allParts.filter(
                (part: any) => part.product?.variants?.[0]?.stockTrafficColor === 'red',
            );
            setUpdatedPartsCheck(filteredParts);
            if (filteredParts?.length > 0) {
                setQuantityCheckPopup(true);
            } else {
                createScreenshot();
            }
        } catch (error) {
            console.error('Error fetching parts:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        async function goToCheckout() {
            try {
                setLoading(true);
                configuratorStore.listenForNavigationChange = false;
                if (checkoutID) {
                    await checkoutStore.updateCheckoutLines(
                        configuratorStore.selectedParts,
                        configuratorStore.selectedColors,
                        configuratorStore.selectedBikeVariant,
                        checkoutID,
                    );
                } else {
                    await checkoutStore.createCheckout(
                        configuratorStore.selectedParts,
                        configuratorStore.selectedColors,
                        configuratorStore.selectedBikeVariant,
                    );
                }
                const id = checkoutStore.data?.id;
                if (!id) {
                    return console.error('No checkout ID.');
                }
                await checkoutStore.saveScreenshot(id);
                if (!configuratorStore.shareableID) {
                    await configuratorStore.saveConfiguration(true);
                }
                await updateCheckoutMetadata();
                configuratorStore.configurationExists = true;

                localStorage.setItem(LocalStorageKey.CHECKOUT_ID, id);

                window.dataLayer.push({
                    event: gtmEvents.CHECKOUT_CREATE,
                });

                // await checkoutStore.sendDataToCustomerIo(gtmEvents.CHECKOUT_CREATE);
                // Replace the configurator URL with the one including SID
                // So if user uses browsers back arrow it still will load the latest configuration he created
                const SID = checkoutStore.data?.metadata.find(d => d.key === 'sid')?.value;
                await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_PRECONFIGURATION_CREATE, SID!);
                navigator(`${AppRoutes.configurator}/${SID}`, { replace: true });
                setClientPopup(id);

                // navigator(AppRoutes.checkout.replace(':id', id));
                // navigator(AppRoutes.serviceUpgrade);
                // navigator(AppRoutes.checkout.replace(':id', id));
            } catch (error) {
                console.error('error', error);
            } finally {
                setLoading(false);
            }
        }

        if (checkoutStore.screenshot && !isPos) {
            goToCheckout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutStore.screenshot]);

    const createScreenshot = async () => {
        setLoading(true);
        checkoutStore.createScreenshot();
    };

    const openSections = (name: any) => {
        setIsSectionOpened(!isSectionOpened);
        setActiveSection(name);
    };

    const getColor = () => {
        if (configuratorStore.selectedBike?.id) {
            const variant = configuratorStore.selectedBike?.variants?.find(
                (variant: any) => variant.id === configuratorStore.selectedBikeVariant?.id,
            );
            return variant?.attributes[0]?.values[0]?.value;
        }
    };

    const setColor = async (color: any, hasCustomColor: boolean = false) => {
        configuratorStore.selectedBikeVariant = {
            id: color.id,
            color: color.value,
            colorName: color.colorName,
            weight: color.weight,
        };
        setLocalActiveColorId(color.id);
        if (!hasCustomColor) {
            configuratorStore.selectedColors = [];
        }
        window.dataLayer.push({
            event: gtmEvents.BIKE_COLOR,
            category: configuratorStore.bike,
            bikeModel: configuratorStore.selectedBike.name,
            color: color.colorName,
            hexColor: color.value,
        });

        await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_COLOR_SELECTED, color.colorName);
        await createOrUpdateDeal();
    };
    useEffect(() => {
        if (!configuratorStore.selectedSize || !filteredVariants || filteredVariants.length === 0) return;

        if (configuratorStore.loadingEnded) {
            const localSelectedVariant = filteredVariants.find((variant: any) => variant.id === localActiveColorId);
            const preselectedVariant = filteredVariants.find(
                (variant: any) =>
                    variant.attributes[0]?.values[0]?.name === configuratorStore.selectedBikeVariant?.colorName,
            );

            const selectedVariant = localSelectedVariant || preselectedVariant;

            if (selectedVariant) {
                setColor(
                    {
                        id: selectedVariant?.id,
                        value: selectedVariant?.attributes[0]?.values[0]?.value,
                        colorName: selectedVariant?.attributes[0]?.values[0]?.name,
                        weight: selectedVariant?.weight?.value,
                    },
                    configuratorStore.hasSelectedColors,
                );
            }
        }
    }, [configuratorStore.selectedSize, configuratorStore.loadingEnded, localActiveColorId]); //eslint-disable-line

    const setIsCheckout = (val: boolean) => {
        // setOpenSummary(val);
        configuratorStore.setIsCheckout(val);
    };
    const isCheckout = configuratorStore.isCheckout || false;

    const weiterFunction = () => {
        if (isPos) {
            setPosPopup(true);
        } else {
            fetchPartsData();
        }
    };
    useEffect(() => {
        if (discount.percentage) {
            setDiscount({
                percentage: discount?.percentage,
                chf: configuratorStore.totalPrice - configuratorStore.totalPrice * (discount?.percentage / 100),
                discount: configuratorStore.totalPrice * (discount?.percentage / 100),
            });

            checkoutStore.updateExpertCheckoutData({
                discount_percent: discount?.percentage,
                discount_amount: configuratorStore.totalPrice * (discount?.percentage / 100),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuratorStore.totalPrice]);

    useEffect(() => {
        configuratorStore.discountPercentage = discount.percentage;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discount]);

    return (
        <div className={styles.mobile_tablet_sidebar}>
            {configuratorStore.configuratorPageLoading ? (
                <div className={styles.spinner}>
                    <Loader />
                </div>
            ) : (
                <>
                    <div className={clsx(styles.container, { [styles.big]: openSummary })}>
                        <div className={styles.price_container}>
                            <div className={styles.initial_container}>
                                <div
                                    className={clsx(
                                        styles.color,
                                        configuratorStore.hasSelectedColors && styles.rainbow_img,
                                    )}
                                    style={{ backgroundColor: !configuratorStore.hasSelectedColors && getColor() }}
                                    onClick={() => openSections('colors')}
                                >
                                    <div className={styles.absolute_item}>
                                        <Icon name="palette" />
                                    </div>
                                </div>

                                {configuratorStore.sizes.length > 1 && (
                                    <div className={styles.item} onClick={() => openSections('sizes')}>
                                        <h5>{configuratorStore.selectedSize.name || configuratorStore.selectedSize}</h5>
                                    </div>
                                )}

                                {configuratorStore.sittingPositions?.length > 1 && (
                                    <div className={styles.item} onClick={() => openSections('sitting position')}>
                                        <h6>
                                            {t(
                                                `sittingPosition${
                                                    configuratorStore.selectedSittingPosition?.name ||
                                                    configuratorStore.selectedSittingPosition
                                                }`,
                                            )}
                                        </h6>
                                    </div>
                                )}

                                {configuratorStore.preconfigurationsList?.length > 1 && (
                                    <div className={styles.item} onClick={() => openSections('preconfiguration')}>
                                        <img src={bike} alt="thoemus bike" />
                                        <h6>{t('sidebarPreconfiguration')}</h6>
                                        <div className={styles.absolute_item}>
                                            <h6>{configuratorStore.preconfigurationsList?.length}</h6>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.buttonContainer}>
                            <div className={styles.price_wrapper}>
                                <div className={styles.total_price}>
                                    {openSummary ? (
                                        <div className={styles.your_price_wrapper}>
                                            <h6>{t('priceStartingFrom')}</h6>
                                            <Icon
                                                name="question"
                                                className={styles.question_mark}
                                                onClick={() => setInstalmentsModal(true)}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={clsx(styles.show_summary, openSummary && styles.hide)}
                                            onClick={() => setOpenSummary(true)}
                                        >
                                            <h6>{t('showSummary')}</h6>
                                            <Icon name="arrow_down" className={styles.icon} />
                                        </div>
                                    )}

                                    <h6>
                                        {configuratorStore.selectedBike?.configuratorPrice?.currency}{' '}
                                        {parseInt(configPrice)}
                                        <span className={styles.per_month}> / {t('month')}</span>
                                    </h6>
                                </div>
                                <Button
                                    type="primaryDark"
                                    preIcon="cart"
                                    className={styles.button}
                                    onClick={weiterFunction}
                                    isLoading={loading}
                                    loaderClassName={styles.btn_loader}
                                    loadingText={t('sidebarLoadingImage')}
                                >
                                    {t('sidebarBuyButton')}
                                </Button>
                            </div>

                            {openSummary && (
                                <>
                                    <div className={styles.includes_tax}>
                                        <h6>{t('totalPriceIncludeTax')}</h6>
                                        <h6>
                                            {configuratorStore.selectedBike?.configuratorPrice?.currency}{' '}
                                            {localizePrice(configuratorStore.totalPrice)}
                                        </h6>
                                    </div>
                                    {openSummary && isPos && (
                                        <div className={styles.discountSection}>
                                            {discount?.percentage > 0 && (
                                                <>
                                                    <h6 className={styles.line}>
                                                        {t('discountForm.discountPercentage')}{' '}
                                                        <div>{discount.percentage}%</div>
                                                    </h6>
                                                    <h6 className={styles.line}>
                                                        {t('discountForm.netPrice')} (inkl. MWSt)
                                                        <div>CHF {localizePrice(discount.chf)}</div>
                                                    </h6>
                                                </>
                                            )}
                                            {eintausch && (
                                                <h6 className={styles.line}>
                                                    {t('discountForm.tradeIn')}
                                                    <div>{t('discountForm.yes')}</div>
                                                </h6>
                                            )}
                                            <div className={styles.actions}>
                                                <div className={styles.row}>
                                                    <Button
                                                        type={eintausch ? 'secondary' : 'primary'}
                                                        className={styles.discountButton}
                                                        onClick={() => setEintausch(!eintausch)}
                                                        isDisabled={loading || !configuratorStore.loadingEnded}
                                                    >
                                                        {eintausch ? '-' : '+'} {t('discountForm.tradeIn')}
                                                    </Button>

                                                    <Button
                                                        type={
                                                            discount.percentage || showDiscountPopup
                                                                ? 'secondary'
                                                                : 'primary'
                                                        }
                                                        className={styles.discountButton}
                                                        onClick={() => setShowDiscountPopup(true)}
                                                        isDisabled={loading || !configuratorStore.loadingEnded}
                                                    >
                                                        + {t('discountForm.specialCondition')}
                                                    </Button>
                                                </div>

                                                <Button
                                                    type={
                                                        notes.external_note || notes.internal_note || showNotesPopup
                                                            ? 'secondary'
                                                            : 'primary'
                                                    }
                                                    className={styles.discountButton}
                                                    onClick={() => setShowNotesPopup(true)}
                                                    isDisabled={loading || !configuratorStore.loadingEnded}
                                                >
                                                    {notes.external_note || notes.internal_note || showNotesPopup
                                                        ? '- '
                                                        : '+ '}
                                                    {t('notesForm.button')}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <SummaryDrawer
                        open={openSummary}
                        onClose={() => setOpenSummary(false)}
                        className={clsx({
                            [styles.summary]: isPos && openSummary,
                            [styles.zero]: !discount.percentage,
                            [styles.tradeIn]: eintausch,
                        })}
                    />
                    {instalmentsModal && (
                        <InstalmentsModal open={instalmentsModal} onClose={() => setInstalmentsModal(false)} />
                    )}
                    {activeSection === 'colors' && filteredVariants?.length > 0 && (
                        <Section
                            isOpen={isSectionOpened}
                            onClose={() => setIsSectionOpened(false)}
                            title={t('sidebarFrameColorTitle')}
                            setValue={async (color: any) => {
                                color?.hasTexture > 0
                                    ? setColor({
                                          id: color.id,
                                          colorName: color.colorName,
                                          value: null,
                                          weight: color.weight,
                                      })
                                    : setColor(color);
                            }}
                            data={configuratorStore?.selectedBike?.id && filteredVariants}
                        />
                    )}

                    {activeSection === 'sitting position' && configuratorStore.sittingPositions?.length > 1 && (
                        <Section
                            isOpen={isSectionOpened}
                            onClose={() => setIsSectionOpened(false)}
                            title={t('sittingPosition')}
                            setValue={async data => {
                                configuratorStore.selectedSittingPosition = data;

                                window.dataLayer.push({
                                    event: gtmEvents.BIKE_SITTING_POSITION,
                                    sittingPosition: data.name,
                                    category: configuratorStore.bike,
                                    bikeModel: configuratorStore.selectedBike.name,
                                });
                                // await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_SITTING_POSITION, data.name);
                                await checkoutStore.hubspotEventTrack(
                                    hubspotEvents.BIKE_SITTING_POSITION_SELECTED,
                                    data.name,
                                );
                            }}
                            activeValue={
                                configuratorStore.selectedSittingPosition?.name ||
                                configuratorStore.selectedSittingPosition
                            }
                            data={configuratorStore.sittingPositions}
                        />
                    )}

                    {activeSection === 'sizes' && configuratorStore.sizes.length > 1 && (
                        <Section
                            isOpen={isSectionOpened}
                            onClose={() => setIsSectionOpened(false)}
                            title={t('sidebarFrameSizeTitle')}
                            setValue={async data => {
                                configuratorStore.selectedSize = { name: data.name, id: data.id };

                                window.dataLayer.push({
                                    event: gtmEvents.BIKE_SIZE,
                                    size: data.name,
                                    category: configuratorStore.bike,
                                    bikeModel: configuratorStore.selectedBike.name,
                                });

                                // await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_SIZE, data.name);
                                await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_SIZE_SELECTED, data.name);
                            }}
                            activeValue={configuratorStore.selectedSize.name ?? configuratorStore.selectedSize}
                            data={configuratorStore.sizes}
                        />
                    )}

                    {activeSection === 'preconfiguration' && (
                        <Section
                            isOpen={isSectionOpened}
                            onClose={() => setIsSectionOpened(false)}
                            title={t('sidebarPreconfigurationsTitle')}
                            setValue={async data => {
                                configuratorStore.setPreconfigurationId(data.id);

                                window.dataLayer.push({
                                    event: gtmEvents.BIKE_PRECONFIGURATION,
                                    preconfiguration: data.title,
                                });

                                // await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_PRECONFIGURATION, data.title);
                            }}
                            activeValue={configuratorStore.preconfigurationId}
                            data={configuratorStore.preconfigurationsList}
                        />
                    )}
                </>
            )}

            {isPos && (
                <>
                    <Modal
                        isOpen={showDiscountPopup}
                        onClose={() => setShowDiscountPopup(false)}
                        maxWidth="600px"
                        className={styles.modal}
                    >
                        <DiscountForm
                            defaultValues={{
                                percentage: discount.percentage,
                                chf: discount.chf,
                                discount: discount.discount,
                            }}
                            onSuccess={val => {
                                setDiscount({
                                    percentage: val?.percentage ?? 0,
                                    chf: val?.chf ?? configuratorStore.totalPrice,
                                    discount: val.discount ?? 0,
                                });
                                checkoutStore.updateExpertCheckoutData({
                                    is_trade_in: eintausch,
                                    discount_percent: val?.percentage,
                                    discount_amount: configuratorStore.totalPrice - val?.chf,
                                });
                                setShowDiscountPopup(false);
                            }}
                        />
                    </Modal>
                    <Modal
                        isOpen={showNotesPopup}
                        onClose={() => setShowNotesPopup(false)}
                        maxWidth="600px"
                        className={styles.modal}
                    >
                        <NotesForm
                            defaultValues={notes}
                            onSuccess={val => {
                                setNotes(val);
                                checkoutStore.updateExpertCheckoutData({
                                    internal_note: val?.internal_note,
                                    external_note: val?.external_note,
                                });
                                setShowNotesPopup(false);
                            }}
                        />
                    </Modal>
                    <Modal
                        isOpen={posPopup}
                        onClose={() => setPosPopup(false)}
                        maxWidth="600px"
                        className={styles.modal}
                    >
                        <SalesPersonForm
                            onSuccess={() => {
                                setPosPopup(false);
                                setIsCheckout(true);
                            }}
                        />
                    </Modal>
                    <Modal
                        isOpen={isCheckout}
                        onClose={() => setIsCheckout(false)}
                        maxWidth="600px"
                        className={styles.modal}
                    >
                        <UserExpertCheckoutForm />
                    </Modal>
                </>
            )}

            {!isPos && (
                <>
                    <Modal
                        isOpen={quantityCheckPopup}
                        onClose={() => setQuantityCheckPopup(false)}
                        maxWidth="600px"
                        className={styles.modal}
                    >
                        <QuantityCheckPopup
                            data={updatedPartsCheck}
                            close={() => setQuantityCheckPopup(false)}
                            onSuccess={() => {
                                setQuantityCheckPopup(false);
                                createScreenshot();
                            }}
                        />
                    </Modal>
                    <Modal
                        isOpen={!!clientPopup}
                        onClose={() => setClientPopup('')}
                        maxWidth="600px"
                        className={styles.modal}
                    >
                        <PublicCheckoutForm id={clientPopup} close={() => setClientPopup('')} />
                    </Modal>
                </>
            )}
        </div>
    );
});
