import clsx from 'clsx';
import React, { FC, useContext } from 'react';
import { DeviceDetectorContext } from 'shared/providers/DeviceDetector';
import { ColorType } from 'shared/types/configurator';

import { Tooltip } from '../../../../shared/components/ui';

import styles from './Color.module.scss';

type ColorProps = {
    color: ColorType | any;
    setSelectedColor: (color: ColorType) => void;
    colorSelected: boolean | null;
    className?: string;
    lightMode?: boolean;
    smallerColor?: boolean;
};

export const Color: FC<ColorProps> = ({
    color,
    setSelectedColor,
    colorSelected,
    smallerColor,
    className,
    lightMode = false,
}) => {
    const { device } = useContext(DeviceDetectorContext);
    const isMobile = device === 'tablet' || device === 'small-tablet' || device === 'mobile';

    // Hardcode color to red for Lightrider R3 WC
    if (color?.colorName?.toLowerCase() === 'worldcup team') {
        color.value = '#C80405';
    }

    if (isMobile) {
        return (
            <div
                key={color.id}
                className={clsx(
                    styles.color,
                    lightMode && styles.light_mode,
                    colorSelected && styles.active_color,
                    className,
                )}
                style={{
                    backgroundColor: colorSelected ? 'transparent' : color.value || color.code,
                }}
                onClick={() => setSelectedColor(color)}
            >
                {colorSelected && <span style={{ backgroundColor: color.value || color.code }} />}
            </div>
        );
    } else {
        return (
            <Tooltip label={color.colorName || color.name}>
                <div
                    key={color.id}
                    className={clsx(
                        styles.color,
                        lightMode && styles.light_mode,
                        colorSelected && styles.active_color,
                        smallerColor && styles.smaller_color,
                        className,
                    )}
                    style={{
                        backgroundColor: colorSelected ? 'transparent' : color.value || color.code,
                    }}
                    onClick={() => setSelectedColor(color)}
                >
                    {colorSelected && <span style={{ backgroundColor: color.value || color.code }} />}
                </div>
            </Tooltip>
        );
    }
};
