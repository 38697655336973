import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Icon, Loader } from 'shared/components/ui';
import CheckoutStore from 'stores/Checkout.store';

import styles from './CheckoutFileUpload.module.scss';

export const CheckoutFileUpload = observer(() => {
    const store = useContext(CheckoutStore);
    const [invalidErrors, setInvalidErrors] = useState<any>([]);
    const [tooLargeErrors, setTooLargeErrors] = useState<any>([]);
    const { t } = useTranslation();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result as string;
                const fileName = file.name;
                if (base64String) {
                    store.uploadCheckoutFile(base64String, fileName);
                }
            };
            reader.readAsDataURL(file);
        });
        store.hubspotDealUpdate();
        // eslint-disable-next-line
    }, []);

    const deleteFile = (id: string) => {
        store.deleteCheckoutFile(id);
    };

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': [],
            'audio/*': [],
            'video/mp4': ['.mp4'],
            'application/pdf': ['.pdf'],
        },
        maxSize: 104857600,
    });

    useEffect(() => {
        if (fileRejections && fileRejections.length > 0) {
            const tooLarge = fileRejections.filter(f => f.errors[0].code === 'file-too-large');
            const invalid = fileRejections.filter(f => f.errors[0].code === 'file-invalid-type');
            setTooLargeErrors(tooLarge);
            setInvalidErrors(invalid);
        }
    }, [fileRejections]);

    // Show errors for files
    const Errors = () => (
        <>
            <div className={styles.notSupported}>
                {invalidErrors.length > 0 && (
                    <>
                        {t('fileUploadFilesNotSupported')}{' '}
                        <span>{invalidErrors.map((f: any) => f.file.name).join(', ')}</span>
                    </>
                )}
            </div>
            <div className={styles.notSupported}>
                {tooLargeErrors.length > 0 && (
                    <>
                        {t('fileUploadTilesTooLarge')}{' '}
                        <span>{tooLargeErrors.map((f: any) => f.file.name).join(', ')}</span>
                    </>
                )}
            </div>
        </>
    );

    return (
        <div className={styles.fileUpload}>
            <div className={styles.dropZone} {...getRootProps()}>
                <input {...getInputProps()} />
                <Icon name="upload" />
                <div className={styles.title}>{t('fileUploadDragAndDrop')}</div>
                <div>{t('fileUploadBrowse')}</div>
                {store.fileUploadingProgress && <Loader className={styles.loader} />}
            </div>

            <Errors />

            <div className={styles.files}>
                {store.data?.files.map(file => (
                    <div className={styles.fileTag} key={file.id}>
                        {file.fileName} <Icon name="close" onClick={() => deleteFile(file.id)} />
                    </div>
                ))}
            </div>
        </div>
    );
});
