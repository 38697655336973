import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ContactCover } from '../ContactCover/ContactCover';

import { EmailForm } from './_components/EmailForm/EmailForm';
import styles from './ContactForm.module.scss';

export const ContactForm = observer(() => {
    const { t } = useTranslation();

    return (
        <div>
            <ContactCover />

            <div className={styles.body}>
                <h5>{t('howDoYouWantContact')}</h5>

                <EmailForm />
            </div>
        </div>
    );
});
