import { UserExpertFormValues } from 'pages/Configurator/_components/UserExpertCheckoutForm/UserExpertCheckoutForm';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import ConfiguratorStore from 'stores/Configurator.store';

import styles from './OrderOverviewProductUserData.module.scss';

export const OrderOverviewProductUserData = () => {
    const configuratorStore = useContext(ConfiguratorStore);
    const { selectedBike, selectedBikeVariant, selectedSize, selectedSittingPosition } = configuratorStore;
    const { t } = useTranslation();

    const savedJSON = localStorage.getItem(LocalStorageKey.USER_EXPERT_CHECKOUT);
    const savedValues: Partial<UserExpertFormValues> = savedJSON ? JSON.parse(savedJSON) : {};

    return (
        <div className={styles.productUserData}>
            <div className={styles.bikeData}>
                <h2 className={styles.title}>{selectedBike?.name}</h2>
                <p>
                    <span>{t('overviewPage.color')}:</span>
                    {` ${
                        configuratorStore.selectedColorType === 'glossy' && configuratorStore?.selectedColors?.[0]?.name
                            ? 'Custom-' + configuratorStore?.selectedColors?.[0]?.name
                            : selectedBikeVariant.colorName
                    }`}
                </p>
                <p>
                    <span>{t('overviewPage.size')}:</span>
                    {` ${selectedSize?.name || selectedSize}`}
                </p>
                <p>
                    <span>{t('overviewPage.sittingPosition')}:</span>
                    {` ${selectedSittingPosition}`}
                </p>
            </div>
            <div className={styles.userData}>
                <h3 className={styles.title}>{t('overviewPage.customer')}</h3>
                <p>{`${savedValues.firstName} ${savedValues.lastName}`}</p>
                <p>{`${savedValues.streetAddress1}`}</p>
                <p>{`${savedValues.postalCode}, ${savedValues.city}`}</p>
                <p>{`+${savedValues.phone}`}</p>
            </div>
        </div>
    );
};
