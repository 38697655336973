import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { SmartFit } from 'pages/Configurator/_components/SmartFit/SmartFit';
import { FC, useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CheckoutStore from '../../../../stores/Checkout.store';
import ConfiguratorStore from '../../../../stores/Configurator.store';
import { getDatetime } from '../../../providers/utils';
import { gtmEvents } from '../../../types/gtmEvents';
import { hubspotEvents } from '../../../types/hubspotEvents';
import { Icon } from '../Icon/Icon';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './Tags.module.scss';

type TagProps = {
    data: any[];
    title?: string;
    type: 'frame size' | 'all' | 'list';
    onSelect: any;
    className?: string;
    active?: any;
};

export const Tags: FC<TagProps> = observer(({ data, title, onSelect, type, className, active }) => {
    const store = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const { t } = useTranslation();
    const location = useLocation();
    const isPos = process.env.REACT_APP_POS === 'true';
    const filteredVariantsColor = useMemo(() => {
        return store.selectedBike?.variants.filter((variant: any) =>
            variant.attributes?.some(
                (attribute: any) => attribute?.values?.[0]?.name === store.selectedBikeVariant?.colorName,
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.selectedBike, store.selectedBikeVariant]);
    const [hoveredVariants, setHoveredVariants] = useState<any>({});

    const isConfiguratorPage = location.pathname.includes('/configurator');
    const tooltipBottom = store.sidebarExpanded && store.sidebarWidth > 400 && type === 'list' && isConfiguratorPage;

    const tooltipText = useMemo(() => {
        switch (type) {
            case 'frame size':
                const channel = process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL;
                const linkHref = channel === 'us-channel' ? 'https://www.thoemus.com' : 'https://www.thoemus.ch';
                return (
                    <Trans i18nKey="sidebarFrameSizeTooltip">
                        The right frame size? Use the icon on the right to determine the size, check the frame
                        geometries on our
                        <a href={linkHref} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                            Website
                        </a>
                        or ask our sales team.
                    </Trans>
                );
            case 'list':
                return <Trans i18nKey="sidebarSittingPositionTooltip" components={{ p: <p />, b: <b /> }} />;
            default:
                return '';
        }
    }, [type]);

    const selectSize = (sf: any) => {
        const normalizedSize = sf.displaySize01.endsWith('cm')
            ? sf.displaySize01.slice(0, -2).trim()
            : sf.displaySize01;

        const size = data.find(d => d.name.toUpperCase() === normalizedSize.toUpperCase());
        if (size) onSelect(size);
    };

    return (
        <div className={clsx(styles.tags_wrapper, className)}>
            {title && (
                <div className={styles.titleWrapper}>
                    <p
                        className={clsx(
                            styles.title,
                            store.sidebarWidth > 370 && store.sidebarExpanded && styles.smaller,
                        )}
                    >
                        {title}
                    </p>
                    {tooltipText && (
                        <Tooltip
                            label={tooltipText}
                            tooltipWrapperClassName={styles.tooltipWrapper}
                            tooltipClassName={clsx(styles.tooltip, tooltipBottom && styles.bottom)}
                        >
                            <Icon name="question" />
                        </Tooltip>
                    )}
                </div>
            )}
            <div className={styles.tags}>
                {type === 'all' && (
                    <div
                        className={clsx(styles.tag, active === 'all' && styles.active_tag)}
                        onClick={() => onSelect('all')}
                    >
                        <p className={styles.name}>{t('tagsAll')}</p>
                    </div>
                )}
                {data.map((item, index) => {
                    const tagKey = item.name || item;
                    const isActive = active === tagKey;
                    const stock = hoveredVariants?.approomStock?.stock;

                    const tagElement = (
                        <div
                            key={`${index}-${item.id}`}
                            className={clsx(
                                styles.tag,
                                isActive && styles.active_tag,
                                store.sidebarWidth > 370 && store.sidebarExpanded && styles.smaller,
                                store.sidebarWidth > 370 &&
                                    store.sidebarExpanded &&
                                    type === 'frame size' &&
                                    styles.smallerFrame,
                                'tag',
                            )}
                            onMouseEnter={() => {
                                const selectedVariant = filteredVariantsColor?.find((variant: any) =>
                                    variant.metadata.some((meta: any) => meta.value === item.name),
                                );
                                setHoveredVariants(selectedVariant);
                            }}
                            onClick={() => onSelect(item)}
                        >
                            {type === 'frame size' && (
                                <p className={clsx(styles.name, styles.uppercase)}>{item.name}</p>
                            )}
                            {type === 'list' && <p className={styles.name}>{t(`sittingPosition${item.name}`)}</p>}
                        </div>
                    );

                    if (stock != null && isPos) {
                        return (
                            <Tooltip key={tagKey} label={`Stock: ${stock}`}>
                                {tagElement}
                            </Tooltip>
                        );
                    }
                    return tagElement;
                })}
                {type === 'frame size' && (
                    <div
                        onClick={() => {
                            window.dataLayer.push({
                                event: gtmEvents.BIKE_SIZE_MODAL,
                            });
                            checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_SIZE_MODAL_TRIGGERED, getDatetime());
                        }}
                    >
                        <SmartFit
                            className={clsx(
                                styles.rullerTag,
                                store.sidebarWidth > 370 && store.sidebarExpanded && styles.smaller,
                            )}
                            product={store.selectedBike}
                            onConfirmSize={(a: any) => selectSize(a)}
                            smaller={store.sidebarWidth > 370 && store.sidebarExpanded}
                        />
                    </div>
                )}
            </div>
        </div>
    );
});
