import { useContext } from 'react';

import CheckoutStore from '../../stores/Checkout.store';
import ConfiguratorStore from '../../stores/Configurator.store';
import { LocalStorageKey } from '../helpers/localStorage';

export const useHubspotDealCreateUpdate = () => {
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);

    const createOrUpdateDeal = async () => {
        await configuratorStore.updateConfigurationParts();

        const email = localStorage.getItem(LocalStorageKey.EMAIL);
        const dealSid = localStorage.getItem(LocalStorageKey.DEAL_SID);
        const dealId = localStorage.getItem(LocalStorageKey.DEAL_ID);

        if (!email) return;

        if (dealId && dealSid) {
            return await checkoutStore.hubspotDealUpdate({
                amount: configuratorStore.totalPrice,
                sid: dealSid,
            });
        }

        if (configuratorStore.shareableID && dealId) {
            await checkoutStore.hubspotDealUpdate({
                amount: configuratorStore.totalPrice,
                sid: configuratorStore.shareableID,
            });
        } else {
            await configuratorStore.saveConfiguration(true);
        }
    };

    return [createOrUpdateDeal];
};
