import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import ConfiguratorStore from 'stores/Configurator.store';

import LogoImage from '../../../../assets/images/logo.svg';
import TwinnerLogo from '../../../../assets/images/twinner_logo.svg';

import styles from './Logo.module.scss';

export const Logo = observer(() => {
    const store = useContext(ConfiguratorStore);
    const color = process.env.REACT_APP_LOGO_COLOR;
    return (
        <div className={clsx(styles.logo, store.isTwinner && styles.twinner)} style={{ backgroundColor: color }}>
            <img src={store.isTwinner ? TwinnerLogo : LogoImage} alt="Logo" width={185} />
            {color && <div className={styles.label}>DEVELOPMENT</div>}
        </div>
    );
});
