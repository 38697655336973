import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMAIL_REGEX } from 'shared/helpers/validators';
import { gtmEvents } from 'shared/types/gtmEvents';
import ConfiguratorStore from 'stores/Configurator.store';

import CheckoutStore from '../../../../stores/Checkout.store';
import { getDatetime } from '../../../providers/utils';
import { hubspotEvents } from '../../../types/hubspotEvents';
import { Button } from '../Button/Button';
import { Chip } from '../Chip/Chip';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { Input } from '../Input/Input';
import { Modal } from '../Modal/Modal';

import styles from './ShareButton.module.scss';

type ShareModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const EMAILS_LIMIT = 5;

export const ShareModal: React.FC<ShareModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();

    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);

    const [recipientsList, setRecipientsList] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setFocus,
    } = useForm({});

    const {
        register: registerMain,
        handleSubmit: handleSubmitMain,
        formState: { errors: errorsMain },
    } = useForm({});

    const addEmail = (formData: any) => {
        const isInList = recipientsList.some((recipient: any) => recipient.email === formData.email);
        if (isInList) return;

        const list = recipientsList;
        list.push(formData);
        setRecipientsList(list);
        resetFields();
        setFocus('firstName');
    };

    const deleteEmail = (email: string) => {
        const list = recipientsList.filter((e: any) => e.email !== email);
        setRecipientsList(list);
    };

    const resetFields = () => {
        setValue('firstName', '');
        setValue('email', '');
    };

    const sendEmails = async (formData: any) => {
        setLoading(true);
        await configuratorStore.shareConfigurationToEmails(
            configuratorStore.configurationID,
            formData.senderEmail,
            formData.senderFirstName,
            recipientsList,
        );
        window.dataLayer.push({
            event: gtmEvents.BIKE_EMAIL_SHARE,
            emailList: recipientsList.map((e: any) => e.email).join(', '),
        });
        await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_EMAIL_SHARE, getDatetime());
        await checkoutStore.hubspotDealUpdate({
            configurationShared: true,
            amount: configuratorStore.totalPrice,
            sid: configuratorStore.shareableID,
        });
        setLoading(false);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} maxWidth={600}>
            <h3 className={styles.title}>{t('SharingSendPerEmail')}</h3>
            <p className={styles.subtitle}>{t('SharingSendPerEmailDescription')}</p>

            <form>
                <span className={styles.formLabel}>{t('SharingSenderLabel')}</span>
                <div className={styles.inputsRow}>
                    <div>
                        <Input
                            placeholder="Your name"
                            {...registerMain('senderFirstName', {
                                required: t('nameIsRequired'),
                            })}
                            error={!!errorsMain.senderFirstName}
                            className={styles.input}
                        />
                    </div>
                    <div>
                        <Input
                            placeholder="Your email"
                            {...registerMain('senderEmail', {
                                required: t('nameIsRequired'),
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: t('emailIncorrect'),
                                },
                            })}
                            error={!!errorsMain.senderEmail}
                            className={styles.input}
                        />
                    </div>
                </div>
            </form>
            {recipientsList.length < EMAILS_LIMIT && (
                <form onSubmit={handleSubmit(addEmail)}>
                    <span className={styles.formLabel}>{t('SharingRecipientsLabel')}</span>

                    <div className={styles.inputsRow}>
                        <div>
                            <Input
                                {...register('firstName', {
                                    required: t('nameIsRequired'),
                                })}
                                placeholder="Muster"
                                error={!!errors.firstName}
                                className={styles.input}
                            />
                        </div>

                        <div>
                            <Input
                                {...register('email', {
                                    required: t('emailIsRequired'),
                                    pattern: {
                                        value: EMAIL_REGEX,
                                        message: t('emailIncorrect'),
                                    },
                                })}
                                placeholder="muster@beispiel.ch"
                                error={!!errors.email}
                                className={styles.input}
                            />
                        </div>

                        <Button type="secondary" className={styles.addButton} htmlType="submit">
                            +
                        </Button>
                    </div>
                    {errors?.firstName && <ErrorMessage message={errors.firstName?.message as any} />}
                    {errors?.email && <ErrorMessage message={errors.email?.message as any} />}
                </form>
            )}
            {recipientsList.length > 0 && (
                <div className={styles.emailsWrapper}>
                    {recipientsList.map((recipient: any) => (
                        <Chip
                            key={recipient.name}
                            label={`${recipient.firstName} - ${recipient.email}`}
                            onDelete={() => deleteEmail(recipient.email)}
                        />
                    ))}
                </div>
            )}

            <Button
                type="ghost"
                className={styles.modalButton}
                isDisabled={recipientsList.length === 0 || loading}
                onClick={handleSubmitMain(sendEmails)}
            >
                {t('SharingSendPerEmailButton')}
            </Button>
        </Modal>
    );
};
