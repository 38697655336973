import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/ui';

import styles from './QuantityCheckPopup.module.scss';

export const QuantityCheckPopup: React.FC<{ data: any; onSuccess?: () => void; close: () => void }> = ({
    data,
    onSuccess,
    close,
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.root}>
            <p>{t('quantityCheckPopup.text')}</p>

            {data?.length > 0 && (
                <div className={styles.content}>
                    <ul>
                        {data.map((item: any, index: number) => (
                            <li className={styles.product} key={index}>
                                <img src={item?.product.media?.[0]?.url} alt="Bike" />
                                {item.product.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <div className={styles.actions}>
                <Button type="secondary" onClick={close}>
                    {t('quantityCheckPopup.backButton')}
                </Button>
                <Button type="primary" onClick={onSuccess}>
                    {t('quantityCheckPopup.continueButton')}
                </Button>
            </div>
        </div>
    );
};
