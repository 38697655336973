import { gql } from '@apollo/client';
import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { apolloClient } from 'shared/lib/apollo';

export enum ContactModalScreen {
    START = 'START',
    CONTACT_FORM = 'CONTACT_FORM',
    CALENDAR = 'CALENDAR',
    SUCCESS = 'SUCCESS',
    CHAT = 'CHAT',
}

export const REQUEST_CONTACT = gql`
    mutation requestContact(
        $email: String
        $phone: String
        $firstName: String!
        $lastName: String!
        $message: String
        $configurationSid: String
        $channel: String
        $languageCode: LanguageCodeEnum!
    ) {
        requestContact(
            email: $email
            phone: $phone
            firstName: $firstName
            lastName: $lastName
            message: $message
            configurationSid: $configurationSid
            channel: $channel
            languageCode: $languageCode
        ) {
            errors {
                field
                message
                code
            }
        }
    }
`;

interface ContactFormType {
    email?: string;
    phone?: string;
    firstName: string;
    lastName: string;
    message?: string;
    configurationSid?: string;
    channel?: string;
    languageCode: string;
}

export class ContactModalStore {
    currentScreen = ContactModalScreen.CONTACT_FORM;
    successScreenMessage = '';

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    get isCalendar() {
        return this.currentScreen === ContactModalScreen.CALENDAR;
    }

    get isSuccessScreen() {
        return this.currentScreen === ContactModalScreen.SUCCESS;
    }

    sendForm(data: ContactFormType) {
        return apolloClient.mutate({
            mutation: REQUEST_CONTACT,
            variables: {
                ...data,
            },
        });
    }
}

export const ContactModalContext = createContext<ContactModalStore>({} as ContactModalStore);
