import colorPickerImg from 'assets/images/color-picker.png';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BikeCard, Button, Icon, Modal, Tooltip } from 'shared/components/ui';
import ConfiguratorStore from 'stores/Configurator.store';

import { getDatetime } from '../../../../../shared/providers/utils';
import { gtmEvents } from '../../../../../shared/types/gtmEvents';
import { hubspotEvents } from '../../../../../shared/types/hubspotEvents';
import CheckoutStore from '../../../../../stores/Checkout.store';
import { Color } from '../../Color/Color';
import { ColorPicker } from '../../ColorPicker/ColorPicker';
import { SmartFit } from '../../SmartFit/SmartFit';

import styles from './Section.module.scss';

type SectionProps = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    data: any;
    setValue: (item: any) => void;
    activeValue?: any;
};

export const Section: FC<SectionProps> = observer(({ isOpen, activeValue, data, onClose, title, setValue }) => {
    const store = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const { hasSelectedColors } = store;

    const [isCustomColorOpen, setIsCustomColorOpen] = useState(false);
    const { t } = useTranslation();

    const tooltipText = useMemo(() => {
        switch (title) {
            case `${t('sidebarFrameSizeTitle')}`:
                const channel = process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL;
                const linkHref = channel === 'us-channel' ? 'https://www.thoemus.com' : 'https://www.thoemus.ch';
                return (
                    <Trans i18nKey="sidebarFrameSizeTooltip">
                        The right frame size? Use the icon on the right to determine the size, check the frame
                        geometries on our
                        <a href={linkHref} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                            Website
                        </a>
                        or ask our sales team.
                    </Trans>
                );
            case `${t('sittingPosition')}`:
                return <Trans i18nKey="sidebarSittingPositionTooltip" components={{ p: <p />, b: <b /> }} />;
            default:
                return '';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);

    const getTag = (item: any) => {
        switch (title) {
            case `${t('sidebarFrameColorTitle')}`:
                return (
                    <Color
                        key={item.id}
                        color={{
                            id: item.id,
                            value: item.attributes[0]?.values[0]?.value,
                            weight: item?.weight?.value,
                            colorName: item.attributes[0]?.values[0]?.name,
                            hasTexture:
                                item?.attributes?.find((a: any) => a?.attribute?.slug === 'primtexture')?.values
                                    ?.length > 0,
                        }}
                        setSelectedColor={color => setValue(color)}
                        className={styles.color}
                        colorSelected={!store.hasSelectedColors && store.selectedBikeVariant.id === item.id}
                    />
                );
            case `${t('sittingPosition')}`:
                return (
                    <div
                        key={item.name}
                        className={clsx(styles.item, activeValue === item.name && styles.active_item)}
                        onClick={() => setValue(item)}
                        style={{
                            padding: '0 32px',
                            textTransform: 'capitalize',
                        }}
                    >
                        {t(`sittingPosition${item.name}`)}
                    </div>
                );
            case `${t('sidebarFrameSizeTitle')}`:
                return (
                    <div
                        key={item.name}
                        className={clsx(styles.item, activeValue === item.name && styles.active_item)}
                        onClick={() => setValue(item)}
                        style={{
                            padding: title === 'Frame size' ? '0' : '0 32px',
                            textTransform: 'uppercase',
                        }}
                    >
                        {item.name}
                    </div>
                );
            case `${t('sidebarPreconfigurationsTitle')}`:
                return (
                    <BikeCard
                        data={item}
                        setBikeModel={() => setValue(item)}
                        activeModel={activeValue}
                        type="configurator"
                        className={styles.bike_card}
                        key={item.id}
                    />
                );
            default:
                return null;
        }
    };

    const selectSize = (sf: any) => {
        const size = data.find((d: any) => d.name.toUpperCase() === sf.displaySize01);
        if (size) setValue(size);
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => onClose()}
                overlayClassName={styles.overlay}
                className={styles.modal_section}
                closeClassName={styles.close_icon}
                darkMode
            >
                <div className={styles.titleWrapper}>
                    <h6>{title}</h6>
                    {tooltipText && (
                        <Tooltip
                            label={tooltipText}
                            tooltipWrapperClassName={styles.tooltipWrapper}
                            tooltipClassName={styles.tooltip}
                        >
                            <Icon name="question" />
                        </Tooltip>
                    )}
                </div>
                <div className={styles.values_wrapper}>
                    <div
                        className={styles.values}
                        style={{ flexWrap: title === 'Preconfigurations' ? 'nowrap' : 'wrap' }}
                    >
                        {data &&
                            data.map((item: any) => {
                                return getTag(item);
                            })}
                        {title === t('sidebarFrameSizeTitle') && (
                            // <FrameSizeButton
                            //     onClick={() => (store.frameSizeModal = true)}
                            //     className={styles.rullerTag}
                            // />
                            <div
                                className={styles.item}
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: gtmEvents.BIKE_SIZE_MODAL,
                                    });
                                    checkoutStore.hubspotEventTrack(
                                        hubspotEvents.BIKE_SIZE_MODAL_TRIGGERED,
                                        getDatetime(),
                                    );
                                }}
                            >
                                <SmartFit product={store.selectedBike} onConfirmSize={(s: any) => selectSize(s)} />
                            </div>
                        )}

                        {title === t('sidebarFrameColorTitle') && store.selectedBike?.isCustomColorActive && (
                            <div
                                className={clsx(styles.color_picker, hasSelectedColors && styles.active)}
                                onClick={() => setIsCustomColorOpen(!isCustomColorOpen)}
                            >
                                <img src={colorPickerImg} alt="color picker" />
                            </div>
                        )}
                    </div>
                </div>

                <Button type="primaryDark" className={styles.button} onClick={() => onClose()}>
                    {t('select')}
                </Button>
            </Modal>

            {title === t('sidebarFrameColorTitle') && store.selectedBike?.isCustomColorActive && (
                <Modal
                    isOpen={isCustomColorOpen}
                    onClose={() => setIsCustomColorOpen(false)}
                    className={styles.colorPickerModal}
                    overlayClassName={styles.overlay}
                >
                    <ColorPicker onClose={() => setIsCustomColorOpen(false)} />
                </Modal>
            )}
        </>
    );
});
