import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CheckoutStore from 'stores/Checkout.store';

import styles from './OrderOverviewNotesVendor.module.scss';

export const OrderOverviewNotesVendor = () => {
    const checkoutStore = useContext(CheckoutStore);
    const { t } = useTranslation();

    const { external_note, salesperson, point_of_sale } = checkoutStore?.expertCheckoutData;
    const selectedSalesLocation = checkoutStore.salesLocations?.expertCheckoutLocations?.edges?.find(
        (edge: any) => edge.node.approomId === point_of_sale,
    );
    const seller = selectedSalesLocation?.node?.sellers?.find((seller: any) => seller.email === salesperson);

    return (
        <>
            {external_note && (
                <div className={styles.notes}>
                    <h3 className={styles.title}>{t('overviewPage.notes')}:</h3>
                    <p>{external_note}</p>
                </div>
            )}
            <div className={styles.sales}>
                <p>
                    <span>{t('overviewPage.pos')}</span>
                    {` ${selectedSalesLocation?.node?.location}`}
                </p>
                <p>
                    <span>{t('overviewPage.salesperson')}:</span>
                    {` ${seller?.firstName}  ${seller?.lastName && seller?.lastName}`}
                </p>
            </div>
        </>
    );
};
