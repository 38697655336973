import fallbackImg from 'assets/images/default-part-image-popup.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'shared/components/ui';

import { FullDescription } from './FullDescription';
import styles from './PartVariant.module.scss';

type LongDescriptionModalProps = {
    data: any;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};
export const LongDescriptionModal: React.FC<LongDescriptionModalProps> = ({ data, isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation();
    const imageExists = data?.referenceProduct?.media?.[0]?.url || false;

    return (
        <Modal isOpen={isOpen} onClose={onClose} maxWidth={444} className={styles.long_desc_modal}>
            <div className={styles.modalContent}>
                <div className={styles.image_wrapper}>
                    <img
                        src={imageExists ? `${data?.referenceProduct?.media?.[0]?.url}?w=348&h=220` : fallbackImg}
                        alt=""
                    />
                </div>
                <h3 className={styles.long_desc_title}>{data?.referenceProduct?.name}</h3>
                <FullDescription jsonStringData={data?.referenceProduct?.description} />
            </div>
            <Button onClick={onConfirm}>{t('selectProductModalButton')}</Button>
        </Modal>
    );
};
