import fallbackLogo from 'assets/images/default-part-image-popup.png';
import fallbackImage from 'assets/images/mock-bike.png';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { localizePrice } from 'shared/providers/utils';

import { Icon } from '../Icon/Icon';
import StockTrafficColor from '../StockTrafficColor/StockTrafficColor';

import styles from './BikeCard.module.scss';

type BikeCardProps = {
    data: any;
    setBikeModel: (data?: any, id?: any, name?: any) => void;
    activeModel: string;
    lightMode?: boolean;
    type?: 'bikeModel' | 'configurator';
    className?: string;
};

export const BikeCard: FC<BikeCardProps> = ({ data, setBikeModel, activeModel, lightMode, type, className }) => {
    const { t } = useTranslation();
    if (type === 'configurator') {
        return (
            <div
                className={clsx(
                    styles.configurator,
                    styles.bike_card,
                    activeModel === data.id && styles.bike_card_active,
                    lightMode && styles.light_mode,
                    className,
                )}
                onClick={() => setBikeModel(data)}
                id={data.id}
            >
                <div className={styles.image}>
                    <img src={`${data.cover}?w=123&h=123&trim=color&trim-tol=50&bri=7` || fallbackImage} alt="" />
                </div>
                <div className={styles.name_price}>
                    <p className={styles.name}>{data.title}</p>
                    {data.price && (
                        <p className={styles.price}>
                            {t('ab')} {`${data.price}`}
                        </p>
                    )}
                </div>
                <StockTrafficColor dataVariant={data} className={styles.trafficColorCircle} />
            </div>
        );
    } else {
        return (
            <div
                className={clsx(
                    styles.bike_card,
                    activeModel === data.id && styles.bike_card_active,
                    lightMode && styles.light_mode,
                    className,
                )}
                onClick={() => setBikeModel(data)}
            >
                <div className={styles.thumbnail}>
                    {data?.media[0] || data?.configuratorCover ? (
                        <img
                            src={
                                `${data.configuratorCover}?w=123&h=75&trim=color&trim-tol=50&bri=7` ||
                                data.media[0]?.url
                            }
                            alt={data.name}
                        />
                    ) : (
                        <img src={fallbackLogo} alt="Logo" className={styles.fallbackLogo} />
                    )}
                    {data?.isEBike && <Icon name="lightning" className={styles.lightning} />}
                </div>
                <div className={styles.name_price}>
                    <p className={styles.name}>{data.translation?.name || data.name}</p>
                    <p className={styles.price}>
                        {t('ab')}{' '}
                        {`${data.configuratorPrice.currency || data.price} ${
                            localizePrice(data.configuratorPrice.amount) || ''
                        }`}
                    </p>
                </div>
            </div>
        );
    }
};
