import React, { useState } from 'react';
import { Icon, Loader, Portal } from 'shared/components/ui';

import { gtmEvents } from '../../../../shared/types/gtmEvents';

import styles from './AR.module.scss';
import { ARModal } from './ARModal';

export const ARButton: React.FC<any> = ({ sendHubspotEvent }) => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const startAR = () => {
        setOpenModal(true);
        setLoading(true);
        sendHubspotEvent();
        setTimeout(() => setLoading(false), 1000);

        window.dataLayer.push({
            event: gtmEvents.BIKE_AR_BUTTON,
        });
    };

    return (
        <>
            <button className={styles.arButton} onClick={() => startAR()} disabled={loading}>
                {loading ? <Loader /> : <Icon name="ar" />}
            </button>
            <Portal>
                <ARModal onOpen={openModal} onClose={() => setOpenModal(false)} />
            </Portal>
        </>
    );
};
