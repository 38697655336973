import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useUpdateCheckoutMetadata } from 'pages/Configurator/hooks/useUpdateCheckoutMetadata';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'Routes';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { gtmEvents } from 'shared/types/gtmEvents';
import CheckoutStore from 'stores/Checkout.store';
import ConfiguratorStore from 'stores/Configurator.store';

import { UserExpertFormValues } from '../UserExpertCheckoutForm/UserExpertCheckoutForm';

import { OrderOverviewFooter } from './_components/OrderOverviewFooter/OrderOverviewFooter';
import { OrderOverviewHeader } from './_components/OrderOverviewHeader/OrderOverviewHeader';
import { OrderOverviewImage } from './_components/OrderOverviewImage/OrderOverviewImage';
import { OrderOverviewNotesVendor } from './_components/OrderOverviewNotesVendor/OrderOverviewNotesVendor';
import { OrderOverviewProductsList } from './_components/OrderOverviewProductsList/OrderOverviewProductsList';
import { OrderOverviewProductUserData } from './_components/OrderOverviewProductUserData/OrderOverviewProductUserData';
import styles from './OrderOverview.module.scss';

type OrderOverviewProps = {
    className?: string;
};

export const OrderOverview: FC<OrderOverviewProps> = observer(({ className }) => {
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const [updateCheckoutMetadata] = useUpdateCheckoutMetadata();
    const navigator = useNavigate();
    const savedJSON = localStorage.getItem(LocalStorageKey.USER_EXPERT_CHECKOUT);
    const savedValues: Partial<UserExpertFormValues> = savedJSON ? JSON.parse(savedJSON) : {};
    const countries = checkoutStore.countries;
    const defaultCountryCode = savedValues?.country || 'CH';
    const [loadingMail, setLoadingMail] = useState(false);
    const [loadingNoMail, setLoadingNoMail] = useState(false);
    const [cover, setCover] = useState<string | false>(false);
    const isPos = process.env.REACT_APP_POS === 'true';

    // CREATE CHECKOUT
    useEffect(() => {
        async function goToCheckout() {
            try {
                configuratorStore.listenForNavigationChange = false;

                await checkoutStore.createCheckout(
                    configuratorStore.selectedParts,
                    configuratorStore.selectedColors,
                    configuratorStore.selectedBikeVariant,
                );

                const id = checkoutStore.data?.id;
                if (!id) {
                    return console.error('No checkout ID.');
                }
                localStorage.setItem(LocalStorageKey.CHECKOUT_ID, id);

                await checkoutStore.saveScreenshot(id);
                if (!configuratorStore.shareableID) {
                    await configuratorStore.saveConfiguration(true);
                }
                await updateCheckoutMetadata();

                window.dataLayer.push({
                    event: gtmEvents.CHECKOUT_CREATE,
                });

                await validateUserForm(id);

                configuratorStore.configurationExists = true;
            } catch (error) {
                console.error('error', error);
            } finally {
                setLoadingMail(false);
                setLoadingNoMail(false);
            }
        }

        if (checkoutStore.screenshot && isPos) {
            goToCheckout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutStore.screenshot]);

    useEffect(() => {
        if (configuratorStore?.screenshot) {
            setCover(configuratorStore.screenshot);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuratorStore.screenshot]);

    // TRIGGER CREATE CHECKOUT
    const createCheckout = async (noMail: boolean) => {
        checkoutStore.noOfferEmail = noMail;
        if (noMail) {
            setLoadingNoMail(true);
        } else {
            await configuratorStore.hubspotDealCreate();
            setLoadingMail(true);
        }

        try {
            await checkoutStore.createScreenshot();
        } catch (error) {
            console.error(error);
        }
    };

    // VALIDATE USERFORM
    const validateUserForm = async (id: string) => {
        const validateContact = async () => {
            if (!savedValues?.phone) return;
            if (savedValues.phone)
                savedValues.phone = savedValues.phone?.includes('+') ? savedValues.phone : `+${savedValues.phone}`;

            if (savedValues.phone) {
                await checkoutStore.updatePhone(savedValues.phone);
            }
            if (savedValues.phone && checkoutStore.phoneValidationFailed) return;
        };

        const setAddress = async () => {
            try {
                const address = {
                    id: '',
                    firstName: savedValues?.firstName || '',
                    lastName: savedValues?.lastName || '',
                    companyName: savedValues?.companyName || '',
                    streetAddress1: savedValues?.streetAddress1 || '',
                    city: savedValues?.city || '',
                    postalCode: savedValues?.postalCode || '',
                    country: {
                        code: savedValues?.country || 'CH',
                        country:
                            countries.find((c: any) => c.code === (defaultCountryCode ?? savedValues.country))
                                ?.country || 'Schweiz',
                    },
                    phone: savedValues?.phone || '',
                };
                await checkoutStore.addressModalSubmit({
                    ...savedValues,
                    country: savedValues.country,
                });

                const shippingResponse = await checkoutStore.setShippingAddress(address);
                const billingResponse = await checkoutStore.setBillingAddress(address);
                return { shippingResponse, billingResponse };
            } catch (error) {
                console.error(error);
                throw error; // re-throw so the caller can catch
            }
        };

        try {
            if (configuratorStore?.discountPercentage) {
                await checkoutStore.createCustomVoucher(configuratorStore?.discountPercentage, true);
            }

            await validateContact();
            const resp = await setAddress();

            if (resp?.shippingResponse?.checkoutShippingAddressUpdate) {
                sendOffert(id);
            } else {
                configuratorStore.isOverview = false;
            }
        } catch (err: any) {
            console.error(err);
        }
    };

    // SEND OFFERT
    const sendOffert = async (id: string) => {
        try {
            const expertCheckoutPaymentGateway = checkoutStore.data?.availablePaymentGateways?.find(
                gateway => gateway.id === 'mirumee.payments.dummy',
            );
            if (!expertCheckoutPaymentGateway) return;

            const paymentResponse = await checkoutStore.createPayment(expertCheckoutPaymentGateway.id, true);
            if (paymentResponse?.data?.checkoutPaymentCreate?.errors.length < 1) {
                navigator(AppRoutes.posOrder.replace(':id', id));
                configuratorStore.isCheckout = false;
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (!configuratorStore.isOverview) return null;

    return (
        <div
            className={clsx(styles.modal_overlay)}
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <div className={clsx(styles.modal, className)} onClick={event => event.stopPropagation()}>
                <div className={styles.container}>
                    <OrderOverviewHeader />
                    <OrderOverviewImage image={cover} />
                    <OrderOverviewProductUserData />
                    <OrderOverviewProductsList />
                    <OrderOverviewNotesVendor />

                    <OrderOverviewFooter
                        createCheckout={createCheckout}
                        isDisabled={loadingNoMail || loadingMail || !configuratorStore.loadingEnded}
                        loadingOffer={loadingMail}
                        loadingCheckout={loadingNoMail}
                    />
                </div>
            </div>
        </div>
    );
});
