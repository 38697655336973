import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/ui';
import ConfiguratorStore from 'stores/Configurator.store';

import styles from './OrderOverviewHeader.module.scss';

export const OrderOverviewHeader = () => {
    const configuratorStore = useContext(ConfiguratorStore);
    const { t } = useTranslation();
    return (
        <div className={styles.header}>
            <h2>
                {t('overviewPage.title')} {configuratorStore.shareableID}
            </h2>
            <Button
                type="secondary"
                className={styles.backButton}
                onClick={() => (configuratorStore.isOverview = false)}
                preIcon="arrow_left"
            >
                {t('overviewPage.buttons.back')}
            </Button>
        </div>
    );
};
