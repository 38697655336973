import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import ConfiguratorStore from 'stores/Configurator.store';

import styles from './Loader3DButton.module.scss';

interface Loader3DButtonProps {
    progress: number;
}

export const Loader3DButton: React.FC<Loader3DButtonProps> = ({ progress }) => {
    const store = useContext(ConfiguratorStore);
    const [currentProgress, setCurrentProgress] = useState(0);

    useEffect(() => {
        store.initialLoading = true;
        const updateProgress = () => {
            setCurrentProgress(prev => prev + 1);
        };
        const interval = setInterval(updateProgress, 1000);
        if (progress >= 10) {
            clearInterval(interval);
            setCurrentProgress(progress);
        }

        if (progress >= 100) {
            setTimeout(() => {
                store.initialLoading = false;
            }, 2000);
            store.setIsTwoDimension(false);
        }

        return () => clearInterval(interval);
    }, [progress, store]);

    const circleCircumference = 125.66;
    const strokeDashoffset = circleCircumference - (circleCircumference * currentProgress) / 100;

    return (
        <div className={styles.root}>
            <div className={styles.buttonContainer}>
                {store.initialLoading && (
                    <svg className={styles.circularLoader} viewBox="0 0 44 44">
                        <circle className={styles.loaderTrack} cx="22" cy="22" r="20" />
                        <circle
                            className={styles.loaderProgress}
                            cx="22"
                            cy="22"
                            r="20"
                            strokeDasharray={circleCircumference}
                            strokeDashoffset={strokeDashoffset}
                        />
                    </svg>
                )}

                <div className={clsx(styles.control3d)}>3D</div>
            </div>
        </div>
    );
};
