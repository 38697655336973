import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { CopyLink, LanguageSelect, Logo } from 'shared/components/ui';
import { ContactModal } from 'shared/components/ui/ContactModal/ContactModal';
import { SearchSidModal } from 'shared/components/ui/SearchSidModal/SearchSidModal';
import { ShareButton } from 'shared/components/ui/ShareButton/ShareButton';

import { gtmEvents } from '../../../../../types/gtmEvents';

import styles from './DefaultHeader.module.scss';

export const DefaultHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const homeNavigate = () => {
        window.dataLayer.push({
            event: gtmEvents.LOGO_CLICK,
        });
        navigate(AppRoutes.home);
    };

    return (
        <header className={styles.header}>
            <div className={styles.logo} onClick={homeNavigate}>
                <Logo />
            </div>
            <div className={styles.headerContent}>
                <div className={styles.buttons}>
                    <SearchSidModal />
                    <ContactModal />

                    {location.pathname.includes('/configurator') && (
                        <>
                            <ShareButton />

                            <CopyLink />
                        </>
                    )}
                    {!location.pathname.includes('/configurator') && <LanguageSelect />}
                </div>
            </div>
        </header>
    );
};
