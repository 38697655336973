import { gql } from '@apollo/client';

import { PARTS_FIELD, PRODUCT_FIELDS, PRODUCT_FIELDS_STOCK } from '../fragments/configurator';

export const BIKE_PRECONFIGURATIONS = gql`
    query Configurations($template: ID!) {
        configurations(
            first: 100
            filter: { template: $template, featured: true, published: true, active: true }
            sortBy: { field: SORT_ORDER, direction: ASC }
        ) {
            edges {
                node {
                    id
                    sid
                    cover
                    title
                    description
                    frameSize
                    sittingPosition
                    parts
                    default
                    featured
                    stockTrafficColor
                    promoPrice {
                        amount
                        currency
                    }
                }
            }
        }
    }
`;

export const PARTS = gql`
    query Parts($productId: ID!, $language: LanguageCodeEnum = DE) {
        product(id: $productId) {
            ${PARTS_FIELD}
            optionsByType(languageCode: $language) {
                values {
                    referenceProduct {
                        translation(languageCode: $language) {
                            name
                            description
                            shortTitle
                            shortDescription
                        }
                        variants {
                            translation(languageCode: $language) {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const REFERENCE_DETAILS = gql`
    query Parts($productId: ID!, $channel: String!, $bikeProductId: ID!) {
        product(id: $productId, channel: $channel) {
            id
            name
            description
            no3dRequired
            longDescription
            shortTitle
            shortDescription
            rating
            requiredProducts {
                id
                name
            }
            conflictingProducts {
                id
                name
            }
            productType {
                id
                name
                conflictingProductTypes {
                    id
                    name
                }
                requiredTypes {
                    id
                    name
                }
            }
            weight {
                value
                unit
            }
            media {
                url
                configuratorOnly
            }
            variants {
                id
                encryptedStock
                weight {
                    value
                    unit
                }
                media {
                    url
                    configuratorOnly
                    id
                }
                stockTrafficColor(bikeProductId: $bikeProductId)
                approomStock(bikeProductId: $bikeProductId) {
                    stock
                    isGroup
                    subProducts {
                        id
                        name
                        stock
                    }
                }

                pricing(bikeProductId: $bikeProductId) {
                    onSale
                    price {
                        net {
                            amount
                            currency
                        }
                        gross {
                            amount
                            currency
                        }
                    }
                    discount {
                        net {
                            amount
                            currency
                        }
                        gross {
                            amount
                            currency
                        }
                    }
                    priceUndiscounted {
                        net {
                            amount
                            currency
                        }
                        gross {
                            amount
                            currency
                        }
                    }
                }
            }
        }
    }
`;

export const PRODUCT_LIGHT = gql`
    query ProductLight($productId: ID!, $channel: String!, $bikeProductId: ID!) {
        product(id: $productId, channel: $channel) {
            id
            name
            shortTitle
            shortDescription
            productType {
                id
                name
            }
            media {
                url
                configuratorOnly
            }
            variants {
                stockTrafficColor(bikeProductId: $bikeProductId)
                approomStock(bikeProductId: $bikeProductId) {
                    stock
                    isGroup
                    subProducts {
                        id
                        name
                        stock
                    }
                }
            }
        }
    }
`;

export const BIKE_PRECONFIGURATION = gql`
    query Configuration($id: ID!) {
        configuration(id: $id) {
            id
            title
            description
            cover
            frameSize
            sittingPosition
            parts
            colors
            default
            featured
            stockTrafficColor
            promoPrice {
                amount
                currency
            }
        }
    }
`;

export const PRODUCT_PART_MODEL = gql`
    query productPartModels($id: ID!, $size: ID!) {
        productPartModels(id: $id, size: $size) {
            id
            name
            productModel
            modelId
            productType {
                id
                name
                isBikeType
                metadata {
                    key
                    value
                }
                conflictingProductTypes {
                    id
                    name
                }
            }
            slots {
                id
                productType {
                    id
                    name
                }
                transformMatrix
                size {
                    id
                }
                modelReferences {
                    id
                    file
                }
            }
        }
    }
`;

export const PRODUCT = gql`
    query ProductOnRefresh($id: ID, $language: LanguageCodeEnum = DE) {
        product(id: $id) {
            ${PRODUCT_FIELDS}
        }
    }
`;

export const PRODUCT_STOCK = gql`
    query ProductOnRefresh($id: ID, $language: LanguageCodeEnum = DE) {
        product(id: $id) {
            ${PRODUCT_FIELDS_STOCK}
        }
    }
`;

export const GET_BIKE_BY_SID = gql`
    query Configuration($sid: String, $language: LanguageCodeEnum!) {
        configuration(sid: $sid) {
            id
            template {
                ${PRODUCT_FIELDS}
                ${PARTS_FIELD}
            }
            title
            description
            cover
            frameSize
            sittingPosition
            parts
            colors
            default
            featured
            referralEmail
            referralOrders
        }
    }

`;

export const GET_BIKEID_BY_SID = gql`
    query Configuration($sid: String) {
        configuration(sid: $sid) {
            id
        }
    }
`;

export const GET_AR_BY_ID = gql`
    query GetFile($id: ID!) {
        arModel(id: $id) {
            id
            file
        }
    }
`;
