import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceDetectorContext } from '../../../providers/DeviceDetector';
import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

import { ContactCalendar } from './_components/ContactCalendar/ContactCalendar';
import { ContactForm } from './_components/ContactForm/ContactForm';
import { ContactOptions } from './_components/ContactOptions/ContactOptions';
import { SuccessScreen } from './_components/SuccessScreen/SuccessScreen';
import styles from './ContactModal.module.scss';
import { ContactModalContext, ContactModalScreen, ContactModalStore } from './stores/ContactModal.store';

export const ContactModal = observer(() => {
    const { device } = useContext(DeviceDetectorContext);
    const isMobile = device === 'mobile' || device === 'small-tablet';
    const isPos = process.env.REACT_APP_POS === 'true';

    if (isPos) return null;

    const [contactModalStore] = useState(() => new ContactModalStore());
    const [showContactModal, setShowContactModal] = useState(false);

    const { currentScreen, successScreenMessage, isCalendar, isSuccessScreen } = contactModalStore;

    const onClose = () => {
        setShowContactModal(false);
        contactModalStore.currentScreen = ContactModalScreen.CONTACT_FORM;
    };

    const { t } = useTranslation();

    const openChat = () => {
        onClose();
        const bubble = document.getElementById('wp-live-chat-by-3CX') as any;
        bubble.classList.add('slideUp');
        setTimeout(() => {
            bubble.shadowRoot.querySelector('#wplc-chat-button').click();
        }, 650);
    };

    const onOpen = () => {
        setShowContactModal(true);
    };

    return (
        <>
            <Button
                type="text"
                preIcon={isMobile ? 'support-bold' : 'support'}
                className={styles.contactButton}
                onClick={() => onOpen()}
            >
                {!isMobile && t('consultation')}
            </Button>

            <Modal
                isOpen={showContactModal}
                onClose={onClose}
                maxWidth={isCalendar ? '100%' : 450}
                className={clsx(
                    styles.modal,
                    isCalendar && styles.calendarModal,
                    isSuccessScreen && styles.successModal,
                )}
            >
                <ContactModalContext.Provider value={contactModalStore}>
                    {currentScreen === ContactModalScreen.START && <ContactOptions onChat={() => openChat()} />}
                    {currentScreen === ContactModalScreen.CONTACT_FORM && <ContactForm />}
                    {currentScreen === ContactModalScreen.CALENDAR && <ContactCalendar />}
                    {currentScreen === ContactModalScreen.SUCCESS && <SuccessScreen message={successScreenMessage} />}
                </ContactModalContext.Provider>
            </Modal>
        </>
    );
});
