import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'shared/components/ui';
import { DeviceDetectorContext } from 'shared/providers/DeviceDetector';
import { ColorType } from 'shared/types/configurator';
import ConfiguratorStore from 'stores/Configurator.store';

import { useHubspotDealCreateUpdate } from '../../../../shared/hooks/useHubspotHook';
import { gtmEvents } from '../../../../shared/types/gtmEvents';
import { hubspotEvents } from '../../../../shared/types/hubspotEvents';
import CheckoutStore from '../../../../stores/Checkout.store';
import { Color } from '../Color/Color';

import styles from './ColorPicker.module.scss';

interface Props {
    onClose?: () => void;
    onColorClick?: () => void;
}

enum ColorTypes {
    MAT = 'mat',
    GLOSSY = 'glossy',
}

export const ColorPicker: React.FC<Props> = observer(({ onClose, onColorClick }) => {
    const { device } = useContext(DeviceDetectorContext);
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const { t } = useTranslation();
    const [createOrUpdateDeal] = useHubspotDealCreateUpdate();

    const data = configuratorStore.selectedBike.customColorVariantsByGroup;

    const setColor = async (color: any, item: any) => {
        configuratorStore.setSelectedColors(item.group, color.code, color.variant.id, color.name, item.key);

        window.dataLayer.push({
            event: gtmEvents.BIKE_CUSTOM_COLOR,
            category: configuratorStore.bike,
            bikeModel: configuratorStore.selectedBike.name,
            bikeFrame: item.group,
            color: color.name,
            hexColor: color.code,
        });

        // await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_CUSTOM_COLOR, color.name);
        await checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_CUSTOM_COLOR_SELECTED, color.name);
        await createOrUpdateDeal();
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <p className={styles.subtitle}>{t('chooseColors').toUpperCase()}</p>
                <h6 className={styles.price_label}>
                    {configuratorStore.selectedBike?.configuratorPrice?.currency} 750.00
                </h6>
            </div>

            <div className={styles.sectionWrapper}>
                <div className={clsx(styles.section, styles.materials)}>
                    <h6 className={styles.label}>{t('colorType')}</h6>
                    <div className={styles.types}>
                        <div
                            className={clsx(
                                styles.color_type,
                                configuratorStore.selectedColorType === ColorTypes.MAT && styles.color_type_active,
                            )}
                            onClick={() => {
                                configuratorStore.selectedColorType = ColorTypes.MAT;

                                window.dataLayer.push({
                                    event: gtmEvents.BIKE_COLOR_TYPE,
                                    colorType: 'mat',
                                });
                                checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_COLOR_TYPE_SELECTED, 'mat');
                            }}
                        >
                            <Icon name="matte" /> <h6>{ColorTypes.MAT}</h6>
                        </div>
                        <div
                            className={clsx(
                                styles.color_type,
                                configuratorStore.selectedColorType === ColorTypes.GLOSSY && styles.color_type_active,
                            )}
                            onClick={() => {
                                configuratorStore.selectedColorType = ColorTypes.GLOSSY;

                                window.dataLayer.push({
                                    event: gtmEvents.BIKE_COLOR_TYPE,
                                    colorType: 'glossy',
                                });

                                checkoutStore.hubspotEventTrack(hubspotEvents.BIKE_COLOR_TYPE_SELECTED, 'glossy');
                            }}
                        >
                            <Icon name="glossy" /> <h6>{t(`colorType${ColorTypes.GLOSSY}`)}</h6>
                        </div>
                    </div>
                </div>

                {data &&
                    data.map((item: any) => {
                        const activeColor = configuratorStore.selectedColors.find(
                            (part: any) => part.frame === item.group,
                        );
                        return (
                            <div className={styles.section} key={item.group}>
                                <h6 className={styles.label}>{item.group}</h6>
                                <div className={styles.colors}>
                                    {item.values.map((color: any) => {
                                        return (
                                            <Color
                                                key={color.code}
                                                className={styles.color}
                                                color={color}
                                                setSelectedColor={(color: ColorType) => {
                                                    onColorClick?.();
                                                    setColor(color, item);
                                                }}
                                                colorSelected={activeColor?.hex === color.code}
                                                lightMode
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
            </div>

            {device !== 'desktop' && (
                <Button className={styles.button} type="ghost" onClick={onClose}>
                    {t('select').toUpperCase()}
                </Button>
            )}
        </div>
    );
});
