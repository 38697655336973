import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'shared/components/ui';
import { Label } from 'shared/components/ui/Label/Label';
import { mapAddressTypeToAddressInputType } from 'shared/types/checkout';
import CheckoutStore, { AddressModalState, CheckoutStep } from 'stores/Checkout.store';

import { AddAddressCard } from './_components/AddAddress/AddAddressCard';
import { AddressCard } from './_components/AddressCard/AddressCard';
import { AddressModal } from './_components/AddressModal/AddressModal';
import styles from './Shipping.module.scss';

export const Shipping: React.FC = observer(() => {
    const checkoutStore = useContext(CheckoutStore);
    const {
        addresses,
        isAddressModalOpen,
        selectedShippingAddress,
        selectedBillingAddress,
        isBillingAddressSameAsShipping,
        data,
    } = checkoutStore;

    const isGuestCheckout = data?.user === null && data?.email !== null;

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const isNextStepDisabled = !(selectedShippingAddress && (selectedBillingAddress || isBillingAddressSameAsShipping));
    const isGuestNextStepDisabled = !(
        checkoutStore.data?.shippingAddress &&
        (isBillingAddressSameAsShipping || checkoutStore.data?.billingAddress)
    );

    const nextStep = async () => {
        try {
            setIsLoading(true);
            if (!selectedShippingAddress) throw new Error('No selected shipping address.');
            if (!isBillingAddressSameAsShipping && !selectedBillingAddress)
                throw new Error('No selected billing address.');

            await checkoutStore.setShippingAddress(selectedShippingAddress);
            await checkoutStore.setBillingAddress(
                isBillingAddressSameAsShipping ? selectedShippingAddress : selectedBillingAddress!,
            );

            checkoutStore.checkoutStepProgress = CheckoutStep.DELIVERY;
            checkoutStore.expandedStep = CheckoutStep.DELIVERY;
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const guestNextStep = async () => {
        try {
            setIsLoading(true);
            if (isBillingAddressSameAsShipping && checkoutStore.data?.shippingAddress) {
                await checkoutStore.guestBillingAddress(
                    mapAddressTypeToAddressInputType(checkoutStore.data?.shippingAddress),
                );
            }
            window.dataLayer.push({
                event: 'Checkout section',
                from: 'Shipping details',
                to: 'Delivery details',
                label: checkoutStore.productData?.name,
            });
            // await checkoutStore.sendDataToCustomerIo('Checkout section', 'Delivery details');
            checkoutStore.checkoutStepProgress = CheckoutStep.DELIVERY;
            checkoutStore.expandedStep = CheckoutStep.DELIVERY;
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.root}>
            {/*<p className={styles.description}>{t('shippingDescription')}</p>*/}
            <Label className={styles.subtitle} title={t('shippingTitle')} />
            <div className={styles.addresses}>
                {isGuestCheckout ? (
                    <>
                        {checkoutStore.data?.shippingAddress && (
                            <AddressCard
                                data={checkoutStore.data?.shippingAddress}
                                selected
                                onEditClick={() => {
                                    checkoutStore.addressModalInitialData = checkoutStore.data?.shippingAddress;
                                    checkoutStore.addressModalState = AddressModalState.EDIT_SHIPPING;
                                }}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {addresses.map(address => (
                            <AddressCard
                                key={address.id}
                                data={address}
                                selected={selectedShippingAddress?.id === address.id}
                                onClick={() => (checkoutStore.selectedShippingAddress = address)}
                                onEditClick={() => {
                                    checkoutStore.addressModalInitialData = address;
                                    checkoutStore.addressModalState = AddressModalState.EDIT_SHIPPING;
                                }}
                            />
                        ))}
                    </>
                )}

                {(!isGuestCheckout || (isGuestCheckout && !checkoutStore.data?.shippingAddress)) && (
                    <AddAddressCard
                        onClick={() => (checkoutStore.addressModalState = AddressModalState.NEW_SHIPPING)}
                    />
                )}
            </div>
            <Label title={t('billingTitle')} className={clsx(styles.subtitle, styles.billingLabel)} />
            <Checkbox
                label={t('useShippingAsBillingAddress')}
                className={styles.billingCheckbox}
                checked={checkoutStore.isBillingAddressSameAsShipping}
                onChange={e => {
                    checkoutStore.isBillingAddressSameAsShipping = e.target.checked;
                }}
            />
            {!checkoutStore.isBillingAddressSameAsShipping && (
                <div className={styles.addresses}>
                    {isGuestCheckout ? (
                        <>
                            {checkoutStore.data?.billingAddress && (
                                <AddressCard
                                    data={checkoutStore.data?.billingAddress}
                                    selected
                                    onEditClick={() => {
                                        checkoutStore.addressModalInitialData = checkoutStore.data?.billingAddress;
                                        checkoutStore.addressModalState = AddressModalState.EDIT_BILLING;
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {addresses.map(address => (
                                <AddressCard
                                    key={address.id}
                                    data={address}
                                    selected={selectedBillingAddress?.id === address.id}
                                    onClick={() => (checkoutStore.selectedBillingAddress = address)}
                                    onEditClick={() => {
                                        checkoutStore.addressModalInitialData = address;
                                        checkoutStore.addressModalState = AddressModalState.EDIT_BILLING;
                                    }}
                                />
                            ))}
                        </>
                    )}

                    {(!isGuestCheckout || (isGuestCheckout && !checkoutStore.data?.billingAddress)) && (
                        <AddAddressCard
                            onClick={() => (checkoutStore.addressModalState = AddressModalState.NEW_BILLING)}
                        />
                    )}
                </div>
            )}
            {isGuestCheckout ? (
                <Button
                    className={styles.button}
                    isDisabled={isGuestNextStepDisabled}
                    isLoading={isLoading}
                    onClick={guestNextStep}
                >
                    {t('next')}
                </Button>
            ) : (
                <Button
                    className={styles.button}
                    isDisabled={isNextStepDisabled}
                    isLoading={isLoading}
                    onClick={nextStep}
                >
                    {t('next')}
                </Button>
            )}
            {isAddressModalOpen && <AddressModal />}
        </div>
    );
});
